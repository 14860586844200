<template>
  <v-container fluid class="dashboard">
    <v-row v-if="news!=''" style="background-color:#faebd789;">
        <v-col><p class="c16-20-dm-sans pl-2">{{news}}</p></v-col>
        <v-spacer></v-spacer>
        <v-col class="text-right"><router-link to="/autocitire"><pre class="c15-20-dm-sans pr-3" style="color:#F02D4B">Trimitere autocitire</pre></router-link></v-col>
    </v-row>
    <v-row class="white-bg">
        <v-card class="ma-3 pa-3" style="width:100%" elevation="4" v-if="infoClient">
          <v-row class="white-bg" no-gutters>
            <v-col sm="12" md="10" align-self="center">
              {{infoClient.Title}}
            </v-col>
            <v-col sm="12" md="2" class="text-right" align-self="center">
              <v-dialog v-model="infoClientDialog" max-width="650">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="#FF5F7824" class="no-text-transform" text v-bind="attrs" v-on="on">
                    <span class="pan-rec">Mai multe detalii</span>
                    <v-icon color="#F02D4B" class="ml-1">mdi-arrow-right</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <v-row no-gutters>
                      <v-col>
                        <span class="c15-20-dm-sans-medium"><b>{{infoClient.Title}}</b></span>
                      </v-col>
                      <v-col class="text-right">
                        <v-btn icon @click="infoClientDialog = false">
                          <v-icon small>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <div class="c15-20-dm-sans" style="color:#171619;" v-html="infoClient.Content"></div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn text class="ml-3 close-text" @click="infoClientDialog = false">
                      Inchide
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="ma-3 pa-3" style="width:100%" elevation="4" v-if="referralData">
            <v-row class="white-bg" no-gutters>
                <v-col style="flex: 0 1 63px">
                    <v-img
                        class="ma-2"
                        style="background: transparent; mix-blend-mode: normal; width:39px"
                        alt="Vuetify Logo"
                        contain
                        :src="require('./../assets/achievement.svg')"
                        transition="scale-transition"
                    />
                </v-col>
                <v-col align-self="center">
                    <span>
                      <pre class="c16-20-dm-sans-medium">Recomanda Nova</pre>
                      <span class="c14-20-dm-sans-subtext">Recomanda Nova prietenilor tai si te recompensam cu 50 de lei!</span>
                    </span>
                </v-col>
                <v-col class="text-right" align-self="center">
                  <router-link to="/referral" style="float:right">
                    <v-btn color="#FF5F7824" class="text-none" text>
                      <span class="pan-rec">Panou de recomandari</span>
                    </v-btn>
                  </router-link>
                  <ReferralModal :info="referralData">
                    <template v-slot:button>
                      <v-btn color="#FF5F7824" class="text-none" dark>
                        <v-icon color="#F02D4B" class="mr-2">mdi-account-multiple-plus</v-icon>
                        <span class="pan-rec">Recomanda Nova</span>
                      </v-btn>
                    </template>
                  </ReferralModal>
                </v-col>
            </v-row>
        </v-card>
    </v-row>
    <v-row class="white-bg">
      <v-col cols="7" align-self="center" class="pl-5">
        <v-row v-if="user">
          <v-col>
            <pre class="c32-20-dm-sans" style="margin-bottom:10px"><b>{{user.partener.Nume}}</b></pre>
            <pre class="c14-14-dm-sans-subtext">COD Client: {{user.partener.BusinessPartnerCode}}</pre>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-row>
          <v-col align="right">
            <table border="0" v-if="tp">
              <tr valign="top">
                <td>
                  <div style="background-color:#2DB7A421; border-radius:16px; padding:18px">
                    <v-img
                        width="21px"
                        alt="Vuetify Logo"
                        :src="require('./../assets/Vector.svg')"
                    />
                  </div>
                </td>
                <td style="padding:9px 12px">
                  <pre class="c32-20-dm-sans" style="margin-bottom:3px"><b>{{new Intl.NumberFormat('ro-RO', { style: 'currency', currency: 'RON' }).format(tp.Lei)}}</b></pre>
                  <span class="c14-14-dm-sans-subtext">Sold curent</span>
                  <pre class="c20-20-dm-sans" v-if="tpn && tpn.Lei"><b>{{new Intl.NumberFormat('ro-RO', { style: 'currency', currency: 'RON' }).format(tpn.Lei)}}</b></pre>
                  <span class="c14-14-dm-sans-subtext" v-if="tpn && tpn.Lei">Sold nerepartizat (plata in avans)</span>
                  <pre class="c20-20-dm-sans" v-if="tpep && tpep.Lei"><b>{{new Intl.NumberFormat('ro-RO', { style: 'currency', currency: 'RON' }).format(tpep.Lei)}}</b></pre>
                  <span class="c14-14-dm-sans-subtext" v-if="tpep && tpep.Lei">Sold facturi excedent prosumator</span>
                </td>
                <td>
                  <platesteModal
                    v-if="tp && tp.Lei"
                    :align="'text-left'"
                    :user="user"
                    :unformattedTotal="tp.Lei"
                    :total="new Intl.NumberFormat('ro-RO', { style: 'currency', currency: 'RON' }).format(tp.Lei)"
                    :btnClass="'pay-btn'"
                    v-on:hidePlateste="hidePlateste($event)"
                  >
                    <template v-slot:button>
                      <span style="text-transform:none !important"><b>Plateste</b></span>
                      <v-icon>mdi-menu-right</v-icon>
                    </template>
                  </platesteModal>
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="white-bg">
      <v-col>
        <apexchart height="350" :options="options" :series="series"></apexchart>
      </v-col>
    </v-row>
      <v-row>
        <v-col class="mr-1 ml-1"><p class="my-bills">Facturile mele</p></v-col>
      </v-row>
      <!-- Bills listing -->
      <v-row v-for="(bill, key) in bills" :key="key" class="npg-box mr-1 ml-1">
        <v-col>
            <v-row>
                <v-col style="flex: 0 1 35px">
                    <v-img
                        v-if="bill.PrescurtareTipContract == 'EE'"
                        contain
                        align-center
                        min-width="35px"
                        min-height="35px"
                        width="35px"
                        height="33px"
                        alt="Energie"
                        :src="require('./../assets/Energy.svg')"
                    />
                    <v-img
                        v-if="bill.PrescurtareTipContract == 'GN'"
                        contain
                        align-center
                        min-width="35px"
                        min-height="35px"
                        width="35px"
                        height="33px"
                        alt="Gaz"
                        :src="require('./../assets/Gas.svg')"
                    />
                </v-col>
                <v-col>
                  <div>
                    <billModal
                      :bill="bill"
                      :user="user"
                    >
                      <template v-slot:button>
                        <pre class="c16-20-dm-sans-medium" style="color:#F02D4B;">{{bill.SerieNumar}}</pre>
                        <span class="c13-14-dm-sans-subtext">{{bill.EnitateFactura}}</span>
                      </template>
                    </billModal>
                  </div>
                </v-col>
            </v-row>
        </v-col>
        <!-- <v-col>
          <v-row>
            <v-col>
              <pre class="c16-20-dm-sans"><strong>{{ bill.TipFactura }}</strong></pre>
              <pre class="c13-14-dm-sans-subtext">Tip factura</pre>
            </v-col>
          </v-row>
        </v-col> -->
        <v-col>
          <v-row>
            <v-col class="text-left">
              <pre class="c16-20-dm-sans"><strong>{{ bill.DataScadenta }}</strong></pre>
              <pre class="c13-14-dm-sans-subtext">Scadent</pre>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col class="text-left">
              <pre class="c16-20-dm-sans"><strong>{{helper.formatPrice(bill.ValoareTotalFactura)}} {{bill.DenumireMoneda}}</strong></pre>
              <pre class="c13-14-dm-sans-subtext">Valoare</pre>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="col-shrink">
          <v-row>
            <v-col align="center" justify="center">
              <template v-if="bill.TipFactura == 'Factura storno'">
                <template v-if="bill.ValoareCuTVA > 0">
                  <platesteModal
                    :user="user"
                    :unformattedTotal="tp.Lei"
                    :total="new Intl.NumberFormat('ro-RO', { style: 'currency', currency: 'RON' }).format(tp.Lei)"
                    :ids="[bill.iDfg]"
                    :btnClass="'pay-btn2'"
                    v-on:hidePlateste="hidePlateste($event)"
                    v-if="bill.Sold > 0 && tp != null"
                  >
                    <template v-slot:button>
                      <span style="text-transform:none !important; color:#2DB7A4;"><b>Plateste</b></span>
                      <v-icon color="#2DB7A4">mdi-menu-right</v-icon>
                    </template>
                  </platesteModal>
                  <div v-else class="text-center">
                    <v-btn text class="my-disabled text-capitalize">
                      <v-icon color="#171619;" small>mdi-check-circle</v-icon>
                      <span class="paid">Achitata</span>
                    </v-btn>
                  </div>
                </template>
                <v-btn v-if="bill.ValoareCuTVA <= 0" text class="my-disabled text-capitalize">
                  <span class="paid">Factura storno</span>
                </v-btn>
              </template>
              <template v-else>
                <template v-if="bill.TipStareFactura == 'Stornata total'">
                  <v-btn text class="my-disabled text-capitalize">
                    <span class="paid">Factura stornata</span>
                  </v-btn>
                </template>
                <template v-else>
                  <platesteModal
                    :user="user"
                    :unformattedTotal="tp.Lei"
                    :total="new Intl.NumberFormat('ro-RO', { style: 'currency', currency: 'RON' }).format(tp.Lei)"
                    :ids="[bill.iDfg]"
                    :btnClass="'pay-btn2'"
                    v-on:hidePlateste="hidePlateste($event)"
                    v-if="bill.Sold > 0 && tp != null"
                  >
                    <template v-slot:button>
                      <span style="text-transform:none !important; color:#2DB7A4;"><b>Plateste</b></span>
                      <v-icon color="#2DB7A4">mdi-menu-right</v-icon>
                    </template>
                  </platesteModal>
                  <div v-else class="text-center">
                    <v-btn text class="my-disabled text-capitalize">
                      <v-icon color="#171619;" small>mdi-check-circle</v-icon>
                      <span class="paid">Achitata</span>
                    </v-btn>
                  </div>
                </template>
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- Bills listing -->
      <v-row>
        <v-col>
          <router-link to="/facturi"><pre class="c15-20-dm-sans" style="color:#F02D4B">Toate facturile</pre></router-link>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="c22-20-dm-sans" style="color:#171619">Locuri de consum</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="6" lg="4" v-for="(v, k) in lc" :key="k">
          <v-card class="pc v-card-custom">
          <v-row no-gutters>
            <v-col><p class="c20-20-dm-sans" style="color:#F02D4B"><b>{{v.NumePunctConsum}}</b></p></v-col>
          </v-row>
          <v-row no-gutters>
            <v-col><p class="c13-14-dm-sans-subtext">{{v.DenumireTipConsumator}}</p></v-col>
          </v-row>
          <v-row>
            <v-col class="mr-4"><p class="c14-20-dm-sans">{{v.Adresa}}</p></v-col>
          </v-row>
          <!-- <v-row>
            <v-col><p class="npg-details">Cod postal: {{v.CodPostal}}</p></v-col>
          </v-row> -->
          <v-row no-gutters>
              <v-col cols="1" style="margin-right:15px" v-if="v.AreContractEE == 1">
                <v-img
                    contain
                    align-center
                    width="35px"
                    height="33px"
                    alt="Energie"
                    :src="require('./../assets/Energy.svg')"
                />
              </v-col>
              <v-col cols="1" style="margin-right:15px" v-if="v.AreContractGN == 1">
                <v-img
                    contain
                    align-center
                    width="35px"
                    height="33px"
                    alt="Gaz"
                    :src="require('./../assets/Gas.svg')"
                />
              </v-col>
          </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <router-link to="/locuri"><pre class="c15-20-dm-sans" style="color:#F02D4B">Toate locurile de consum</pre></router-link>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import platesteModal from "./modals/Plateste";
import billModal from "./modals/Bill";
import ReferralModal from "./modals/Referral";
import validate from "./../common/js/validate.js";
import Http from "../common/js/Http.js";
import Helper from "../common/js/Helper.js";

export default {
  name: "Dashboard",
  mixins: [validate],
  data() {
    return {
        validReads : [],
        daysDiff : [],
        daysDiffEE : [],
        daysDiffGN : [],
        infoClient: null,
        infoClientDialog: false,
        autocitiriCounter : 0,
        news : '',
        graphData : [],
        tp: null,
        tpn: null,
        tpep: null,
        helper : Helper,
        bills : [],
        lc : [],
        referralData: null,
      options: {
        tooltip: {
            enabled: true,

            custom: function({series, seriesIndex, dataPointIndex, w}) {
                const totalAmount = series[seriesIndex][dataPointIndex];
                const currency = w.config.series[seriesIndex].data[dataPointIndex].currency;
                const totalEE = w.config.series[seriesIndex].data[dataPointIndex].totalEE;
                const totalGN = w.config.series[seriesIndex].data[dataPointIndex].totalGN;
                const eep = Math.round((totalEE * 100) / totalAmount);
                const gnp = Math.round((totalGN * 100) / totalAmount);

                return `
                <div style="min-width:230px; min-height:170px" class="tootltip-box">
                <br />
                <v-row style="display:block; margin-bottom:5px">
                    <v-col class="text-center">
                        <pre class="c13-14-dm-sans-subtext">${w.config.series[seriesIndex].data[dataPointIndex].description}</pre>
                    </v-col>
                </v-row>
                <v-row style="display:block; margin-bottom:10px">
                    <v-col class="text-center">
                        <pre style="height:26px;" class="c20-20-dm-sans"><b>${totalAmount} ${currency}</b></pre>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col style="float:left; margin-left:15px">
                      <div style="position:relative; display:block; width:60px; height:60px; border-radius:50%; background:conic-gradient(#F4A21F 0 ${eep}%, #4B5AD0 ${eep}% ${gnp}%);">
                        <div style="position:absolute; top:10px; left:10px; z-index:10; display:block; width:40px; height: 40px; border-radius:50%; background:#fff;"></div>
                      </div>
                    </v-col>
                    <v-col style="float:right; margin-right:15px">
                        <v-row>
                          <div style="margin-bottom:10px">
                            <span>
                              <div aria-label="Energie" role="img" class="v-image v-responsive theme--light" align-center="" style="float:left; height:22px; width:25px; margin-top:2px; margin-right:8px">
                                <div class="v-responsive__sizer" style="padding-bottom: 112.903%;"></div>
                                  <div class="v-image__image v-image__image--contain" style="background-image: url('${require('./../assets/Energy.svg')}'); background-position: center center;">
                                </div>
                                <div class="v-responsive__content" style="width: 31px;">
                                </div>
                              </div>
                              <span class="c14-20-dm-sans-medium">
                              ${totalEE} ${currency}
                              </span>
                              <span class="c13-14-dm-sans-subtext" style="display:block">Energie</span>
                            </span>
                          </div>
                          <div>
                            <span>
                              <div aria-label="Energie" role="img" class="v-image v-responsive theme--light" align-center="" style="float:left; height:22px; width:25px; margin-top:2px; margin-right:8px">
                                <div class="v-responsive__sizer" style="padding-bottom: 112.903%;"></div>
                                  <div class="v-image__image v-image__image--contain" style="background-image: url('${require('./../assets/Gas.svg')}'); background-position: center center;">
                                </div>
                                <div class="v-responsive__content" style="width: 31px;">
                                </div>
                              </div>
                              <span class="c14-20-dm-sans-medium">
                              ${totalGN} ${currency}
                              </span>
                              <span class="c13-14-dm-sans-subtext" style="display:block">Gaz</span>
                            </span>
                          </div>
                        </v-row>
                    </v-col>
                </v-row>
                </div>
                `;
            }
        },

        colors: ["#FF5F78"],
        chart: {
          width: "100%",
          height: 100,
          type: "area",
          id: "vc",
          toolbar: {
            show: false,
          },

        },

        fill: {
//         colors: 'white',
            type: 'gradient',
            gradient: {
                opacityFrom: 1.0,
                opacityTo: 0.0,
                stops: [0, 90, 100]
            }
        },
        dataLabels: {
            enabled:false,
        },
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 6,
        },
        title: {
          text: "",
          align: "left",
        },
        xaxis: {
          categories: [
            "Ian",
            "Feb",
            "Mar",
            "Apr",
            "Mai",
            "Iun",
            "Iul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: {
          tickAmount: 3,
        },
      },

      series: [
        {
            name: [],
            data: [],
            label: [],
        },
      ],
    };
  },
  watch: {
    user(info) {
      this.getReferral(info);
    }
  },
  methods: {
    async getAllAutocitiri() {
      let http = new Http();
      let autocitiri = await http.get(`/webapi/autocitire/all`);
      autocitiri = autocitiri.data;
      let currentDate = new Date(this.helper.formatDate());
      let autocitiriCurrentSend = [];
      let autocitiriToSend = [];

      autocitiri.forEach(elem => {
        if(elem.TotalCantitate != 0 || elem.InPerioadaAutocitire == '1') {

          let cStartDate = new Date(elem.DataInceputAutocitire);
          let cEndDate = new Date(elem.DataFinalAutocitire);
          if(currentDate.getTime() <= cEndDate.getTime() &&
            currentDate.getTime() >= cStartDate.getTime()
          ) {
            autocitiriCurrentSend[this.helper.daysBetween2Dates(currentDate, cEndDate)] = elem;
          } else if(currentDate.getTime() < cStartDate.getTime()) {
            autocitiriToSend[this.helper.daysBetween2Dates(currentDate, cStartDate)] = elem;
          }

        }
      })

      if(autocitiri) {
        let autocitiriTips = [];
        let today = new Date();
        autocitiri.forEach(elem => {
            if(!autocitiriTips.includes(elem.IdPunctConsum)) {
                if(
                    elem.NrCitiri.split(' ')[2] != '0' &&
                    (elem.DataInceputAutocitire != null || elem.DataFinalAutocitire != null) &&
                    elem.InPerioadaAutocitire == '1'
                )
                {
                    //let startDate = new Date(elem.DataInceputAutocitire);
                    let endDate = new Date(elem.DataFinalAutocitire);
                    let timeDIff = today.getTime() - endDate.getTime();
                    let daysDIff = Math.floor(timeDIff / (1000 * 3600 *24));
                    let news = `Perioada de autocitire este ${Helper.dateFormatter(elem.DataInceputAutocitire)} - ${Helper.dateFormatter(elem.DataFinalAutocitire)}`;
                    this.daysDiffEE.push({daysDIff, elem, news});

                    autocitiriTips.push(elem.IdPunctConsum);
                }
                this.validReads.push(elem);
            }
        });
        let daysDiff = [];
        daysDiff.push(this.daysDiffEE);
        daysDiff.push(this.daysDiffGN);

        this.news = '';

        daysDiff.forEach(elem => {
          elem.forEach(elem2 => {
              if(this.news == '' && elem2.news != null) {
                this.news = elem2.news;
              }
          })
        });
      }
    },
    hidePlateste(status) {
        return status;
    },
    async getReferral(user) {
      if (user) {
        let http = new Http();
        let res = await http.get(`/webapi/referral/getCode?partener=${user.PartnerEntityId}`);
        if (res.data && res.data.code) {
          this.referralData = res.data;
        }
      }
    },
    async getGraphData() {
        let http = new Http();
        this.graphData = await http.get(`/webapi/default/billsGraph`);
        this.graphData = await this.graphData.data.data;
        const seriesData = [];
        this.graphData.data.forEach(element => {
            seriesData.push(
                {
                    x : element.strPentruLuna.substring(0,3),
                    y : element.Total,
                    totalEE : parseFloat(element.TotalEE),
                    totalGN : parseFloat(element.TotalGN),
                    description : element.strPentruLuna,
                    currency : 'RON'
                }
            );
        });
        const max = Math.max.apply(null, seriesData);
        const min = Math.min.apply(null, seriesData);
        this.options.yaxis.min = min
        this.options.yaxis.max = max

        this.series = [{
          data: seriesData,
          label: [],
        }];
    },
    async getBills() {
        let http = new Http();
        this.bills = await http.get(`/webapi/default/bills?paginate=true`);
        this.bills = await this.bills.data.data.bills;
        Object.values(this.bills).forEach(val => {
          if(val['Sold'] <= 0) {
            val['ValoareTotalFactura'] = val['ValoareCuTVA'];
          } else {
            val['ValoareTotalFactura'] = val['Sold'];
          }
        });
    },
    async getLocuriConsum() {
        let http = new Http();
        this.lc = await http.get(`/webapi/default/locuriConsum?page=1&recsPerPage=3`);
        this.lc = this.lc.data.data.locuriConsum;
    },
    async getTotals() {
        let http = new Http();
        let tp = await http.get(`/webapi/default/totalPayout`);
        if (tp.data && tp.data.data) {
          const tpdata = tp.data.data;
          this.tp = tpdata.totaluri || null;
          this.tpn = tpdata.totaluriNerepartizat || null;
          this.tpep = tpdata.totaluriExcedentProsumator || null;
          if (this.tp && isNaN(this.tp.Lei)) {
            this.tp.Lei = 0;
          }
        }
    },
    async getInfoClient() {
      let http = new Http();
      let res = await http.get(`/webapi/default/infoClient`);
      if (res.data) {
        this.infoClient = res.data.data;
      }
    }
  },
  async created() {
    this.getAllAutocitiri();
    this.getGraphData();
    this.getBills();
    this.getLocuriConsum();
    this.getTotals();
    this.getInfoClient();
    this.$root.$children[0].accountSelected = null;
    this.$root.$children[0].selectedMenuItem = 'home';
  },
  components: {
    apexchart: VueApexCharts,
    platesteModal,
    billModal,
    ReferralModal
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=DM+Sans");

.dashboard {
  padding: 0;
}

.white-bg {
  background-color: white;
}

.close-text {
  color: #F02D4B;
  font-weight: bold;
  text-transform: none;
}

.card-nova {
  text-align: right;
  font: normal normal bold 14px/20px DM Sans;
  letter-spacing: 0px;
  color: #171619;
}

.recomandari {
    color:#F02D4B;
    text-align: right;
    font: normal normal normal 15px/20px DM Sans;
    letter-spacing: 0.3px;
}

.my-bills {
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--base-typeface-color);
  text-align: left;
  font: normal normal normal 22px/20px DM Sans;
  letter-spacing: 0px;
  color: #171619;
  opacity: 1;
}

p {
  padding: 0;
  margin: 0;
}

.npg {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-medium) var(--unnamed-font-size-16) /
    var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--nova-red);
  text-align: left;
  font: normal normal medium 16px/20px DM Sans;
  letter-spacing: 0px;
  color: #f02d4b;
  opacity: 1;
}

.npg-info {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-13) /
    var(--unnamed-line-spacing-14) var(--unnamed-font-family-dm-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--dark-grey);
  text-align: left;
  font: normal normal normal 13px/14px DM Sans;
  letter-spacing: 0px;
  color: #5d5d5c;
  opacity: 1;
}

.npg-standard {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-medium) var(--unnamed-font-size-16) /
    var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--base-typeface-color);
  font: normal normal medium 16px/20px DM Sans;
  letter-spacing: 0px;
  color: #171619;
  opacity: 1;
}

.npg-details {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-14) /
    var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--base-typeface-color);
  font: normal normal normal 14px/20px DM Sans;
  letter-spacing: 0px;
  color: #5D5D5C;
  opacity: 0.88;
}

.npg-nova {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-14) / var(--unnamed-line-spacing-20)
    var(--unnamed-font-family-dm-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--base-typeface-color);
  font: normal normal bold 14px/20px DM Sans;
  letter-spacing: 0px;
  color: #171619;
  opacity: 1;
}

.pay-btn {
    border: 2px solid var(--green-primary);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #2DB7A4;
    border-radius: 5px;
    text-transform:none !important;
}

.npg-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #b5957b1a;
  border-radius: 12px;
  margin-bottom: 10px;
}
.npg-box .col-shrink {
  flex: 0 1 150px;
}
.npg-box-pc p{
    line-height: 150%;
}

.npg-box-pc {
  min-height: 250px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #b5957b1a;
  border-radius: 12px;
  margin-bottom: 10px;
}

.paid {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-15)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0-3);
    color: var(--base-typeface-color);
    text-align: left;
    font: normal normal medium 15px/20px DM Sans;
    letter-spacing: 0.3px;
}
.tootltip-box {
    min-width: 248px;
    min-height: 203px;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 8px #00000029 !important;
}

.disable-events {
  pointer-events: none
}

.pan-rec {
  font: normal normal normal 15px/20px DM Sans !important;
  letter-spacing: 0px;
  color: #F02D4B;
}

.pc {
  height: 100%;
  padding: 20px;
}
</style>
