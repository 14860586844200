<template>
    <v-container fluid class="locuriconsum align-center">
        <v-row>
            <v-col>
                <span>
                <router-link to="/locuri">
                    <span class="c14-14-dm-sans" style="color:#F02D4B">Locuri de consum</span>
                </router-link>
                    <v-icon>mdi-menu-right</v-icon>
                    <span class="c14-14-dm-sans">{{pcName}}</span>
                </span>
            </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h1 class="c32-20-dm-sans" style="margin-bottom:10px">{{pcName}}</h1>
            <pre class="c14-14-dm-sans-subtext" v-if="pc.idTipConsumator">{{pc.idTipConsumator.Denumire}}</pre>
          </v-col>
        </v-row>
    <v-row>
      <v-col>
        <v-tabs background-color="transparent" color="red">
          <v-tab><span class="c16-20-dm-sans-medium no-text-transform">Prezentare</span></v-tab>
          <v-tab-item>
            <Prezentare />
          </v-tab-item>
          <v-tab><span class="c16-20-dm-sans-medium no-text-transform">Procese verbale</span></v-tab>
          <v-tab-item>
            <Procese />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    </v-container>
</template>

<script>
// @ is an alias to /src
import Prezentare from "./../components/Locuri/Prezentare";
import Procese from "./../components/Locuri/ProceseVerbale";
import validate from "../common/js/validate.js";
import Http from "../common/js/Http.js";

export default {
    mixins: [validate],
    name: 'PunctCosnum',
    data() {
        return {
            pc:{},
            pcName:''
        }
    },
    methods: {
        async getPunctConsum() {
            let http = new Http();
            this.pc = await http.get(`/webapi/locuriconsum/locconsum?id=${this.$route.params.id}`);
            this.pc = await this.pc.data.data.pc;
        }
    },
    created() {
        this.pcName = this.$route.params.name;
        this.getPunctConsum();
        this.$root.$children[0].accountSelected = null;
        this.$root.$children[0].selectedMenuItem = 'lc';
    },
    components: {
        Prezentare,
        Procese
    }
}
</script>
<style scoped>
</style>
