<template>
    <v-container fluid class="facturilemele">
      <v-row>
        <v-col cols="2">
          <v-select class="no-border-select" dense filled :items="items" v-model="PrescurtareTipContract" label="Tip furnizare" v-on:change="setTotal"></v-select>
        </v-col>
        <!-- <v-col cols="2">
          <v-select class="no-border-select" dense filled :items="billTypes" v-model="TipFactura" label="Tip factura" v-on:change="setTotal"></v-select>
        </v-col> -->
        <v-col cols="2">
          <v-select class="no-border-select" dense filled :items="locuriConsum" v-model="EnitateFactura" label="Loc de consum" v-on:change="setTotal"></v-select>
        </v-col>
        <v-col cols="2">
          <v-select class="no-border-select" dense filled :items="status" v-model="Status" label="Status" v-on:change="setTotal"></v-select>
        </v-col>
      </v-row>
      <v-divider></v-divider>
        <v-row>
          <v-col><h2>{{totalBills}} facturi</h2></v-col>
          <v-col class="text-right" cols="3">
            <div v-if="recurringBillsContracts && recurringBillsContracts.length != 0">
            <v-dialog
              v-model="cancelRecurringDialog"
              width="500"
            >
              <template v-slot:activator="{ on, attrs }">
              <p v-bind="attrs" v-on="on" class="c16-20-dm-sans-medium" style="color:#F02D4B; float:left; padding-top:6px">
                <span class="recurring-refresh"></span>
                <b style="float:left;">Plata recurenta: Activa ({{Object.keys(recurringBillsContracts).length}} contracte)&nbsp;</b>
              </p>
              </template>
               <v-card>
                <v-card-title class="pa-md-2">
                    <v-row no-gutters>
                        <v-col>
                            <span class="pl-3 c15-20-dm-sans-medium"><b>Plata sold curent</b></span>
                        </v-col>
                        <v-col class="text-right">
                            <v-btn icon @click="cancelRecurringDialog = false">
                                <v-icon small>mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <span class="c15-20-dm-sans-medium" style="color:black;"><b>Plata recurenta este activa pentru contractele</b></span>
                    </v-col>
                  </v-row>
                  <v-simple-table style="border: 1px solid #F2ECE6; border-radius:5px;">
                    <tbody v-for="bill in recurringBIlls" :key="bill.IdPlataRecurenta">
                        <tr v-for="contract in recurringBillsContracts" :key="contract.IdContract">
                          <td>
                            <span>
                              <v-img
                                  style="float:left; margin-right:10px;"
                                  contain
                                  width="22px"
                                  height="16px"
                                  :src="require('./../../assets/refresh.svg')"
                              />
                              <span v-if="contract.IdTipContract == '6'" class="c15-20-dm-sans" style="color:#171619;">
                                Contract Energie Electrica {{contract.SerieNumar}}
                              </span>
                              <span v-if="contract.IdTipContract == '40'" class="c15-20-dm-sans" style="color:#171619;">
                                Contract Gaze Naturale {{contract.SerieNumar}}
                              </span>
                            </span>
                          </td>
                          <v-divider></v-divider>
                        </tr>
                        <!-- <td style="padding:25px;" v-if="recurringBillsContracts && recurringBillsContracts[bill.IdPlataRecurenta]">
                          <input type="checkbox" style="display:none;" :id="bill.IdPlataRecurenta" :value="bill.IdPlataRecurenta" v-model="checkedBills" />

                          <span v-if="!Array.isArray(recurringBillsContracts[bill.IdPlataRecurenta])">
                              <v-img
                                  style="float:left; margin-right:10px;"
                                  contain
                                  width="22px"
                                  height="16px"
                                  :src="require('./../../assets/refresh.svg')"
                              />
                              <span v-if="recurringBillsContracts[bill.IdPlataRecurenta].SerieSufix == 'EE'" class="c15-20-dm-sans" style="color:#171619;">
                                Contract Energie Electrica #{{recurringBillsContracts[bill.IdPlataRecurenta].NrDocument}}
                              </span>
                              <span v-if="recurringBillsContracts[bill.IdPlataRecurenta].SerieSufix == 'GN'" class="c15-20-dm-sans" style="color:#171619;">
                                Contract Gaze Naturale #{{recurringBillsContracts[bill.IdPlataRecurenta].NrDocument}}
                              </span>
                          </span>
                          <span v-else>
                            <span v-for="contract in recurringBillsContracts[bill.IdPlataRecurenta]" :key="contract.IdContract">
                              <v-icon color="#000">mdi-refresh</v-icon>
                              {{bill.IdPlataRecurenta}}
                              <span class="c15-20-dm-sans" v-if="contract.SerieSufix == 'EE'" style="color:#171619;">
                                Contract Energie Electrica #{{bill.NrDocument}}
                              </span>
                              <span class="c15-20-dm-sans" v-if="contract.SerieSufix == 'GN'" style="color:#171619;">
                                Contract Gaze Naturale #{{bill.NrDocument}}
                              </span>
                            </span>
                          </span>
                        </td> -->
                      <!-- </tr> -->
                    </tbody>
                  </v-simple-table>
                  <v-row v-if="recurringBIlls && recurringBIlls[0]">
                    <v-col><span class="c15-20-dm-sans-medium" style="color:black;">Detalii plati</span></v-col>
                  </v-row>
                  <v-row v-if="recurringBIlls && recurringBIlls[0]">
                    <v-col>
                      <p style="color:black;" class="c22-20-dm-sans">{{recurringBIlls[0].Frecventa}}</p>
                      <p style="color:black;" class="c15-20-dm-sans-subtext">Frecventa</p>
                    </v-col>
                    <v-col>
                      <p style="color:black;" class="c22-20-dm-sans">{{recurringBIlls[0].Durata}}</p>
                      <p style="color:black;" class="c15-20-dm-sans-subtext">Durata</p>
                    </v-col>
                    <v-col>
                      <p style="color:black;" class="c22-20-dm-sans">{{parseFloat(recurringBIlls[0].PragMaxim).toFixed(2)}}</p>
                      <p style="color:black;" class="c15-20-dm-sans-subtext">Prag maxim</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn style="background: #FF5F7824" text @click="cancelRecurring();">
                      <span class="c15-20-dm-sans-medium no-text-transform" style="color: #F02D4B">Dezactiveaza</span>
                      </v-btn>
                      <v-btn text @click="cancelRecurringDialog = false">
                      <span class="c15-20-dm-sans-medium no-text-transform" style="color: #F02D4B">Inchide</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
               </v-card>
            </v-dialog>
            </div>
            <v-btn small style="background: #FF5F7824" v-on:click="exportFile">
                <v-icon color="#F02D4B">mdi-text-box-check</v-icon>
                <span class="details"><b>Export</b></span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="bills"
              :loading="loading"
              :footer-props="{
                'items-per-page-text': 'Facturi pe pagina',
                showFirstLastPage: true
              }"
              item-key="SerieNumar"
              show-select
              class="elevation-1 striped"
              loading-text="Se incarca..."
              no-results-text="Nu exista inregistrari de afisat"
              no-data-text="Nu exista inregistrari de afisat"
            >

            <template v-slot:[`footer.page-text`]="props">
              {{props.pageStart}}-{{props.pageStop}} din <span id="dtPaginationTotal">{{props.itemsLength}}</span>
            </template>

            <template v-slot:[`item.PrescurtareTipContract`]= "{item}">
              <div class="pt-4 pb-4">
                <v-img
                    v-if="item.PrescurtareTipContract == 'EE'"
                    contain
                    align-center
                    width="35px"
                    height="33px"
                    alt="Energie"
                    :src="require('./../../assets/Energy.svg')"
                />
                <v-img
                    v-if="item.PrescurtareTipContract == 'GN'"
                    contain
                    align-center
                    width="35px"
                    height="33px"
                    alt="Gaz"
                    :src="require('./../../assets/Gas.svg')"
                />
              </div>
            </template>

            <template v-slot:[`item.SerieNumar`]= "{item}">
              <v-row>
                <v-col>
                  <billModal
                    :bill="item"
                    :user="user"
                  >
                    <template v-slot:button>
                      <span style="color:#F02D4B; letter-spacing:0px" class="c15-20-dm-sans-medium">{{item.SerieNumar}}</span>
                    </template>
                  </billModal>
                </v-col>
              </v-row>
            </template>

            <!-- <template v-slot:[`item.TipFactura`]= "{item}">
              <v-row>
                <v-col align-self="center">
                  <span style="color:#171619; letter-spacing:0px" class="c15-20-dm-sans">{{item.TipFactura}}</span>
                </v-col>
              </v-row>
            </template> -->

            <template v-slot:[`item.EnitateFactura`]= "{item}">
              <v-row>
                <v-col align-self="center">
                  <span style="color:#171619; letter-spacing:0px" class="c15-20-dm-sans">{{item.EnitateFactura}}</span>
                </v-col>
              </v-row>
            </template>

            <template v-slot:[`item.DataScadenta`]= "{item}">
              <v-row>
                <v-col align-self="center">
                  <span style="color:#171619; letter-spacing:0px" class="c15-20-dm-sans">{{item.DataScadenta}}</span>
                </v-col>
              </v-row>
            </template>

            <template v-slot:[`item.ValoareTotalFactura`]= "{item}">
              <v-row>
                <v-col align-self="center">
                  <span style="color:#171619; letter-spacing:0px" class="c15-20-dm-sans">{{item.ValoareTotalFactura}} RON</span>
                </v-col>
              </v-row>
            </template>


            <template v-slot:[`item.Status`]= "{item}">
              <template v-if="item.TipFactura == 'Factura storno'">
                <template v-if="item.ValoareCuTVA > 0">
                  <platesteModal
                    :user="user"
                    :unformattedTotal="tp.Lei"
                    :total="new Intl.NumberFormat('ro-RO', { style: 'currency', currency: 'RON' }).format(tp.Lei)"
                    :ids="[item.iDfg]"
                    :btnClass="'pay-btn2'"
                    v-if="item.Status == 'Neachitata' && tp != null"
                  >
                    <template v-slot:button>
                      <span style="text-transform:none !important; color:#2DB7A4;"><b>Plateste</b></span>
                      <v-icon color="#2DB7A4">mdi-menu-right</v-icon>
                    </template>
                  </platesteModal>
                  <div v-else class="text-center">
                    <v-btn text class="my-disabled text-capitalize">
                      <v-icon color="#171619;" small>mdi-check-circle</v-icon>
                      <span class="paid">Achitata</span>
                    </v-btn>
                  </div>
                </template>
                <v-btn v-if="item.ValoareCuTVA <= 0" text class="my-disabled text-capitalize">
                  <span class="paid">Factura storno</span>
                </v-btn>
              </template>
              <template v-else>
                <template v-if="item.TipStareFactura == 'Stornata total'">
                  <v-btn text class="my-disabled text-capitalize">
                    <span class="paid">Factura stornata</span>
                  </v-btn>
                </template>
                <template v-else>
                  <platesteModal
                    :user="user"
                    :unformattedTotal="tp.Lei"
                    :total="new Intl.NumberFormat('ro-RO', { style: 'currency', currency: 'RON' }).format(tp.Lei)"
                    :ids="[item.iDfg]"
                    :btnClass="'pay-btn2'"
                    v-if="item.Status == 'Neachitata' && tp != null"
                  >
                    <template v-slot:button>
                      <span style="text-transform:none !important; color:#2DB7A4;"><b>Plateste</b></span>
                      <v-icon color="#2DB7A4">mdi-menu-right</v-icon>
                    </template>
                  </platesteModal>
                  <div v-else class="text-center">
                    <v-btn text class="my-disabled text-capitalize">
                      <v-icon color="#171619;" small>mdi-check-circle</v-icon>
                      <span class="paid">Achitata</span>
                    </v-btn>
                  </div>
                </template>
              </template>
            </template>
            </v-data-table>
          </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Http from "../../common/js/Http.js";
import Helper from "../../common/js/Helper.js";
import validate from "../../common/js/validate.js";
import platesteModal from "../modals/Plateste";
import billModal from "../modals/Bill";

export default {
    mixins: [validate],
    name: 'FacturileMele',
    data: () => ({
      lastBillSelected: null,
      checkedBills:[],
      recurringBIlls : null,
      recurringBillsContracts : null,
      cancelRecurringDialog : false,
      tp: null,
      totalBills : 0,
      locuriConsum: [],
      // billTypes: [],
      bills: [],
      status: [],
      loading :true,
      PrescurtareTipContract : '',
      TipFactura : '',
      EnitateFactura: '',
      Status: '',
      items: ['Toate', 'EE', 'GN'],
      selected: [],
    }),
  components: {
    platesteModal,
    billModal
  },

  computed: {
    headers() {
      return [
        {
          width: 35,
          align: 'center',
          sortable: false,
          value: 'PrescurtareTipContract',
          filter: f => {
            if (this.PrescurtareTipContract) {
              return this.PrescurtareTipContract == 'Toate' || String(f).toLowerCase() == this.PrescurtareTipContract.toLowerCase();
            }
            return true;
          }
        },
        {
          text: 'Serie factura',
          align: 'start',
          sortable: false,
          value: 'SerieNumar'
        },
        /* {
          text: 'Tip factura',
          value: 'TipFactura',
          filter: f => {
            if(this.TipFactura == 'Toate') {
              return true;
            }

            return (' '+f+' ' ).toLowerCase().includes( this.TipFactura.toLowerCase() ) }
        }, */
        {
          text: 'Loc de consum',
          value: 'EnitateFactura',
          filter: f => {
            if (this.EnitateFactura) {
              return this.EnitateFactura == 'Toate' || String(f).toLowerCase() == this.EnitateFactura.toLowerCase();
            }
            return true;
          }
        },
        {
          text: 'Scadent',
          value: 'DataScadenta',
        },
        {
          text: 'Valoare',
          value: 'ValoareTotalFactura'
        },
        {
          text: 'Status',
          value: 'Status',
          align: 'center',
          filter: f => {
            if(this.Status == 'Toate') {
              return true;
            }

            return (''+f+'' ).includes( this.Status )
          }
        },
      ]
    }
  },

  watch: {
    selected(to) {
      let unformattedTotal = 0;
      let total = 0;
      let ids = [];
      to.forEach(elem => {
        if(elem.Status != 'Achitata') {
          ids.push(elem.iDfg);
          unformattedTotal+=parseFloat(elem.Sold);
        }
      })
      total = new Intl.NumberFormat('ro-RO', { style: 'currency', currency: 'RON' }).format(unformattedTotal);
      this.$emit('preppedBills', unformattedTotal.toFixed(2), total, ids);
    }
  },

  methods:{
    setTotal() {
      setTimeout(() => {
        let total = 0, selector = document.querySelector('#dtPaginationTotal');
        if (selector) {
          total = selector.innerHTML;
        }
        this.totalBills = total;
      }, 10);
    },
    async cancelRecurring() {
      let http = new Http();
      let recurring = await http.post(`/webapi/euPlatesc/deleteRecurentPayment`, {

      });
      this.recurring = await recurring.data;

//      this.cancelRecurringDialog = false;
    },
    async selectedBills(bill, billObj) {
      this.lastBillSelected = billObj;
      if(!this.checkedBills.includes(bill))
        this.checkedBills.push(bill);
      else {
        let index = this.checkedBills.indexOf(bill);
        this.checkedBills.splice(index, 1);
      }
    },
    async getRecurring() {
        let http = new Http();
        let recurringBIlls = await http.get(`/webapi/euPlatesc/getRecurring`);
        this.recurringBIlls = await recurringBIlls.data.recurringBills;
        this.recurringBillsContracts = await recurringBIlls.data.contracts;
    },
    async getTotals() {
        let http = new Http();
        this.tp = await http.get(`/webapi/default/totalPayout`);
        this.tp = await this.tp.data.data.totaluri;
        if (this.tp && isNaN(this.tp.Lei)) {
          this.tp.Lei = 0;
        }
    },

    // async getBillTypes() {
    //     let http = new Http();
    //      this.billTypes = await http.get(`/webapi/facturi/types`).then(
    //       response => this.billTypes = Object.values(response.data.data.types));
    // },

    async getBills() {
        let http = new Http();
        this.bills = await http.get(`/webapi/default/bills`).then(
          (response) => this.bills = response.data.data.bills);
        this.loading = false;
        let bt = ['Toate'];
        let lc = ['Toate'];
        Object.values(this.bills).forEach(val => {
          if(val['Sold'] <= 0) {
            val['Status'] = 'Achitata';
            val['ValoareTotalFactura'] = val['ValoareCuTVA'];
          } else {
            val['Status'] = 'Neachitata';
            val['ValoareTotalFactura'] = val['Sold'];
          }
          if(!bt.includes(val['EnitateFactura']))
            lc.push(val['EnitateFactura']);
          if(!bt.includes(val['TipFactura']))
            bt.push(val['TipFactura']);
        })
        this.totalBills = this.bills.length;
        // this.billTypes = bt;
        this.locuriConsum = lc;
        this.status = ['Toate', 'Achitata', 'Neachitata'];
    },

    exportFile(ev) {
      ev.preventDefault();
      let selected = '';
      if (this.selected.length) {
        let ids = [];
        for (let s in this.selected) {
          ids.push('bills[]=' + this.selected[s].SerieNumar.trim());
        }
        selected = '&' + ids.join('&');
      }
      const filters = `?contract_type=${this.PrescurtareTipContract}&bill_type=${this.TipFactura}&bill_entity=${this.EnitateFactura}&bill_status=${this.Status}${selected}`;
      Helper.downloadXLSX(`/webapi/exports/bills` + filters, `facturi`);
    }
  },
  created() {
    this.getRecurring();
//    this.getBillTypes();
  },
  async mounted () {
    await this.getTotals();
    this.getBills();
  }
}
</script>

<style scoped>
.pay-btn {
    border: 2px solid var(--green-primary);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #2DB7A4;
    border-radius: 5px;
    color: #2DB7A4;
}

.my-disabled {
  pointer-events: none
}

.v-data-table > .v-data-table__wrapper > table {
  display: none !important;
  width:50%;
}

.facturilemele {
    background: #FFFCF9 0% 0% no-repeat padding-box !important;
}

.recurring-refresh {
  margin-top: 2px;
  margin-right: 2px;
  float: left;
  width: 20px;
  height: 20px;
  background-color: #F02D4B;
  mask: url('./../../assets/refresh.svg');
  mask-size: contain;
  mask-repeat: no-repeat;

}

/* .v-data-table >>> tbody tr:nth-of-type(odd){
    background-color: #FAEBD789 !important
} */
</style>
