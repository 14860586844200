var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"optiuni",attrs:{"fluid":"","align-start":""}},[_c('v-row',[_c('v-col',[_c('h2',{staticClass:"c22-20-dm-sans"},[_vm._v(_vm._s(_vm.oDataCount)+" contracte")])])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.oData,"footer-props":{
                'items-per-page-text' : 'Inregistrari pe pagina',
                showFirstLastPage: true,
                },"item-key":"name","loading-text":"Se incarca...","no-results-text":"Nu exista inregistrari de afisat","no-data-text":"Nu exista inregistrari de afisat"},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" din "+_vm._s(props.itemsLength)+" ")]}},{key:"item.Nume",fn:function(ref){
                var item = ref.item;
return [(item.ContractEE == '1')?_c('v-img',{attrs:{"contain":"","align-center":"","width":"35px","height":"33px","alt":"Energie","src":require('./../../assets/Energy.svg')}}):_vm._e(),(item.ContractGN == '1')?_c('v-img',{attrs:{"contain":"","align-center":"","width":"35px","height":"33px","alt":"Gaz","src":require('./../../assets/Gas.svg')}}):_vm._e()]}},{key:"item.NrContract",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"c16-20-dm-sans-medium"},[_vm._v(_vm._s(item.NrContract))])]}},{key:"item.Denumire",fn:function(ref){
                var item = ref.item;
return [_c('BillSendOptions',{attrs:{"item":item},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('span',{staticClass:"no-text-transform c3-tf"},[_vm._v(_vm._s(item.Denumire))]),_c('v-icon',{attrs:{"color":"#F02D4B"}},[_vm._v("mdi-arrow-down")])]},proxy:true}],null,true)})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }