<template>
  <v-dialog
    persistent
    max-width="40%"
    v-model="viewStatus"
  >
  <v-card>
    <v-container fluid style="padding:0;">
      <v-card-title class="pa-md-2">
        <v-row no-gutters>
            <v-col>
              <span class="pl-3 c15-20-dm-sans-medium" v-if="item !== null"><b>{{item.Name}}</b></span>
            </v-col>
            <v-col class="text-right">
                <v-btn icon @click="close">
                    <v-icon small>mdi-close</v-icon>
                </v-btn>
            </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row v-if="loading === true">
          <v-col align="center">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <h3 class="c15-20-dm-sans" style="color:#171619"><b>{{punctConsum.NumePunctConsum}}</b></h3>
            <span class="c14-20-dm-sans" style="color:#5D5D5C">{{address}}</span>
          </v-col>
          <v-col class="text-right">
            <v-btn rounded v-if="suggestion.status">
              <span class="c14-20-dm-sans no-text-transform"><b>Status:
                <v-icon color="black" v-if="suggestion.status.Name === 'Asteptam feedback'">mdi-reload</v-icon>
                <v-icon v-if="suggestion.status.Name === 'Rezolvat'">mdi-checkbox-marked-circle</v-icon>
                <v-icon v-if="suggestion.status.Name === 'Nou'">mdi-flash</v-icon>
                <v-icon v-if="suggestion.status.Name === 'Inchis'">mdi-lock</v-icon>
                {{suggestion.status.Name}}
                </b></span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="suggestion.type">
          <v-col cols="4">
            <span class="c15-24-dm-sans-medium" style="color:#171619">Tip tichet</span>
          </v-col>
          <v-col cols="8">
            <span class="c15-24-dm-sans" style="color:#171619">{{suggestion.type.Name}}</span>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="suggestion.type">
          <v-col cols="4">
            <span class="c15-24-dm-sans-medium" style="color:#171619">Data aparitie problema:</span>
          </v-col>
          <v-col cols="8">
            <span class="c15-24-dm-sans" style="color:#171619">{{Helper.dateFormatter(suggestion.CreationDate)}}</span>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="firstTime">
          <v-col cols="4">
            <span class="c15-24-dm-sans-medium" style="color:#171619">Prima data:</span>
          </v-col>
          <v-col cols="8">
            <span class="c15-24-dm-sans" style="color:#171619">{{firstTime}}</span>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="suggestion.type">
          <v-col cols="4">
            <span class="c15-24-dm-sans-medium" style="color:#171619">Tip furnizare:</span>
          </v-col>
          <v-col cols="8">
            <span class="c15-24-dm-sans" style="color:#171619">{{item.tipEEGN}}</span>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="suggestion.theme">
          <v-col cols="4">
            <span class="c15-24-dm-sans-medium" style="color:#171619">Tema solicitarii:</span>
          </v-col>
          <v-col cols="8">
            <span class="c15-24-dm-sans" style="color:#171619">{{suggestion.theme.Name}}</span>
          </v-col>
        </v-row>
        <!-- Comment -->
        <v-form ref="form" v-model="valid" v-if="!loading && addComment">
        <v-divider style="margin:15px 0"></v-divider>
        <v-alert type="error" text v-if="errorMessage"><span class="c15-20-s-dm-sans">{{errorMessage}}</span></v-alert>
        <v-row no-gutters>
          <v-textarea type="text" v-model="details" :rules="[rules.required]" label="Adauga comentariu" outlined></v-textarea>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-btn class="green-btn" v-on:click="sendComment">
              Trimite
            </v-btn>
            <AddDocument v-on:add-to-files="addToFiles">
              <template v-slot:button>
                <v-btn text class="ml-3 download-btn no-text-transform">
                  <v-icon color="#F02D4B">mdi-cloud-upload</v-icon>
                  <span class="c15-20-dm-sans"><b>Incarca document</b></span>
                </v-btn>
              </template>
            </AddDocument>
          </v-col>
        </v-row>
        <v-card v-for="(file, fkey) in files" :key="fkey" style="margin-top:15px">
          <v-row no-gutters class="pt-2">
            <v-col cols="1" class="text-center">
              <v-icon style="color:black;">mdi-paperclip</v-icon>
            </v-col>
            <v-col>
              <span class="c13-20-dm-sans" style="color:#171619"><strong>{{file.Name}}</strong></span><br>
              <span class="c13-20-dm-sans" style="color:#171619">{{file.FileTypeName}}</span>
            </v-col>
            <v-col class="text-right pa-2">
              <v-btn icon v-on:click.once="deleteFileComment(fkey)">
              <v-icon color="red">mdi-delete-forever</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="8">
              <span class="c13-14-dm-sans" style="color:#5D5D5C">{{file.Comment}}</span>
            </v-col>
            <v-col cols="3">
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="7">
              <v-btn text class="no-text-transform c13-14-dm-sans" style="color:#F02D4B">
                {{file.FileName}}
              </v-btn>
            </v-col>
            <v-col cols="4">
            </v-col>
          </v-row>
        </v-card>
        </v-form>
        <!-- // Comment -->
        <div v-for="(itm, key) in items" :key="key" style="margin-top:15px">
        <v-divider></v-divider>
        <v-row>
          <v-col>
            <span class="c15-20-dm-sans" style="color:#171619;">
              <v-icon style="float:left; font-size:22px; color:black">mdi-account</v-icon>
              <strong>{{itm.creationUser.Name}} in data de {{Helper.dateFormatter(itm.CreationDate)}}</strong>
            </span>
          </v-col>
        </v-row>
        <v-row v-if="itm.ConclusionId">
          <v-col>
            <span style="color:#171619; letter-spacing:0px" class="close-span c15-20-dm-sans">Concluzie analizare plangere: {{itm.conclusion.Name}}</span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <p class="c15-20-dm-sans" style="color:#171619">{{itm.Comment}}</p>
          </v-col>
        </v-row>
        <v-row v-if="itm.file.length">
          <v-col>
            <pre class="c15-20-dm-sans" style="color:#171619"><strong>Documente atasate</strong></pre>
          </v-col>
        </v-row>
        <v-card v-for="(f, key) in itm.file" :key="key" style="margin-bottom:15px">
          <v-row no-gutters class="pt-2">
            <v-col cols="1" class="text-center">
              <v-icon style="color:black;">mdi-paperclip</v-icon>
            </v-col>
            <v-col>
              <span class="c13-20-dm-sans" style="color:#171619"><strong>{{f.Name}}</strong></span><br>
              <span class="c13-20-dm-sans" style="color:#171619">{{f.fileType.Name}}</span>
            </v-col>
            <v-col class="text-right pa-2">
              <v-btn icon v-on:click.once="deleteFile(f.Id, key)">
              <v-icon color="red">mdi-delete-forever</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="8">
              <span class="c13-14-dm-sans" style="color:#5D5D5C">{{f.Comment}}</span>
            </v-col>
            <v-col cols="3">
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="7">
              <v-btn text class="no-text-transform c13-14-dm-sans" style="color:#F02D4B" v-on:click="downloadFile(f.Id, f.FileName)">
                {{f.FileName}}
              </v-btn>
            </v-col>
            <v-col cols="4">
            </v-col>
          </v-row>
        </v-card>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text v-on:click.stop="close" class="ml-3 close-text">
          Inchide
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
  </v-dialog>
</template>

<script>
import globals from "./../../../common/js/globals";
import Http from "./../../../common/js/Http";
import Helper from "./../../../common/js/Helper";
import AddDocument from "./AddDocument";

export default {
  name: 'ViewTichet',
  mixins: [globals],
  props: {
    status : Boolean,
    item: Object
  },
  data() {
    return {
      Helper,
      loading: true,
      viewStatus : this.status,
      suggestion: {},
      punctConsum: {},
      items: {},
      address: '',
      firstTime: '',

      errorMessage: '',
      addComment: false,
      valid: true,
      files: [],
      details: ''
    }
  },
  watch: {
    status: function(newV) {
      this.viewStatus = newV;
      if (newV) {
        this.getDetails();
      }
    }
  },
  components: {
    AddDocument
  },
  created() {
  },
  methods: {
    close: function() {
      this.loading = true;
      this.viewStatus = false;
      this.suggestion = {};
      this.punctConsum = {};
      this.items = {};
      this.address = '';
      this.firstTime = '';
      this.$emit('status', this.viewStatus);
    },
    downloadFile(id, name) {
      (new Http()).get(`/webapi/suggestion/downloadFile?id=${id}`, { responseType: 'blob' }).then(response => {
        if (response.data.size) {
          let blob = new Blob([response.data], { type: response.data.type });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = name;
          link.click();
        }
      });
    },
    deleteFile(id, k) {
      (new Http()).get(`/webapi/suggestion/deleteFile?id=${id}`).then(response => {
        if (response.data) {
          this.items[k].file = response.data.files;
        }
      });
    },
    addToFiles(item) {
      if (item) {
        this.files.push(item);
      }
    },
    deleteFileComment(key) {
      let file = this.files[key].FileName;
      (new Http()).get(`/webapi/suggestion/deleteUploadedFile?file=${file}`).then(() => {
        this.files.splice(key, 1);
      });
    },
    sendComment() {
      if (this.$refs.form.validate() && this.addComment) {
        let formData = new FormData();
        formData.append('details', this.details);
        this.files.forEach((v, k) => {
          for (let i in v) {
            formData.append('files[' + k + '][' + i + ']', v[i]);
          }
        });
        (new Http()).post(`/webapi/suggestion/addComment?id=${this.item.Id}`, formData).then(response => {
          if (response.data.error) {
            this.errorMessage = response.data.error.message;
          } else {
            this.details = '';
            this.files = [];
            this.$refs.form.resetValidation();
            this.getDetails();
          }
        });
      }
    },
    async getDetails() {
      let http = new Http();
      let res = await http.get(`/webapi/suggestion/details?id=${this.item.Id}`);
      if (res.data) {
        this.suggestion = await res.data.suggestion;
        this.punctConsum = await res.data.punctConsum;
        this.items = await res.data.items;
        this.addComment = await res.data.addComment;
        let pc = this.punctConsum;
        let address = '';
        if (pc.idJudet) {
          pc.idJudet.Judet + ', ';
        }
        if (pc.Localitate) {
          address += pc.Localitate + ', ';
        }
        if (pc.Strada) {
          address += pc.Strada;
        }
        if (pc.Numar) {
          address += ' Nr. ' + pc.Numar;
        }
        if (pc.Bloc) {
          address += ', Bloc ' + pc.Bloc;
        }
        if (pc.Scara) {
          address += ', Scara ' + pc.Scara;
        }
        if (pc.Apartament) {
          address += ', Ap. ' + pc.Apartament;
        }
        if (pc.CodPostal) {
          address += ' Cod Postal: ' + pc.CodPostal;
        }
        this.address = address;

        this.firstTime = this.suggestion.FirstTimeProblem ? 'Da' : 'Nu';
      }
      this.loading = false;
    }
  }
}
</script>

<style scoped>
.download-btn {
  color: #F02D4B;
  background-color: #FF5F7824
}
.download-btn .v-icon {
  margin-right: 5px;
}
.close-span {
  background-color: #BCAFA122;
  padding: 5px 10px;
  border-radius: 15px;
}
.close-text {
  color: #F02D4B;
  font-weight: bold;
  text-transform: none;
}
.green-btn {
  color: white;
  text-transform:none !important;
  background: var(--green-primary) 0% 0% no-repeat padding-box !important;
  background: #2DB7A4 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
}
</style>
