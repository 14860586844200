<template>
    <v-container style="display:none;">
        <v-form action="https://secure.euplatesc.ro/tdsprocess/tranzactd.php" method="post" name="gateway" ref="euPlatescForm" id="euPlatescForm" target="_blank">
            <input name="fname" type="hidden" :value="data.fname" />
            <input name="lname" type="hidden" :value="data.lname" />
            <input name="country" type="hidden" :value="data.country" />
            <input name="company" type="hidden" :value="data.company" />
            <input name="city" type="hidden" :value="data.city" />
            <input name="add" type="hidden" :value="data.add" />
            <input name="email" type="hidden" :value="data.email" />
            <input name="phone" type="hidden" :value="data.phone" />
            <input name="fax" type="hidden" :value="data.fax" />
            <!-- snd billing details -->

            <!-- daca detaliile de shipping difera -->
            <!-- begin shipping details -->
            <input name="sfname" type="hidden" :value="data.sfname" />
            <input name="slname" type="hidden" :value="data.slname" />
            <input name="scountry" type="hidden" :value="data.scountry" />
            <input name="scompany" type="hidden" :value="data.scompany" />
            <input name="scity" type="hidden" :value="data.scity" />
            <input name="sadd" type="hidden" :value="data.sadd" />
            <input name="semail" type="hidden" :value="data.semail" />
            <input name="sphone" type="hidden" :value="data.sphone" />
            <input name="sfax" type="hidden" :value="data.sfax" />
            <!-- end shipping details -->

            <input v-if="recurent" type="hidden" name="recurent" value="Base">
            <input v-if="recurent" type="hidden" name="recurent_freq" size=3 :value="data.recurent_freq" />
            <input v-if="recurent" type="hidden" name="recurent_exp" size="12" :value="data.recurent_exp" />

            <input type="hidden" name="amount" size="12" :value="data.amount" MAXLENGTH="12" />
            <input TYPE="hidden" name="curr" size="5" :value="data.curr" MAXLENGTH="3" />
            <input type="hidden" name="invoice_id" size="32" :value="data.invoice_id" MAXLENGTH="32" />
            <input type="hidden" name="order_desc" size="32" :value="data.order_desc" MAXLENGTH="50" />
            <input TYPE="hidden" name="merch_id" size="15" :value="data.merch_id" />
            <input TYPE="hidden" name="timestamp" size="15" :value="data.timestamp" />
            <input TYPE="hidden" name="nonce" size="35" :value="data.nonce"   />
            <input TYPE="hidden" name="fp_hash" size="40" :value="data.fp_hash"   />
            <p><a href="javascript:gateway.submit();" class="txtCheckout">Go Now!</a></p>
            <v-btn type="submit">Apasa</v-btn>
        </v-form>
    </v-container>
</template>

<script>

export default {
    name: 'FormPlateste',
    props: {
      data : Object,
      recurent: Boolean,
      selectedFrecventa: Number,
      selectedDurata: Number
    },
    data: () => ({

    }),

    methods:{

    },

    watch: {
    },

    created() {
        // let d = Array.from(document.querySelectorAll('#euPlatescForm input')).reduce((acc, input) => ({...acc, 'a': input.value}), {});
    },

    mounted() {
       document.getElementById('euPlatescForm').submit();
       // this.$refs.euPlatescForm.submit();
    },
}
</script>

<style scoped>

</style>
