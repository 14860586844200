<template>
  <v-dialog
    persistent
    max-width="40%"
    v-model="viewStatus"
  >
  <template v-slot:activator="{ on, attrs }">
    <div v-bind="attrs" v-on="on">
      <slot name="button"></slot>
    </div>
  </template>
  <v-card>
    <v-container fluid style="padding:0;">
      <v-card-title class="pa-md-2">
        <v-row no-gutters>
          <v-col>
            <span class="pl-3 c15-20-dm-sans-medium"><b>Tichet suport</b></span>
          </v-col>
          <v-col class="text-right">
            <v-btn icon @click="close">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-form ref="form" v-model="valid">
      <v-card-text>
        <v-row no-gutters>
          <v-select v-model="type" :items="types" item-text="name" item-value="id" :rules="[rules.required]" label="Tip ticket" v-on:change="changeType(type)" outlined class="form-select"></v-select>
        </v-row>
        <v-row no-gutters>
          <v-text-field type="text" v-model="subject" :rules="[rules.required]" label="Subiect" outlined></v-text-field>
        </v-row>
        <v-row no-gutters>
          <v-textarea type="text" v-model="details" :rules="[rules.required]" label="Detalii" outlined></v-textarea>
        </v-row>
        <v-row no-gutters>
          <v-select v-model="pc" :items="pcs" item-text="name" item-value="id" :rules="[rules.requiredselect]" label="Punct de consum" outlined class="form-select"></v-select>
        </v-row>
        <v-row no-gutters>
          <v-menu v-model="eventDateMenu" :close-on-content-click="false" :nudge-right="40" :rules="[rules.required]" transition="scale-transition" offset-y min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="eventDate" label="Data aparitiei problemei" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined class="form-select"></v-text-field>
            </template>
            <v-date-picker :locale="locale" color="red lighten-1" v-model="eventDate" @input="eventDateMenu = false"></v-date-picker>
          </v-menu>
        </v-row>
        <v-row no-gutters>
          <v-select v-model="firstTime" :items="firsts" item-text="name" item-value="id" :rules="[rules.requiredselect]" label="Indicati daca problema a aparut pentru prima data su nu" outlined class="form-select"></v-select>
        </v-row>
        <v-row no-gutters>
          <v-select v-model="supplyType" :items="supplyTypes" item-text="name" item-value="id" :rules="[rules.requiredselect]" label="Tip furnizare" v-on:change="changeSupplyType(supplyType)" outlined class="form-select"></v-select>
        </v-row>
        <v-row no-gutters>
          <v-select v-model="theme" :items="themes" item-text="name" item-value="id" :rules="[rules.requiredselect]" label="Tema solicitarii" outlined class="form-select"></v-select>
        </v-row>
        <!-- Attached Documents -->
        <v-row>
          <v-col>
            <pre class="c15-20-dm-sans" style="color:#171619"><strong>Documente atasate</strong></pre>
          </v-col>
        </v-row>
        <v-card v-for="(file, key) in files" :key="key" style="margin-bottom:15px">
          <v-row no-gutters class="pt-2">
            <v-col cols="1" class="text-center">
              <v-icon style="color:black;">mdi-paperclip</v-icon>
            </v-col>
            <v-col>
              <span class="c13-20-dm-sans" style="color:#171619"><strong>{{file.Name}}</strong></span><br>
              <span class="c13-20-dm-sans" style="color:#171619">{{file.FileTypeName}}</span>
            </v-col>
            <v-col class="text-right pa-2">
              <v-btn icon v-on:click.once="deleteFile(key)">
              <v-icon color="red">mdi-delete-forever</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="8">
              <span class="c13-14-dm-sans" style="color:#5D5D5C">{{file.Comment}}</span>
            </v-col>
            <v-col cols="3">
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="7">
              <v-btn text class="no-text-transform c13-14-dm-sans" style="color:#F02D4B">
                {{file.FileName}}
              </v-btn>
            </v-col>
            <v-col cols="4">
            </v-col>
          </v-row>
        </v-card>
        <!-- // Attached Documents -->
        <v-row>
          <v-col>
            <AddDocument v-on:add-to-files="addToFiles">
              <template v-slot:button>
                <v-btn text class="download-btn no-text-transform">
                  <v-icon color="#F02D4B">mdi-plus-circle</v-icon>
                  <span class="c15-20-dm-sans"><b>Adauga document</b></span>
                </v-btn>
              </template>
            </AddDocument>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn class="ml-3 green-btn" v-on:click="submit">
          Trimite
        </v-btn>
        <v-btn text v-on:click.stop="close" class="ml-3 close-text">
          Inchide
        </v-btn>
      </v-card-actions>
      </v-form>
    </v-container>
  </v-card>
  </v-dialog>
</template>

<script>
import globals from "./../../../common/js/globals";
import Http from "./../../../common/js/Http.js";
import AddDocument from "./AddDocument";

export default {
  name: 'AddTichet',
  mixins: [globals],
  data() {
    return {
      viewStatus: false,
      valid: true,
      eventDateMenu: false,
      types: [],
      pcs: [],
      firsts: [],
      supplyTypes: [],
      themes: [],

      files: [],

      type: 0,
      subject: '',
      details: '',
      pc: null,
      eventDate: '',
      firstTime: null,
      supplyType: null,
      theme: null
    }
  },
  watch: {
    viewStatus(status) {
      if (status) {
        this.setEventDate();
        this.getTypes();
        this.getFirstTimes();
        this.getLC();
      }
    }
  },
  components: {
    AddDocument
  },
  created() {
  },
  methods: {
    close() {
      this.viewStatus = false;
    },
    submit() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append('type', this.type);
        formData.append('subject', this.subject);
        formData.append('details', this.details);
        formData.append('pc', this.pc);
        formData.append('eventDate', this.eventDate);
        formData.append('firstTime', this.firstTime);
        formData.append('supplyType', this.supplyType);
        formData.append('theme', this.theme);
        this.files.forEach((v, k) => {
          for (let i in v) {
            formData.append('files[' + k + '][' + i + ']', v[i]);
          }
        });
        (new Http()).post(`/webapi/suggestion/add`, formData).then(() => {
          this.files = [];
          this.type = 0;
          this.subject = '';
          this.details = '';
          this.pc = null;
          this.firstTime = null;
          this.supplyType = null;
          this.theme = null;
          this.$refs.form.resetValidation();
          this.$emit('refresh-tickets');
        });
        this.close();
      }
    },
    addToFiles(item) {
      if (item) {
        this.files.push(item);
      }
    },
    deleteFile(key) {
      let file = this.files[key].FileName;
      (new Http()).get(`/webapi/suggestion/deleteUploadedFile?file=${file}`).then(() => {
        this.files.splice(key, 1);
      });
    },
    setEventDate() {
      this.eventDate = new Date().toISOString().substr(0, 10);
    },
    changeType(id) {
      this.supplyTypes = [];
      this.themes = [];
      this.getSupplyTypes(id);
    },
    changeSupplyType(id) {
      this.themes = [];
      this.getThemes(id);
    },
    async getTypes() {
      let http = new Http();
      let res = await http.get(`/webapi/suggestion/getTypes`);
      if (res.data) {
        for (let i in res.data.data) {
          this.types.push({id: i, name: res.data.data[i]});
        }
      }
    },
    async getFirstTimes() {
      let http = new Http();
      let res = await http.get(`/webapi/suggestion/getFirstTimes`);
      if (res.data) {
        for (let i in res.data.data) {
          this.firsts.push({id: i, name: res.data.data[i]});
        }
      }
    },
    async getLC() {
      let http = new Http();
      let res = await http.get(`/webapi/default/locuriconsum`);
      if (res.data) {
        const self = this;
        res.data.data.locuriConsum.forEach(function (v) {
          self.pcs.push({id: v.IdPunctConsum, name: v.NumePunctConsum});
        });
      }
    },
    async getSupplyTypes(id) {
      let http = new Http();
      let res = await http.get(`/webapi/suggestion/getSupplyTypes?id=${id}`);
      if (res.data) {
        for (let i in res.data.data) {
          this.supplyTypes.push({id: i, name: res.data.data[i]});
        }
      }
    },
    async getThemes(id) {
      let http = new Http();
      let res = await http.get(`/webapi/suggestion/getThemes?id=${id}`);
      if (res.data) {
        for (let i in res.data.data) {
          this.themes.push({id: i, name: res.data.data[i]});
        }
      }
    }
  }
}
</script>
<style scoped>
.download-btn {
  color: #F02D4B;
  background-color: #FF5F7824
}
.download-btn .v-icon {
  margin-right: 5px;
}
.close-text {
  color: #F02D4B;
  font-weight: bold;
  text-transform: none;
}
.green-btn {
  color: white;
  text-transform:none !important;
  background: var(--green-primary) 0% 0% no-repeat padding-box !important;
  background: #2DB7A4 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
}
</style>
