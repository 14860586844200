<template>
  <v-container fluid align-start class="dashboard">
    <v-row>
      <v-col class="text-center">
        <h1>401</h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
