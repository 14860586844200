<template>
  <v-container align-start fluid class="referral">
    <v-row>
      <v-col>
        <span v-if="user">
          <router-link to="/account">
            <span class="c14-14-dm-sans" style="color:#F02D4B">{{user.Name}}</span>
          </router-link>
          <v-icon>mdi-menu-right</v-icon>
          <span class="c14-14-dm-sans">{{pageTitle}}</span>
        </span>
      </v-col>
      <v-col align="right">
        <ReferralModal v-if="referralData" :info="referralData">
          <template v-slot:button>
            <v-btn color="#F02D4B" class="text-none">
              <v-icon color="#ffffff" class="mr-2">mdi-account-multiple-plus</v-icon>
              <span class="pan-rec">Recomanda Nova</span>
            </v-btn>
          </template>
        </ReferralModal>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p class="c32-20-dm-sans">{{pageTitle}}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tabs background-color="transparent" color="red">
          <v-tab><span style="text-transform:none !important; letter-spacing:0px; height:21px;" class="c16-20-dm-sans-medium">Finalizate</span></v-tab>
          <v-tab-item>
            <ReferralFinalized />
          </v-tab-item>
          <v-tab><span style="text-transform:none !important; letter-spacing:0px; height:21px;" class="c16-20-dm-sans-medium">Trimise</span></v-tab>
          <v-tab-item>
            <ReferralSent />
          </v-tab-item>
          <v-tab><span style="text-transform:none !important; letter-spacing:0px; height:21px;" class="c16-20-dm-sans-medium">Primite</span></v-tab>
          <v-tab-item>
            <ReferralReceived />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import validate from "./../common/js/validate.js";
import Http from "./../common/js/Http";
import ReferralFinalized from "./../components/Referral/Finalized";
import ReferralSent from "./../components/Referral/Sent";
import ReferralReceived from "./../components/Referral/Received";
import ReferralModal from "./../components/modals/Referral";

export default {
  name: 'Referral',
  mixins: [validate],
  data() {
    return {
      pageTitle: 'Recomandari',
      tab: null,
      referralData: null
    }
  },
  created() {
    this.$root.$children[0].accountSelected = this.pageTitle;
    this.$root.$children[0].selectedMenuItem = null;
  },
  watch: {
    user(info) {
      this.getReferral(info);
    }
  },
  methods: {
    async getReferral(user) {
      if (user) {
        let http = new Http();
        let res = await http.get(`/webapi/referral/getCode?partener=${user.PartnerEntityId}`);
        if (res.data && res.data.code) {
          this.referralData = res.data;
        }
      }
    }
  },
  components: {
    ReferralFinalized,
    ReferralSent,
    ReferralReceived,
    ReferralModal
  }
}
</script>
<style scoped>
.pan-rec {
  font: normal normal normal 15px/20px DM Sans !important;
  letter-spacing: 0px;
  color: #ffffff;
}
</style>
