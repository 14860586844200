<template>
  <div>
    <v-dialog
      v-model="modificaModal0"
      max-width="700"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn x-small text class="pink-red-btn ma-0 pa-0" v-bind="attrs" v-on="on">
            <slot name="button"></slot>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="pa-md-2">
            <v-row no-gutters>
                <v-col>
                    <span class="pl-3 c15-20-dm-sans-medium"><b>Trimite autocitire {{atype}}</b></span>
                </v-col>
                <v-col class="text-right">
                    <v-btn icon @click.stop="close">
                        <v-icon small>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-start">
            <v-row>
            <v-col><p class="c15-20-dm-sans-medium" style="color:black">Locuri de consum</p></v-col>
            </v-row>
            <div style="border: solid 1px #F2ECE6; border-radius:10px;">
            <v-row v-for="autocitire in validReads" :key="autocitire.NumePunctConsum+autocitire.IdTipuriLuniAni"
            class="mb-1">
                <v-col cols="1" align-self="center">
                    <v-img
                        contain
                        class="pl-10"
                        width="24px"
                        height="25px"
                        alt="Punct consum"
                        :src="require('../../../assets/house.svg')"
                    />
                </v-col>
                <v-col cols="8">
                    <span class="c15-20-dm-sans-medium" style="color:#000000">{{autocitire.NumePunctConsum}}</span>
                    <br>
                    <span class="c15-20-dm-sans-subtext" v-if="[1,6].includes(Number(autocitire.IdTipConsumator))">Consumator noncasnic</span>
                    <span class="c15-20-dm-sans-subtext" v-if="[2,3].includes(Number(autocitire.IdTipConsumator))">Consumator casnic</span>
                    <pre
                        style="color:black;"
                        v-if="autocitire.AutocitireActiva == '0' && (autocitire.DataInceputAutocitire == null || autocitire.DataFinalAutocitire == null)"
                    ><b>Contractul nu prevede autocitire.</b></pre>
                    <pre
                        style="color:black;"
                        v-if="autocitire.AutocitireActiva == '1' && autocitire.DataInceputAutocitire != null && autocitire.DataFinalAutocitire != null"
                    ><b>Perioada de autocitirie este {{Helper.dateFormatter(autocitire.DataInceputAutocitire)}} - {{Helper.dateFormatter(autocitire.DataFinalAutocitire)}}</b></pre>
                    <pre
                        style="color:black;"
                        v-if="autocitire.AutocitireActiva == '1' && autocitire.InPerioadaAutocitire == '1' && autocitire.DejaAutocitit == '1'"
                    ><b>Au fost introduse deja autocitiri pentru acest punct de consum.</b></pre>
                    <pre
                        style="color:black;"
                        v-else-if="autocitire.AutocitireActiva == '1' && autocitire.InPerioadaAutocitire == '1' && autocitire.DejaCititDeFurnizor == '1'"
                    ><b>Au fost introduse deja citiri pentru acest punct de consum, de catre furnizor.</b></pre>
                    <pre
                        style="color:black;"
                        v-else-if="autocitire.AutocitireActiva == '1' && autocitire.InPerioadaAutocitire == '0'"
                    ><b>Nu se pot introduce inca autocitiri pentru acest punct de consum.</b></pre>
                    <pre
                        style="color:black; overflow-wrap:anywhere;"
                        v-if="autocitire.NrCitiri.split(' ')[2] == '0'"
                    ><b>Operatorul de distributie transmite datele locului<br>de consum in luna urmatoare lunii de consum.<br>Va asiguram de faptul ca de luna viitoare veti avea<br>posibilitatea introducerii indexulul autocitit.<br>Va multumim pentru intelegere!</b></pre>
                </v-col>
                <v-col cols="3">
                    <indexModal
                        :item=autocitire
                        v-on:edited="edited($event)"
                        v-if="autocitire.NrCitiri.split(' ')[2] != '0'
                        && autocitire.InPerioadaAutocitire == '1'
                        && autocitire.AutocitireActiva == '1'
                        && autocitire.DejaCititDeFurnizor == '0'
                        && (autocitire.DataInceputAutocitire != null || autocitire.DataFinalAutocitire != null)"
                    >
                        <template v-slot:button>
                            <v-btn x-small text style="background: #FF5F7824;" class="pink-red-btn no-text-transform">
                                <span class="c14-19-dm-sans">Alege</span>
                            </v-btn>
                        </template>
                    </indexModal>
                </v-col>
                <div v-if="(validReads[validReads.length-1].NumePunctConsum+validReads[validReads.length-1].IdTipuriLuniAni) != (autocitire.NumePunctConsum+autocitire.IdTipuriLuniAni)" style="height:2px; width:90%; margin: 0 auto; background-color:#F2ECE6;"></div>
            </v-row>
            </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            class="ml-3 close-text"
            text
            @click="close"
          >
            Inchide
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import indexModal from '../../modals/autocitire/Index';
import Helper from "./../../../common/js/Helper.js";
export default {
    name: 'adaugaAutocitire',
    props: {
      autocitiri : {
          type: Array,
          default: null
      },
      atype: String
    },
    data () {
        return {
            Helper,
            validReads : [],
            daysDiffEE : [],
            daysDiffGN : [],
            modificaModal0: false,
        }
    },

    methods: {
        close() {
            this.modificaModal0 = false;
        },
        edited(event) {
            console.log(event);
        }
    },

    watch: {
    },

    async created() {
        let diffType = null;

        if(this.autocitiri.length) {
            let autocitiriTips = [];
            let today = new Date();
            this.autocitiri.forEach(elem => {
                if (
                    elem.IdPunctConsum
                    && !autocitiriTips.includes(elem.IdPunctConsum)
                    && elem.InPerioadaAutocitire != null
                    && elem.AutocitireActiva != null
                ) {
                    if (
                        elem.InPerioadaAutocitire == 1
                        && (elem.DataInceputAutocitire != null || elem.DataFinalAutocitire != null)
                    ) {
                        let endDate = new Date(elem.DataFinalAutocitire);
                        let timeDIff = today.getTime() - endDate.getTime();
                        let daysDIff = Math.floor(timeDIff / (1000 * 3600 *24));
                        if(elem.EE == '1') {
                            let newsEE = `Perioada de autocitire este ${Helper.dateFormatter(elem.DataInceputAutocitire)} - ${Helper.dateFormatter(elem.DataFinalAutocitire)}`;
                            diffType = 'EE';
                            this.daysDiffEE.push({daysDIff, elem, newsEE});
                        }
                        else if(elem.GN == '1') {
                            let newsGN = `Perioada de autocitire este ${Helper.dateFormatter(elem.DataInceputAutocitire)} - ${Helper.dateFormatter(elem.DataFinalAutocitire)}`;
                            diffType = 'GN';
                            this.daysDiffGN.push({daysDIff, elem, newsGN});
                        }
                    }
                    autocitiriTips.push(elem.IdPunctConsum);
                    this.validReads.push(elem);
                }
            });
            this.validReads.sort((a, b) => (a.NumePunctConsum > b.NumePunctConsum) ? 1 : -1);
        }
        let daysDIff = [];
        daysDIff.push(this.daysDiffEE);
        daysDIff.push(this.daysDiffGN);
        // console.log(Helper.empty(this.daysDiffEE));
        // console.log(Helper.empty(this.daysDiffGN));
        this.$emit(`daysDiff${diffType}`, daysDIff);
        // console.log(daysDIff);
    },

    components: {
        indexModal
    }
}
</script>
<style scoped>
.close-text {
  color: #F02D4B;
  font-weight: bold;
  text-transform: none;
}

.pink-red-btn {
    font: normal normal normal 15px/20px DM Sans;
    letter-spacing: 0.3px;
    color: #F02D4B;
}

.text-red-btn {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-15)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0-3);
    color: var(--nova-red);
    font: normal normal normal 15px/20px DM Sans;
    color: #F02D4B;
}

.details-text {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-13)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--dark-grey);
    text-align: left;
    font: normal normal normal 13px/14px DM Sans;
    letter-spacing: 0px;
    color: #5D5D5C;
}

.green-white-btn {
    background: var(--green-primary) 0% 0% no-repeat padding-box !important ;
    background: #2DB7A4 0% 0% no-repeat padding-box !important;
    border-radius: 5px;
}

.main-tf {
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nova-red);
    letter-spacing: 0px;
    color: #F02D4B;
}

.if {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal normal 12px/14px DM Sans;
    letter-spacing: 0px;
}

.if > span {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal normal 12px/14px DM Sans;
    letter-spacing: 0px;
    color: #CE203B;
}

.arrow {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-font-awesome-5-free);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--dark-grey);
    text-align: left;
    font: normal normal normal 14px/20px Font Awesome 5 Free;
    letter-spacing: 0px;
    color: #5D5D5C;
}
.pink-btn {
    background: #FF5F7824 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1;
    color: #F02D4B;
}
</style>
