<template>
  <v-dialog
    persistent
    max-width="650"
    v-model="modalUser"
  >
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot name="button"></slot>
      </span>
    </template>
    <v-card>
      <v-container fluid style="padding:0;">
        <v-card-title class="pa-md-2">
          <v-row no-gutters>
            <v-col>
              <span class="pl-3 c15-20-dm-sans-medium"><b>{{title}}</b></span>
            </v-col>
            <v-col class="text-right">
              <v-btn icon @click.stop="close">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref="form" v-model="valid">
        <v-card-text>
          <v-alert type="success" text v-if="successMessage"><span class="c15-20-s-dm-sans">{{successMessage}}</span></v-alert>
          <v-alert type="error" text v-if="errorMessage"><span class="c15-20-s-dm-sans">{{errorMessage}}</span></v-alert>
          <v-tabs v-model="tab" background-color="transparent" color="red">
            <v-tab><span style="text-transform:none !important; letter-spacing:0px; height:21px; color:#5D5D5C;" class="c16-20-dm-sans">Date personale</span></v-tab>
            <v-tab-item>
              <br>
              <v-row no-gutters>
                <v-text-field type="text" v-model="name" :rules="[rules.required]" label="Nume" outlined></v-text-field>
              </v-row>
              <v-row no-gutters>
                <v-text-field type="text" v-model="email" :rules="[rules.required, rules.email]" label="Email" outlined></v-text-field>
              </v-row>
              <v-row no-gutters>
                <v-text-field type="text" v-model="mobile" label="Mobil" outlined></v-text-field>
              </v-row>
            </v-tab-item>
            <v-tab><span style="text-transform:none !important; letter-spacing:0px; height:21px; color:#5D5D5C;" class="c16-20-dm-sans">Date suplimentare</span></v-tab>
            <v-tab-item>
              <br>
              <v-row no-gutters>
                <v-text-field type="text" v-model="func" label="Functia" outlined></v-text-field>
              </v-row>
              <div v-if="departmentsList.length" style="margin-bottom:25px">
              <span class="c15-20-dm-sans-medium" style="color:#171619">Departamente</span>
              <v-row no-gutters v-for="(dep, key) in departmentsList" :key="key">
                <v-col cols="12">
                  <v-checkbox
                    v-model="departments"
                    :label="dep.Name"
                    color="black"
                    :value="dep.Id"
                    :disabled="isMaster ? dep.Id == 1 : false"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
              </div>
              <!-- <v-row no-gutters>
                <v-text-field type="text" v-model="cui" label="CUI" outlined disabled></v-text-field>
              </v-row> -->
              <v-row no-gutters>
                <v-text-field type="text" v-model="cnp" label="CNP/CUI" outlined></v-text-field>
              </v-row>
              <v-row no-gutters>
                <v-text-field type="text" v-model="regcom" label="Reg. Com." outlined></v-text-field>
              </v-row>
              <v-row no-gutters>
                <v-text-field type="text" v-model="phone" label="Telefon" outlined></v-text-field>
              </v-row>
              <v-row no-gutters>
                <v-text-field type="text" v-model="fax" label="Fax" outlined></v-text-field>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn class="ml-3 green-btn" @click="send">
            Salveaza
          </v-btn>
          <v-btn class="ml-3 close-text" text @click.stop="close">
            Inchide
          </v-btn>
        </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import globals from "./../../common/js/globals";
import Http from "./../../common/js/Http";

export default {
  name: 'User',
  props: ['type', 'item'],
  mixins: [globals],
  data() {
    return {
      tab: null,
      modalUser: false,
      title: '',
      departmentsList: [],
      isMaster: false,
      successMessage: '',
      errorMessage: '',
      valid: true,
      formURL: null,
      name: '',
      email: '',
      mobile: '',
      func: '',
      departments: [],
      // cui: '',
      cnp: '',
      regcom: '',
      phone: '',
      fax: ''
    }
  },
  created() {
    switch (this.type) {
      case 'add':
        this.title = 'Adauga utilizator';
        this.formURL = `/webapi/account/addUser`;
        break;
      case 'edit':
        this.title = 'Modifica utilizator';
        this.formURL = `/webapi/account/editUser?id=${this.item.Id}`;
        break;
    }
    if (this.item) {
      this.name = this.item.Name;
      this.email = this.item.Email;
      this.mobile = this.item.Mobil;
      this.func = this.item.Functia;
      const departmentsAssoc = this.item.departmentsAssoc;
      for (let d in departmentsAssoc) {
        this.departments.push(departmentsAssoc[d].DepartmentId);
      }
      // this.cui = this.item.CUI;
      this.cnp = this.item.CNP
      this.regcom = this.item.RegisterNumber;
      this.phone = this.item.TelefonFix;
      this.fax = this.item.Fax;
    }
  },
  watch: {
    modalUser(status) {
      if (status) {
        this.getDepartments();
      }
    }
  },
  methods: {
    close() {
      this.successMessage = '';
      this.errorMessage = '';
      this.modalUser = false;
    },
    send() {
      this.successMessage = '';
      this.errorMessage = '';
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append('name', this.name);
        formData.append('email', this.email);
        formData.append('mobile', this.mobile);
        formData.append('func', this.func);
        formData.append('departments', this.departments.join(','));
        // formData.append('cui', this.cui);
        formData.append('cnp', this.cnp);
        formData.append('regcom', this.regcom);
        formData.append('phone', this.phone);
        formData.append('fax', this.fax);

        (new Http()).post(this.formURL, formData).then(response => {
          if (response.data.success) {
            this.successMessage = response.data.success.message;
            if (this.type == 'add') {
              this.name = '';
              this.email = '';
              this.mobile = '';
              this.func = '';
              this.departments = [];
              // this.cui = '';
              this.cnp = '';
              this.regcom = '';
              this.phone = '';
              this.fax = '';
              this.$refs.form.resetValidation();
            }
            this.$emit('refresh-users');
          }
          if (response.data.error) {
            this.errorMessage = response.data.error.message;
          }
        });
      }
    },
    async getDepartments() {
      let http = new Http();
      let res = await http.get(`/webapi/account/getUserDepartments`);
      if (res.data) {
        this.departmentsList = res.data.data;
        this.isMaster = res.data.master;
      }
    }
  }
}
</script>
<style scoped>
.close-text {
  color: #F02D4B;
  font-weight: bold;
  text-transform: none;
}
.green-btn {
  color: white;
  text-transform:none !important;
  background: var(--green-primary) 0% 0% no-repeat padding-box !important;
  background: #2DB7A4 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
}
</style>
