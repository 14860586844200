<template>
    <v-container fluid class="suport">
        <v-row>
          <v-col>
            <p class="c32-20-dm-sans">Suport</p>
          </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
                <AddTichet v-on:refresh-tickets="getTickets">
                <template v-slot:button>
                <v-card class="v-card-custom">
                    <v-card-title>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col class="pl-5">
                                <div style="float:left;">
                                    <v-img
                                        contain
                                        align-center
                                        width="33px"
                                        height="35px"
                                        :src="require('./../assets/suport-call.svg')"
                                    />
                                </div>
                                <div style="float:left;" class="ml-3">
                                    <h3 class="c16-20-dm-sans-medium" style="color:#171619">Suport clienti</h3>
                                    <div style="padding-top:6px">
                                    <b class="c16-20-dm-sans-medium" style="color:#171619">0219246</b>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="11">
                                <v-btn text class="no-text-transform">
                                    <span class="c15-20-dm-sans" style="color:#F02D4B">Deschide tichet</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                </template>
                </AddTichet>
            </v-col>
            <v-col cols="3">
                <v-card class="v-card-custom">
                    <v-card-title>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col  class="pl-5">
                                <div style="float:left;">
                                    <v-img
                                        contain
                                        align-center
                                        width="33px"
                                        height="35px"
                                        :src="require('./../assets/suport-clienti.svg')"
                                    />
                                </div>
                                <div style="float:left;" class="ml-3">
                                    <h3 class="c16-20-dm-sans-medium" style="color:#171619">Nova Power & Gas Hotline</h3>
                                    <span class="c15-20-dm-sans" style="color:#5D5D5C">Distributie gaze naturale<br>Campulung Moldovenesc, Moara si Huedin.</span>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="11">
                                <pre class="ml-5 c15-20-dm-sans" style="color:#171619"><b>0800 801 010</b></pre>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row>
            <v-col><h2>Solicitari/Reclamatii</h2></v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-select class="no-border-select" dense :items="tipTichet" v-model="selectedTipTichet" label="Tip tichet" filled></v-select>
          </v-col>
          <v-col cols="2">
            <v-select class="no-border-select" dense :items="locDeConsum" v-model="selectedLocDeConsum" label="Loc de consum" filled></v-select>
          </v-col>
          <v-col cols="2">
            <v-select class="no-border-select" dense :items="tipFurnizare" v-model="selectedTipFurnizare" label="Tip furnizare" filled></v-select>
          </v-col>
          <v-col cols="2">
            <v-select class="no-border-select" dense :items="status" v-model="selectedStatus" label="Status" filled></v-select>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="suggestions"
              :footer-props="{
                'items-per-page-text' : 'Facturi pe pagina',
                showFirstLastPage: true,
              }"
              item-key="name"
              class="elevation-1 striped"
              @click:row="showDetails"
              loading-text="Se incarca..."
              no-results-text="Nu exista inregistrari de afisat"
              no-data-text="Nu exista inregistrari de afisat"
            >
            <template v-slot:[`item.typeName`]= "{item}">
                <v-row align-content="center">
                    <v-col>
                         <span class="c15-20-dm-sans">{{item.typeName}}</span>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:[`item.Id`]= "{item}">
                <v-row align-content="center">
                    <v-col>
                        <span class="c15-20-dm-sans">{{item.Id}}</span>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:[`item.tipEEGN`]= "{item}">
                <v-row v-if="item.tipEEGN === 'Energie electrica'" align-content="center">
                    <v-col align-self="center">
                        <v-img
                            style="float:left"
                            contain
                            align-center
                            width="35px"
                            height="33px"
                            alt="Energie"
                            :src="require('./../assets/Energy.svg')"
                        />
                        <div class="mt-1">
                            <span class="c15-20-dm-sans ml-2">{{item.tipEEGN}}</span>
                        </div>
                    </v-col>
                </v-row>
                <v-row v-if="item.tipEEGN === 'Gaze naturale'" align-content="center">
                    <v-col align-self="center">
                        <v-img
                            style="float:left"
                            contain
                            align-center
                            width="35px"
                            height="33px"
                            alt="Gaz"
                            :src="require('./../assets/Gas.svg')"
                        />
                        <div class="mt-1">
                            <span class="c15-20-dm-sans ml-2">{{item.tipEEGN}}</span>
                        </div>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:[`item.Name`]= "{item}">
                <v-row align-content="center">
                    <v-col>
                        <span class="c15-20-dm-sans" style="color:#F02D4B"><b>{{item.Name}}</b></span>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:[`item.punctConsumName`]= "{item}">
                <v-row align-content="center">
                    <v-col>
                        <span class="c15-20-dm-sans">{{item.punctConsumName}}</span>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:[`item.dataAparitie`]= "{item}">
                <v-row align-content="center">
                    <v-col>
                        <span class="c15-20-dm-sans">{{item.dataAparitie}} - {{Helper.getYearMonthDayFromDate(item.CreationDate, '.')}}</span>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:[`item.statusName`]="{item}">
                    <span v-if="item.statusName === 'Asteptam feedback'"><v-icon color="black">mdi-reload</v-icon></span>
                    <span v-if="item.statusName === 'Rezolvat'"><v-icon>mdi-checkbox-marked-circle</v-icon></span>
                    <span v-if="item.statusName === 'Nou'"><v-icon>mdi-flash</v-icon></span>
                    <span v-if="item.statusName === 'Inchis'"><v-icon>mdi-lock</v-icon></span>
                    <span align-center class="c15-20-dm-sans ml-2"><b>{{item.statusName}}</b></span>
            </template>


            <template v-slot:[`footer.page-text`]="props">
              {{props.pageStart}}-{{props.pageStop}} din {{props.itemsLength}}
            </template>

            </v-data-table>
          </v-col>
        </v-row>
        <div><ViewTichet v-on:status="changeView($event)" :status="viewStatus" :item="selectedRow"></ViewTichet></div>
    </v-container>
</template>

<script>
import ViewTichet from './../components/modals/suport/ViewTichet';
import AddTichet from './../components/modals/suport/Add';
import validate from "./../common/js/validate.js";
import Http from "../common/js/Http.js";
import Helper from "../common/js/Helper.js";

export default {
    name: 'Suport',
    mixins: [validate],

    data: () => ({
        Helper,
        tipTichet: ['Toate'],
        selectedTipTichet: '',
        locDeConsum: ['Toate'],
        selectedLocDeConsum: '',
        tipFurnizare: ['Toate'],
        selectedTipFurnizare: '',
        status: ['Toate'],
        selectedStatus: '',
        viewStatus : false,
        selectedRow : null,
        items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
        suggestions : [],
        selected: [],

        vitems: [
        {
            TypeId: 'Reclamatie',
            Id: '5265',
            SourceId: 'EE',
            Name: 'Lorem Ipsum issimply dummy text...',
            lc: 'Cirt Carmen Dorina',
            CreationDate: '12.09.2020',
            StatusId: 0,
        },
        {
            TypeId: 'Reclamatie',
            Id: '5265',
            SourceId: 'EE',
            Name: 'Lorem Ipsum issimply dummy text...',
            lc: 'Cirt Carmen Dorina',
            CreationDate: '12.09.2020',
            StatusId: 0,
        },
        {
            TypeId: 'Reclamatie',
            Id: '5265',
            SourceId: 'EE',
            Name: 'Lorem Ipsum issimply dummy text...',
            lc: 'Cirt Carmen Dorina',
            CreationDate: '12.09.2020',
            StatusId: 0,
        },
        {
            TypeId: 'Reclamatie',
            Id: '5265',
            SourceId: 'EE',
            Name: 'Lorem Ipsum issimply dummy text...',
            lc: 'Cirt Carmen Dorina',
            CreationDate: '12.09.2020',
            StatusId: 0,
        },

        ]
    }),

  components: {
      ViewTichet,
      AddTichet
  },

  created() {
      this.getTickets();
      this.$root.$children[0].accountSelected = null;
      this.$root.$children[0].selectedMenuItem = 'support';
  },

  computed: {
    headers() {
        return [
        {
            text: 'Tip tichet',
            align: 'start',
            sortable: false,
            value: 'typeName',
            filter: f => {
                if(this.selectedTipTichet == 'Toate') {
                return true;
                }
                return (' '+f+' ' ).toLowerCase().includes( this.selectedTipTichet.toLowerCase())
            }
        },
        { text: 'Id', value: 'Id'},
        { text: 'Tip furnizare', value: 'tipEEGN',
            filter: f => {
                if(this.selectedTipFurnizare == 'Toate') {
                return true;
                }
                return (' '+f+' ' ).toLowerCase().includes( this.selectedTipFurnizare.toLowerCase())
            }

        },
        { text: 'Subiect', value: 'Name' },
        { text: 'Loc de consum', value: 'punctConsumName',
            filter: f => {
                if(this.selectedLocDeConsum == 'Toate') {
                return true;
                }
                return (' '+f+' ' ).toLowerCase().includes( this.selectedLocDeConsum.toLowerCase())
            }
        },
        { text: 'Data aparitie problema', value: 'dataAparitie' },
        { text: 'Status', value: 'statusName',
            filter: f => {
                if(this.selectedStatus == 'Toate') {
                return true;
                }
                return (' '+f+' ' ).toLowerCase().includes( this.selectedStatus.toLowerCase())
            }

        },
        ]
    }
  },

  methods:{
    async getTickets() {
        let http = new Http();
        let suggestions = await http.get(`/webapi/suggestion/index`, {});
        this.suggestions = await suggestions.data.data.suggestions;
        this.suggestions.forEach(elem => {
            elem.statusName = elem.status.Name;
            if (!this.status.includes(elem.statusName))
                this.status.push(elem.statusName);

            if (elem.punctConsum && elem.punctConsum.NumePunctConsum) {
                elem.punctConsumName = elem.punctConsum.NumePunctConsum;
                if (!this.locDeConsum.includes(elem.punctConsumName))
                    this.locDeConsum.push(elem.punctConsumName);
            } else {
                elem.punctConsumName = null;
            }

            elem.typeName = elem.type.Name;
            if (!this.tipTichet.includes(elem.typeName))
                this.tipTichet.push(elem.typeName);

            if (elem.supplyType.Id == "17" || elem.supplyType.Id == "30")
                elem.tipEEGN = 'Energie electrica';
            else if (elem.supplyType.Id == "18" || elem.supplyType.Id == "32")
                elem.tipEEGN = 'Gaze naturale';
            if (!this.tipFurnizare.includes(elem.tipEEGN))
                this.tipFurnizare.push(elem.tipEEGN);

            if (elem.FirstTimeProblem == "1") {
                elem.dataAparitie = 'Prima data';
            } else {
                elem.dataAparitie = 'Nu este prima data';
            }
        });
    },

    showDetails(row) {
        this.selectedRow = row;
        this.viewStatus = !this.viewStatus;
    },
    changeView(status) {
        this.viewStatus = status;
    }
  },
}
</script>

<style scoped>
.pay-btn {
    border: 2px solid var(--green-primary);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #2DB7A4;
    border-radius: 5px;
    color: #2DB7A4;
}
/* .v-data-table >>> tbody tr:nth-of-type(odd){
    background-color: #FAEBD789 !important
} */
</style>
