<template>
  <v-container align-start fluid class="contracts">
    <v-row>
      <v-col>
        <span v-if="user">
          <router-link to="/account">
            <span class="c14-14-dm-sans" style="color:#F02D4B">{{user.Name}}</span>
          </router-link>
          <v-icon>mdi-menu-right</v-icon>
          <span class="c14-14-dm-sans">{{pageTitle}}</span>
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p class="c32-20-dm-sans">{{pageTitle}}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-select :items="filterTypes" v-model="filterType" v-on:change="getContracts()" label="Tip furnizare" class="no-border-select" filled></v-select>
      </v-col>
      <v-col cols="2">
        <v-select :items="filterStatuses" v-model="filterStatus" v-on:change="getContracts()" label="Status" class="no-border-select" filled></v-select>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col><h2>{{total}} inregistrari</h2></v-col>
      <v-col class="text-right">
        <v-btn small style="background: #FF5F7824" v-on:click="exportFile">
          <v-icon color="#F02D4B">mdi-text-box-check</v-icon>
          <span class="details"><b>Export</b></span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" sm="6" lg="4" v-for="(contract, key) in contracts" :key="key">
        <v-card elevation="5" class="cb v-card-custom">
          <v-row no-gutters>
            <v-col style="flex: 0 1 45px">
              <v-img
                v-if="contract.IdTipContract == 6"
                contain
                align-center
                min-width="35px"
                min-height="35px"
                width="35px"
                height="33px"
                alt="Energie"
                :src="require('./../assets/Energy.svg')"
              />
              <v-img
                  v-if="contract.IdTipContract == 40"
                  contain
                  align-center
                  min-width="35px"
                  min-height="35px"
                  width="35px"
                  height="33px"
                  alt="Gaz"
                  :src="require('./../assets/Gas.svg')"
                />
            </v-col>
            <v-col>
              <h2 class="c20-20-dm-sans" style="color:black;"><b>Contract {{contract.NrContract}}</b></h2>
              <span class="c13-14-dm-sans" style="color:#5D5D5C">{{contract.PunctConsumAdresaFull}}</span>
            </v-col>
            <v-col  style="flex: 0 1 100px" v-if="contract.IsActive == false">
              <span style="color:#171619; letter-spacing:0px" class="close-span c15-20-dm-sans-medium">Incetat</span>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="4">
              <pre class="c16-20-dm-sans" style="color:black;"><b>{{Helper.dateFormatter(contract.DataSemnare)}}</b></pre>
              <pre class="c13-14-dm-sans" style="color:#5D5D5C">Semnat</pre>
            </v-col>
            <v-col cols="4">
              <pre class="c16-20-dm-sans" style="color:black;"><b>{{Helper.dateFormatter(contract.DataVigoare)}}</b></pre>
              <pre class="c13-14-dm-sans" style="color:#5D5D5C">Intrat in vigoare</pre>
            </v-col>
            <v-col cols="4" v-if="contract.IsActive == false">
              <pre class="c16-20-dm-sans" style="color:black;"><b>{{Helper.dateFormatter(contract.DataIncetare)}}</b></pre>
              <pre class="c13-14-dm-sans" style="color:#5D5D5C">Incetat</pre>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <span class="c13-20-dm-sans" style="color:black">{{contract.ModTransmitereFact}}</span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import validate from "./../common/js/validate.js";
import Http from "./../common/js/Http";
import Helper from "./../common/js/Helper";

export default {
  name: 'Contracts',
  mixins: [validate],
  data() {
    return {
      Helper,
      pageTitle: 'Contracte',
      total: 0,
      contracts: [],
      filterTypes: ['Toate'],
      filterType: '',
      filterStatuses: ['Toate', 'Active', 'Incetate'],
      filterStatus: ''
    }
  },
  created() {
    this.getContracts();
    this.$root.$children[0].accountSelected = null;
    this.$root.$children[0].selectedMenuItem = null;
  },
  watch: {
  },
  methods: {
    async getContracts() {
      let http = new Http();
      let res = await http.get(`/webapi/account/contracte?contract_type=${this.filterType}&status=${this.filterStatus}`);
      if (res.data) {
        this.total = res.data.counter;
        this.contracts = res.data.data;
        if (this.filterTypes.length == 1) {
          this.contracts.forEach((v) => {
            const name = v.TipContractNume;
            if (this.filterTypes.indexOf(name) === -1) {
              this.filterTypes.push(name);
            }
          });
        }
      }
    },
    exportFile(ev) {
      ev.preventDefault();
      const filters = `?contract_type=${this.filterType}&status=${this.filterStatus}`;
      Helper.downloadXLSX(`/webapi/exports/contracts` + filters, `contracte`);
    }
  },
  components: {
  }
}
</script>
<style scoped>
.close-span {
  background-color: #BCAFA122;
  padding: 5px 10px;
  border-radius: 15px;
}
.cb {
  height: 100%;
  padding: 20px;
}
.cb hr {
  margin: 15px 0;
}
</style>
