<template>
  <v-dialog
    persistent
    max-width="650"
    v-model="modalLocuriAddress"
  >
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot name="button"></slot>
      </span>
    </template>
    <v-card>
      <v-container fluid style="padding:0;">
        <v-card-title class="pa-md-2">
          <v-row no-gutters>
            <v-col>
              <span class="pl-3 c15-20-dm-sans-medium"><b>Schimbare adresa</b></span>
            </v-col>
            <v-col class="text-right">
              <v-btn icon @click.stop="close">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref="form" v-model="valid">
        <v-card-text>
          <v-row no-gutters>
            <v-select :items="countries" v-model="ptara" outlined class="form-select" label="Tara"></v-select>
          </v-row>
          <v-row no-gutters>
            <v-select :items="formattedCounties" v-model="pjudet" outlined class="form-select" label="Judet"></v-select>
          </v-row>
          <v-row no-gutters>
            <v-combobox :items="formattedCities" v-model="porasName" outlined class="form-select" hide-selected label="Localitate"></v-combobox>
          </v-row>
          <v-row no-gutters>
            <v-text-field class="form-select" outlined type="string" v-model="pstrada" label="Strada"></v-text-field>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field class="pa-2 form-select" outlined type="number" v-model="pnumar" label="Numar"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field class="pa-2 form-select" outlined type="string" v-model="pbloc" label="Bloc"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field class="pa-2 form-select" outlined type="number" v-model="pscara" label="Scara"></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field class="pa-2 form-select" outlined type="number" v-model="ptronson" label="Tronson"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field class="pa-2 form-select" outlined type="string" v-model="petaj" label="Etaj"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field class="pa-2 form-select" outlined type="number" v-model="pap" label="Ap"></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field class="pa-2 form-select" outlined type="number" v-model="psector" label="Sector"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field class="pa-2 form-select" outlined type="number" v-model="pcodpostal" label="Cod postal"></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-divider></v-divider>
          <br>
          <v-row>
            <v-col>
              <v-textarea name="input-7-1" outlined v-model="pobs" label="Observatii" auto-grow background-color="transparent" value=""></v-textarea>
            </v-col>
          </v-row>
          <v-alert type="success" text v-if="successMessage"><span class="c15-20-s-dm-sans">{{successMessage}}</span></v-alert>
          <v-alert type="error" text v-if="errorMessage"><span class="c15-20-s-dm-sans">{{errorMessage}}</span></v-alert>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn class="ml-3 green-btn" @click="send">
            Trimite cererea
          </v-btn>
          <v-btn class="ml-3 close-text" text @click.stop="close">
            Inchide
          </v-btn>
        </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Http from "./../../common/js/Http";

export default {
  name: 'LocuriAddress',
  props: ['pc'],
  data() {
    return {
      modalLocuriAddress: false,
      valid: true,
      successMessage: '',
      errorMessage: '',
      countries: [],
      counties: [],
      formattedCounties: [],
      cities: [],
      formattedCities: [],

      ptara: '',
      ptaraName: '',
      pjudet: '',
      pjudetName: '',
      poras: '',
      porasName: '',
      pstrada: '',
      pnumar: '',
      pbloc: '',
      pscara: '',
      ptronson: '',
      petaj: '',
      pap: '',
      psector: '',
      pcodpostal: '',
      pobs: ''
    }
  },
  created() {
  },
  watch: {
    async ptara(to) {
      this.ptaraName = this.pc.idTara.Tara;
      let http = new Http();
      this.counties = await http.get(`/webapi/account/getcountiesbycountry?country=${to}`);
      this.counties = this.counties.data;
    },
    counties() {
      if (this.counties != null) {
        this.formattedCounties = [];
        this.counties.forEach(elem => {
          this.formattedCounties.push({
            text: elem.DenumireFaraDiacritice,
            value: elem.IdJudete
          });
        });
      }
      this.pjudet = this.pc.IdJudet;
      this.pjudetName = this.pc.idJudet.Judet;
    },
    async pjudet(to) {
      if(this.counties != null) {
        this.counties.forEach(elem => {
          if (to == elem.IdJudete)
            this.pjudetName = elem.DenumireFaraDiacritice
        });
        let http = new Http();
        this.cities = await http.get(`/webapi/account/getcities?country=${this.ptara}&county=${to}`);
        this.cities = this.cities.data;
        if (this.cities != null) {
          this.formattedCities = [];
          this.cities.forEach(elem => {
            let label = elem.label.split('>>');
            this.formattedCities.push({
              text: label[0].trim(),
              value: elem.value
            })
          });
        }
        this.porasName = {text: this.pc.Localitate};
      }
    },
    async porasName(to) {
      this.formattedCities.forEach(elem => {
        if (elem.text == to.text) {
          if ( (typeof to === 'object' || typeof to === 'function') && (to !== null) ) {
            this.porasName = elem.text;
            this.poras = elem.value;
          }
        }
      });
    },
    async modalLocuriAddress(status) {
      if (status && this.pc) {
        this.getCountries();

        this.ptara = this.pc.IdTara;
        this.pstrada = this.pc.Strada;
        this.pnumar = this.pc.Numar;
        this.pbloc = this.pc.Bloc;
        this.pscara = this.pc.Scara;
        this.petaj = this.pc.Etaj;
        this.pap = this.pc.Apartament;
        this.pcodpostal = this.pc.CodPostal;
      }
    }
  },
  methods: {
    close() {
      this.successMessage = '';
      this.errorMessage = '';
      this.modalLocuriAddress = false;
    },
    async send() {
      this.successMessage = '';
      this.errorMessage = '';
      let formData = new FormData();
      formData.append('entity-id', this.pc.IdPunctConsum);
      formData.append('entity-type-id', 3);
      formData.append('address-type-id', 3);
      formData.append('associate', 0);
      formData.append('update', 0);
      formData.append('association-address-id', '');
      formData.append('newAddress', 0);

      formData.append('set-address-country-name', this.ptaraName);
      formData.append('set-address-country', this.ptara);

      formData.append('set-address-county-name', this.pjudetName);
      formData.append('set-address-county', this.pjudet);

      formData.append('set-address-city-name', this.porasName);
      formData.append('set-address-city', this.poras);

      formData.append('set-address-street', this.pstrada);
      formData.append('set-address-street-id', this.pstrada);

      formData.append('set-address-street-number', this.pnumar);
      formData.append('set-address-sector', this.psector);
      formData.append('set-address-building', this.pbloc);
      formData.append('set-address-entrance', this.pscara);
      formData.append('set-address-section', this.ptronson);
      formData.append('set-address-floor', this.petaj);
      formData.append('set-address-apartment', this.pap);
      formData.append('set-address-postal-code', this.pcodpostal);
      formData.append('set-address-comment', this.pobs);

      let http = new Http();
      let getPostalCode = await http.get(`/webapi/account/getCheckPostalCode?city=${this.poras}&street=${this.pstrada}&pcode=${this.pcodpostal}`);
      if(getPostalCode.data['status']) {
        let addressCheck = await http.post(`/webapi/address/verify`, formData);
        if (addressCheck.data.error) {
          this.errorMessage = addressCheck.data.error.message;
        }
        if (addressCheck.data.address) {
          let saveData = new FormData();
          Object.keys(addressCheck.data.address).forEach(k => {
              saveData.append(k, addressCheck.data.address[k]);
          });

          let addressSave = await http.post(`/webapi/locuriconsum/updateAddress?id=${this.pc.IdPunctConsum}`, saveData);
          if (addressSave.data.success) {
            this.successMessage = addressSave.data.success.message;
          }
          if (addressSave.data.error) {
            this.errorMessage = addressSave.data.error.message;
          }
        }
      } else {
        this.errorMessage = 'Nu am gasit o informatii pentru acest cod postal.';
      }
    },
    async getCountries() {
      let http = new Http();
      let account = await http.get(`/webapi/account`);
      this.account = await account.data;

      for (const [key, value] of Object.entries(this.account.countries)) {
        this.countries.push({
          text: value,
          value: key
        });
      }
    }
  }
}
</script>
<style scoped>
.close-text {
  color: #F02D4B;
  font-weight: bold;
  text-transform: none;
}
.green-btn {
  color: white;
  text-transform:none !important;
  background: var(--green-primary) 0% 0% no-repeat padding-box !important;
  background: #2DB7A4 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
}
</style>
