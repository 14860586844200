export default {
  data() {
    return {
      locale: 'ro-ro',
      currentYear: new Date().getFullYear(),
      currentDay: new Date().getDate(),
      currentMonth: new Date().getMonth()+1,
      currentDate: new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate(),

      rules: {
        required: (value) => {
          if (value !== null) {
            if (value.length > 0) {
              return true;
            } else {
              return "Camp obligatoriu!";
            }
          } else return true;
        },
        requiredselect: (value) => {
          if (value !== null) {
            return true;
          } else return "Required";
        },
        email: (value) => {
          if (value !== null) {
            if (value.length > 0) {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              if (pattern.test(value)) {
                this.error = false;
                return true;
              } else {
                this.error = true;
                return "Email invalid!";
              }
            } else {
              this.error = true;
              return "Camp obligatoriu*";
            }
          } else return true;
        },
        pass: (value) => {
          if (value !== null) {
            const pattern = /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/;
            if (value && pattern.test(value)) {
              this.error = false;
              return true;
            } else {
              this.error = true;
              return "Parola trebuie sa contina minim 8 caractere, cel putin o majuscula si o cel putin cifra!";
            }
          } else return true;
        },
        cpass: (value) => {
          if (value !== null) {
            if (String(value).length >= 8) {
              if (this.password === value) {
                return true;
              } else {
                return "Parolele trebuie sa semene!";
              }
            } else {
              this.error = true;
              return "Camp obligatoriu!";
            }
          } else return true;
        },
      },
    }
  },

  methods: {
      async logout() {
        await localStorage.clear();
        await this.$store.commit('setAppBarStatus', false);
        this.$router.push({name: 'Login'});
      },
      getYearMonths() {
        let dates=[]
        //   '01.2020','02.2020','03.2020','04.2020','05.2020','06.2020','07.2020','08.2020','09.2020','10.2020','11.2020','12.2020',
        for (let index = 1; index <= 12; index++) {
            let i = 0;
            if(index < 10)
                i = '0'+index;
            else
                i = index
            dates.push((i).toString()+'.'+this.currentYear);
        }
        return dates;
      },
      async getMonthDays(y, m) {
        this.header = ['Ora'];
        let d = new Date(`${y}-${m}`);
        let dinM = new Date(y,m,0);
        for (let i = d.getDate(); i <= dinM.getDate(); i++) {
            let cDate = new Date(`${y}-${m}-${i}`);
            this.header.push(`Z${i}(`+this.days[cDate.getDay()]+')');
        }
        this.header.push('Total');
    }

  }
};
