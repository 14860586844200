<template>
  <div class="text-center">
    <v-dialog
      width="62%"
      v-model="status"
    >
      <template v-slot:activator="{ on, attrs }">
        <div :class="align">
            <v-btn
            :class="btnClass"
            v-bind="attrs"
            v-on="on"
            >
            <slot name="button"></slot>
            </v-btn>
        </div>
      </template>
      <v-card>
        <v-container fluid style="padding:0;">
            <v-card-title class="pa-md-2">
                <v-row no-gutters>
                    <v-col>
                        <span class="pl-3 c15-20-dm-sans-medium"><b>Plata sold curent</b></span>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn icon @click.stop="hide">
                            <v-icon small>mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
             <v-simple-table v-if="bills.length != 0" style="border:1px solid rgba(0, 0, 0, 0.12);">
                <tbody>
                    <tr
                    v-for="item in bills"
                    :key="item.name"
                    >

                    <td class="pa-3">
                        <v-img
                            style="float:left;"
                            contain
                            align-center
                            width="18px"
                            height="18px"
                            :src="require('./../../assets/file.svg')"
                        />
                        <span class="ml-3 c16-20-s-dm-sans-medium">{{ item.TipFactura }} {{ item.SerieNumar }}</span>
                        <br>
                        <span class="ml-8 c14-20-dm-sans-subtext">{{ item.EnitateFactura }}</span>
                    </td>
                    <td class="pa-3"><span style="float:right;" class="pr-10 c22-20-s-44-dm-sans">{{ helper.formatPrice(item.Sold) }} {{helper.leiToRon(item.DenumireMoneda)}}</span></td>
                    </tr>
                </tbody>
             </v-simple-table>
             <v-divider style="margin:15px 0"></v-divider>
             <v-checkbox messages="Scapa de griji prin activarea platii recurente" v-model="recurring">
                 <template v-slot:label>
                     <span class="c16-20-s-dm-sans-medium" style="color:black;">Plata recurenta</span>
                 </template>
             </v-checkbox>
             <v-divider style="margin:15px 0"></v-divider>
            <!-- <v-row> -->
                <!-- <v-col> -->
                    <v-simple-table v-if="recurring && contracts.length != 0" style="border:1px solid rgba(0, 0, 0, 0.12); margin-top:15px">
                        <tbody>
                            <tr v-for="item in contracts" :key="item.IdContract">
                            <td width="1%">
                                <v-checkbox v-model="recurringContract" :value="item.IdContract"></v-checkbox>
                            <td v-if="item.IdTipContract == 6" style="padding-left:0px;">
                                <span class="c15-20-s-dm-sans">Contract Energie Electrica Nr. {{ item.NrContract }}</span>
                            </td>
                            <td v-else-if="item.IdTipContract == 40" style="padding-left:0px;">
                                <span class="c15-20-s-dm-sans">Contract Gaz Nr. {{ item.NrContract }}</span>
                            </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                <!-- </v-col> -->
            <!-- </v-row> -->
            <v-row v-if="recurring">
                <v-col><p>Optiuni plata recurenta</p></v-col>
            </v-row>
            <v-row v-if="recurring">
                <v-col cols="2">
                    <v-select :items="frecventa" v-model="selectedFrecventa" label="Frecventa (zile)"></v-select>
                </v-col>
                <v-col cols="2">
                    <v-select :items="durata" v-model="selectedDurata" label="Durata (luni)"></v-select>
                </v-col>
                <v-col cols="2">
                    <v-text-field
                    type="number"
                    v-model="selectedTotal"
                    label="Prag maxim (RON)"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-divider style="margin-bottom:15px" v-if="recurring"></v-divider>
            <div class="c13-14-dm-sans-subtext" style="margin-bottom:10px"><b>Puteti modifica suma platibila</b></div>
            <div style="border:2px solid #F2ECE6; width:20%; border-radius:5px" class="pa-2">
                <v-text-field label="Total de plata (RON)" v-model="newTotal" hide-details="true" type="number" class="c15-20-dm-sans-medium no-border-select"></v-text-field>
            </div>
             <v-checkbox v-model="tac">
                 <template v-slot:label>
                     <span class="c15-20-s-dm-sans" style="color:#171619;">Am citit si acceptat <router-link to="/termeni" style="color:#F02D4B !important">termenii si conditiile</router-link></span>
                 </template>
             </v-checkbox>
            <v-alert type="error" text v-if="errorMessage != ''">
            <span class="c15-20-s-dm-sans">{{errorMessage}}</span>
            </v-alert>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
                <v-btn class="pay-btn" :disabled="!tac" @click.stop="plateste">
                Plateste
                </v-btn>
                <PlatesteForm
                    :recurent="recurring"
                    :selectedFrecventa="selectedFrecventa"
                    :selectedDurata="selectedDurata"
                    :data="payment"
                    @click.stop="pay"
                    v-if="generatedPayDataStatus && !paymentPressed"
                />
                <v-btn class="close-text" text @click.stop="hide">
                Inchide
                </v-btn>
            <v-spacer></v-spacer>
                <v-img
                    alt="Vuetify Logo"
                    contain
                    :src="require('./../../assets/euplatesc_banner.png')"
                    transition="scale-transition"
                    width="79"
                />
        </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Http from "./../../common/js/Http";
//import validate from "./../../common/js/validate";
import Helper from "./../../common/js/Helper";
import qs from 'qs';
import PlatesteForm from "./../../views/PlatesteForm";
//import axios from 'axios';

export default {
  name: 'Home',
//  mixins: [validate],
  props: {
      user: Object,
      ids : {
          type: Array,
          default: null
      },
      align : {
          type: String,
          default: 'text-center'
      },
      btnClass : String,
      total : String,
      unformattedTotal : Number,
  },
  data() {
      return {
            generatedPayDataStatus: false,
            paymentPressed: false,
            tac: false,
            helper : Helper,
            status : false,
            recurring : false,
            recurringContract : [],
            bills : [],
            contracts : [],
            frecventa : [5, 10, 15, 20, 25, 30],
            durata : [],
            selectedFrecventa: 10,
            selectedDurata: 6,
            payment : null,
            dataFacturi : [],
            dataFactura : [],
            dataSold    : [],
            dataDataEmitere : [],
            factura     : [],
            plataRecurenta: [],
            contracte : [],
            platiRecurente : [],

            newTotal : this.unformattedTotal,
            selectedTotal : 0,
            errorMessage: '',

      }
  },
  components: {
      PlatesteForm
  },
  created() {
    for (let index = 1; index <= 36; index++) {
        this.durata.push(index);
    }
    this.contracte['IdTipContract'] = '';
  },
    watch: {
        selectedFrecventa() {
            this.errorMessage = '';
        },
        selectedDurata() {
            this.errorMessage = '';
        },
        selectedTotal() {
            this.errorMessage = '';
        },
        recurringContract() {
            this.errorMessage = '';
        },
        status(to) {
            this.errorMessage = '';
            if(to) {
                this.bills = [];
                this.getBills();
                //this.selectedTotal = this.unformattedTotal;
            }
        },
        recurring(to) {
            if(to) {
                this.getContracts();
                this.errorMessage = '';
            }
        },
        unformattedTotal(to) {
            this.newTotal = to;
        }
    },
  methods: {
    plateste: async function() {
        let http = new Http();
        this.bills.forEach(elem => {
            this.dataFacturi[elem.iDfg] = elem.SerieNumar;
            this.dataFactura[elem.iDfg] = elem.iDfg;
            this.dataSold[elem.iDfg] = elem.Sold;
            this.dataDataEmitere[elem.iDfg] = elem.DataEmitere;
            if(this.ids === null)
                this.factura[elem.iDfg] = 1;
        });

        if(this.ids !== null) {
            this.ids.forEach(elem => {
                this.factura[elem] = 1;
            });
        }
        let payData = {
            'data-facturi':this.dataFacturi,
            'data-factura':this.dataFactura,
            'data-sold':this.dataSold,
            'data-dataemitere':this.dataDataEmitere,
            'factura':this.factura, // these are the seleced bills change this if user selected specific bills
        };

        if(Object.keys(this.factura).length == 0) {
            this.errorMessage = 'Nici o factura n-a fost emisa!';
        }

        if(this.recurring) {
            if(isNaN(this.selectedFrecventa))
                this.errorMessage = 'Frecventa neselectata!';
            if(isNaN(this.selectedDurata))
                this.errorMessage = 'Durata neselectata!';
            if(isNaN(parseFloat(this.selectedTotal))) {
                this.errorMessage = 'Pragul maxim neselectat!';
            }
            payData['plata_recurenta']='on';
            payData['Contracte'] = this.contracte;
            payData['PlatiRecurente'] = [];
            payData['PlatiRecurente']['IduriContracte']=this.recurringContract;
            payData['PlatiRecurente']['Frecventa']=this.selectedFrecventa;
            payData['PlatiRecurente']['Durata']=this.selectedDurata;
            payData['PlatiRecurente']['PragMaxim']=this.selectedTotal;
        }

        payData['Total'] = this.newTotal;
        payData['submit'] = "Plateste";

        if(this.errorMessage == '') {
            await http.post(`/webapi/euplatesc/sendpayment`, qs.stringify(payData))
            .then((response) => {
                this.payment = response.data.data.data;
                this.generatedPayDataStatus = true;
            })
            .catch((error => {
                this.generatedPayDataStatus = false;
                this.errorMessage = error.response.data.data.error;
            }));
        }
        // this.status = false;
        // this.$emit('hidePlateste', false);
    },
    makePayment:function() {
        this.paymentPressed = true;
    },
    hide:function() {
        this.status = false;
        this.$emit('hidePlateste', false);
    },
    async getBills() {

        let http = new Http();
        let bills = await http.get(`/webapi/default/bills`);
        bills = await bills.data.data.bills;
        if(this.ids !== null) {
            let calculatedTotal = 0;
            bills.forEach(elem => {
                if(this.ids.includes(elem.iDfg)) {
                    this.bills.push(elem);
                    calculatedTotal += parseFloat(elem.Sold);
                }
            });
            this.newTotal =  calculatedTotal;
        } else {
            bills.forEach(elem => {
                if(elem.Sold != 0)
                    this.bills.push(elem);
            });
        }
    },

    async getContracts() {
        let http = new Http();
        this.contracts = await http.get(`/webapi/default/partnercontracts?partener=${this.user.partener.IdParteneri}`);
        this.contracts = await this.contracts.data.data.contracts.rawData;
    },
  }
}
</script>

<style scoped>

.close-text {
    color: #F02D4B;
    font-weight: bold;
    text-transform: none;
}
.x-header {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-15)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--base-typeface-color);
    font: normal normal normal 18px/20px DM Sans;
    letter-spacing: 0px;
    color: #171619;
    opacity: 0.91;
    background-color: transparent !important;
    border: 0 !important;
    padding: 0;
    margin: 0;
    box-shadow: none;
}
.pay-header {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-15)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--base-typeface-color);
    text-align: left;
    font: normal normal normal 15px/20px DM Sans;
    letter-spacing: 0px;
    color: #171619;
    opacity: 0.91;
}

.pay-bill {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--base-typeface-color);
    text-align: left;
    font: normal normal medium 16px/20px DM Sans;
    letter-spacing: 0px;
    color: #171619;
}

.pay-address {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--dark-grey);
    text-align: left;
    font: normal normal normal 14px/20px DM Sans;
    letter-spacing: 0px;
    color: #5D5D5C;
}

.pay-btn {
    color: white;
    text-transform:none !important;
    background: var(--green-primary) 0% 0% no-repeat padding-box !important;
    background: #2DB7A4 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    margin-left: 10px !important;
}

.pay-btn2 {
    border: 2px solid var(--green-primary);
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 2px solid #2DB7A4;
    border-radius: 5px;
    text-transform:none !important;
}

</style>
