<template>
    <div class="text-center">
    <v-dialog
      width="40%"
      v-model="modificaModal0"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn x-small text class="pink-red-btn" v-bind="attrs" v-on="on">
                <slot name="button"></slot>
            </v-btn>
        </template>
        <v-card>
            <v-container fluid style="padding:0;">
                <v-card-title class="pa-md-2">
                    <v-row no-gutters>
                        <v-col>
                            <span class="pl-3 c15-20-dm-sans-medium"><b>{{item.NumePunctConsum}} - {{item.PentruLuna}}</b></span>
                        </v-col>
                        <v-col class="text-right">
                            <v-btn icon @click="modificaModal0 = false;">
                                <v-icon small>mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row v-if="loading0 === true">
                        <v-col align="center">
                            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col>
                            <h4 v-if="item.EE == '1'">Autocitire energie electrica</h4>
                            <h4 v-if="item.GN == '1'">Autocitire gaze naturale</h4>
                        </v-col>
                    </v-row>
                    <div v-if="autocitire">
                    <v-card elevation="1" v-for="contor in autocitire.data.data.contorData" :key="contor.IdContor" class="pl-3 pr-3 mb-3">
                        <v-row v-if="contor.IdTipContor != '4' && contor.idTip != ''">
                            <v-col align-self="center" style="flex:0 1 46px">
                                <v-img
                                    v-if="item.EE == '1'"
                                    contain
                                    width="22px"
                                    height="25px"
                                    alt="Energie"
                                    :src="require('./../../../assets/Energy.svg')"
                                />
                                <v-img
                                    v-if="item.GN == '1'"
                                    contain
                                    width="22px"
                                    height="25px"
                                    alt="Gaz"
                                    :src="require('./../../../assets/Gas.svg')"
                                />
                            </v-col>
                            <v-col>
                                <pre class="main-text">Contor {{contor.SerieContor}}</pre>
                                <span v-if="selectedResource==0 && contor.PCPVIndexInitialEA != contor.PCPVIndexFinalEA" class="details-text">
                                    {{parseFloat(contor.PCPVIndexInitialEA).toFixed(2)}}
                                    <v-icon class="text-center" small>mdi-arrow-right-bold</v-icon>
                                    {{parseFloat(contor.PCPVIndexFinalEA).toFixed(2)}}
                                    ({{parseFloat(contor.PCPVIndexFinalEA - contor.PCPVIndexInitialEA).toFixed(2)}})
                                </span>
                                <span v-else-if="selectedResource==0" class="details-text">
                                    Index initial {{(contor.PCPVIndexFinalEA)?parseFloat(contor.PCPVIndexFinalEA).toFixed(2):0.00}}
                                </span>
                                <span v-if="selectedResource==1 && contor.PCPVGN_IndexInitial != contor.PCPVGN_IndexFinal" class="details-text">
                                    {{parseFloat(contor.PCPVGN_IndexInitial).toFixed(2)}}
                                    <v-icon class="text-center" small>mdi-arrow-right-bold</v-icon>
                                    {{parseFloat(contor.PCPVGN_IndexFinal).toFixed(2)}}
                                    ({{parseFloat(contor.PCPVGN_IndexFinal - contor.PCPVGN_IndexInitial).toFixed(2)}})
                                </span>
                                <span v-else-if="selectedResource==1" class="details-text">
                                    Index initial {{(contor.PCPVGN_IndexInitial)?parseFloat(contor.PCPVGN_IndexInitial).toFixed(2):0.00}}
                                </span>
                            </v-col>
                            <v-col align-self="center" align="right" v-if="contor.idTip != '' && autocitire.data.data.messageData.AutocitireActiva != '0'
                            && autocitire.data.data.messageData.InPerioadaAutocitire && autocitire.data.data.cont != '0'">
                                <v-btn text class="no-text-transform pink-btn" @click="modificaModal1 = !modificaModal1; sendReads(contor)" >
                                    <span class="c14-19-dm-sans-medium" v-if="contor.Autocitit == 1">Modifica</span>
                                    <span v-else>Trimite autocitire</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                    </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn class="ml-3 close-text" text @click="modificaModal0 = false">
                    Inchide
                    </v-btn>
                </v-card-actions>
            </v-container>
        </v-card>
    </v-dialog>
    <v-dialog
        v-if="selectedContor"
        width="40%"
        v-model="modificaModal1"
    >
        <v-card>
            <v-container fluid style="padding:0;">
                <v-card-title class="pa-md-2">
                    <v-row no-gutters>
                        <v-col>
                            <span class="pl-3 c15-20-dm-sans-medium"><b>Autocitire {{item.PentruLuna}} - Contor {{selectedContor.SerieContor}}</b></span>
                        </v-col>
                        <v-col class="text-right">
                            <v-btn icon @click="modificaModal1 = false;">
                                <v-icon small>mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text v-if="loading1 === true">
                    <v-row>
                        <v-col align="center">
                            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text v-else>
                    <v-row>
                        <v-col>
                            <v-card class="pa-md-3">
                                <pre class="if" v-if="selectedResource==0">Index final (kWh)<span>*</span></pre>
                                <pre class="if" v-if="selectedResource==1">Index final (mc)<span>*</span></pre>
                                    <v-text-field
                                        filled
                                        class="no-border-select"
                                        type="number"
                                        v-model="selectedFinal"
                                    ></v-text-field>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card class="pa-md-3">
                                <pre v-if="selectedResource==0">Index initial (kWh)</pre>
                                <pre v-if="selectedResource==1">Index initial (mc)</pre>
                                <pre v-if="selectedResource==0 && selectedContor.PCPVIndexInitialEA != null">{{parseFloat(selectedContor.PCPVIndexInitialEA).toFixed(2)}}</pre>
                                <pre v-if="selectedResource==1 && selectedContor.PCPVGN_IndexInitial != null">{{parseFloat(selectedContor.PCPVGN_IndexInitial).toFixed(2)}}</pre>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card class="pa-md-3">
                                <pre v-if="selectedResource==0">Consum total (kWh)</pre>
                                <pre v-if="selectedResource==1">Consum total (mc)</pre>
                                <pre>{{ct}}</pre>
                                <!-- <pre v-if="selectedResource==0 && selectedContor.PCPVIndexInitialEA != null">{{parseFloat(selectedContor.PCPVIndexFinalEA - selectedContor.PCPVIndexInitialEA).toFixed(2)}}</pre>
                                <pre v-if="selectedResource==1 && selectedContor.PCPVGN_IndexInitial != null">{{parseFloat(selectedContor.PCPVGN_IndexFinal - selectedContor.PCPVGN_IndexInitial).toFixed(2) * parseFloat(selectedContor.PCS).toFixed(3)}}</pre> -->
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card class="pa-md-3">
                                <pre>Consum total MWh</pre>
                                <pre>{{ctm}}</pre>
                                <!-- <pre v-if="selectedContor.PCPVGN_IndexInitialEA != null">x</pre>
                                <pre v-if="selectedResource==0 && selectedContor.PCPVIndexInitialEA != null">{{((parseFloat(selectedContor.PCPVIndexFinalEA - selectedContor.PCPVIndexInitialEA).toFixed(2))/1000).toFixed(2)}}</pre>
                                <pre v-if="selectedResource==1 && selectedContor.PCPVGN_IndexInitial != null">{{((parseFloat(selectedContor.PCPVGN_IndexFinal - selectedContor.PCPVGN_IndexInitial).toFixed(2)) * parseFloat(selectedContor.PCS).toFixed(3)/1000).toFixed(2)}}</pre> -->
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row>
                        <v-col><h2 class="c16-20-dm-sans-medium" style="color:black;">Detalii generale</h2></v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <pre v-if="selectedResource==0" class="c16-20-dm-sans-medium" style="color:black;">{{selectedContor.PCPVDataInceput}} </pre>
                            <pre v-if="selectedResource==1" class="c16-20-dm-sans-medium" style="color:black;">{{selectedContor.PCPVGN_DataInceput}} </pre>
                            <pre class="c13-14-dm-sans">Data inceput</pre>
                        </v-col>
                        <v-col>
                            <pre v-if="selectedResource==0" class="c16-20-dm-sans-medium" style="color:black;">{{selectedContor.PCPVDataFinal}} </pre>
                            <pre v-if="selectedResource==1" class="c16-20-dm-sans-medium" style="color:black;">{{selectedContor.PCPVGN_DataFinal}} </pre>
                            <pre class="c13-14-dm-sans">Data sfarsit</pre>
                        </v-col>
                        <v-col>
                            <pre class="c16-20-dm-sans-medium" style="color:black;">{{selectedContor.DescriereTipContor}} </pre>
                            <pre class="c13-14-dm-sans">Tip contor</pre>
                        </v-col>
                        <v-col v-if="item.EE == '1'">
                            <pre v-if="selectedResource==0" class="c16-20-dm-sans-medium" style="color:black;">{{selectedContor.ConstantaAparat}} </pre>
                            <pre v-if="selectedResource==1" class="c16-20-dm-sans-medium" style="color:black;">1</pre>
                            <pre class="c13-14-dm-sans">Constanta</pre>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-alert type="success" text v-if="status == 1 && message"><span class="c15-20-s-dm-sans">{{message}}</span></v-alert>
                            <v-alert type="error" text v-if="status == 0 && message"><span class="c15-20-s-dm-sans">{{message}}</span></v-alert>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn text class="pink-btn" @click="modificaModal1 = false">
                    Inapoi
                    </v-btn>
                    <v-btn v-if="loading1 === false" class="no-text-transform green-white-btn ml-md-5" dark @click="saveReadBtn()">
                    Salveaza
                    </v-btn>
                    <v-btn text class="ml-3 close-text" @click="modificaModal1 = false; modificaModal0 = false;">
                    Inchide
                    </v-btn>
                </v-card-actions>
            </v-container>
        </v-card>
    </v-dialog>
    <ConfirmDialog ref="confirm"></ConfirmDialog>
    </div>
</template>

<script>
import Http from "../../../common/js/Http.js";
import Helper from "../../../common/js/Helper.js";
import globals from "../../../common/js/globals.js";
import ConfirmDialog from "../../../components/modals/ConfirmDialog";

export default {
  name: 'Index',
  mixins: [globals],
  props: {
      item : Object
  },
  data() {
      return {
            Helper,
            loading0: true,
            loading1: true,
            modificaModal0 : false,
            modificaModal1 : false,
            trimiteModal   : false,
            autocitire     : null,
            selectedContor : null,
            selectedFinal  : 0,
            ct : 0,
            ctm : 0,
            editablePCAC   : false,
            selectedResource : null, // 0 == elec 1 == gas

            status : null,
            message : ''
      }
  },
  components: {
    ConfirmDialog
  },
  created() {
  },
  watch: {
      selectedFinal() {
          if(this.selectedResource == 0 && this.selectedContor.PCPVIndexInitialEA != null) {
              //EE
            this.ct = parseFloat((this.selectedFinal - this.selectedContor.PCPVIndexInitialEA) * this.selectedContor.ConstantaAparat).toFixed(2);
            this.ctm = parseFloat(this.ct/1000).toFixed(3);
          } else if(this.selectedResource == 1 && this.selectedContor.PCPVGN_IndexInitial){
              // GN
            this.ct = parseFloat(this.selectedFinal - this.selectedContor.PCPVGN_IndexInitial).toFixed(3)
            let pcs = 1;
            if(this.selectedContor.PCS) {
                pcs = this.selectedContor.PCS;
            }
            this.ctm = parseFloat(this.ct * parseFloat(pcs)/1000).toFixed(5);
          }
        // console.log(this.selectedResource);
        // console.log(this.selectedContor);
      },
      async modificaModal0(status) {
        // console.log(this.item);
        if(status) {
            let year = parseFloat(this.item.IdTipuriLuniAni.substring(0,4))
            let month = parseFloat(this.item.IdTipuriLuniAni.substr(-2))
            let http = new Http();
            let autocitire = null;
            if(this.item.EE == '1') {
                this.selectedResource = 0;
                autocitire = await http.get(`/webapi/autocitire/getPreviousReadsEE?pc=${this.item.IdPunctConsum}&month=${month}&year=${year}`);
            }
            else if(this.item.GN == '1') {
                this.selectedResource = 1;
                autocitire = await http.get(`/webapi/autocitire/getPreviousReadsGN?pc=${this.item.IdPunctConsum}&month=${month}&year=${year}`);
            }
            this.autocitire = autocitire;

            let idTip = '';
            autocitire.data.data.contorData.forEach(contor => {
                // ENERGY
                if(contor.iDpcpv) {
                    if(
                        contor.IdTipContor == '1' &&
                        Helper.empty(contor.iDpcpv) &&
                        this.autocitire.data.data.messageData.AutocitireActiva == '1' &&
                        this.autocitire.data.data.messageData.InPerioadaAutocitire == '1'
                    ) {
                        idTip = 1;
                    } else if(
                        contor.IdTipContor == '1' &&
                        !Helper.empty(contor.iDpcpv) &&
                        contor.Autocitit == '1' &&
                        this.autocitire.data.data.messageData.AutocitireActiva == '1' &&
                        this.autocitire.data.data.messageData.InPerioadaAutocitire == '1' &&
                        this.autocitire.data.data.cont == '1'
                    ) {
                        idTip = 2;
                    } else if(
                        contor.IdTipContor == '1' &&
                        !Helper.empty(contor.iDpcpv) &&
                        contor.Autocitit == '1' &&
                        this.autocitire.data.data.messageData.AutocitireActiva == '1' &&
                        this.autocitire.data.data.messageData.InPerioadaAutocitire == '1' &&
                        this.autocitire.data.data.cont == '0'
                    ) {
                        idTip = 1;
                    }
                }
                // GAS
                else {
                    if(
                        contor.IdTipContor == '1' &&
                        Helper.empty(contor.iDpcpvgn) &&
                        this.autocitire.data.data.messageData.AutocitireActiva == '1' &&
                        this.autocitire.data.data.messageData.InPerioadaAutocitire == '1'
                    ) {
                        idTip = 1;
                    } else if(
                        contor.IdTipContor == '1' &&
                        !Helper.empty(contor.iDpcpvgn) &&
                        contor.Autocitit == '1' &&
                        this.autocitire.data.data.messageData.AutocitireActiva == '1' &&
                        this.autocitire.data.data.messageData.InPerioadaAutocitire == '1' &&
                        this.autocitire.data.data.cont == '1'
                    ) {
                        idTip = 2;
                    }
                }
                contor['idTip'] = idTip;
                // console.log(contor);
            });
            let currentDate = new Date(this.currentDate);
            let intervalStart = new Date(this.autocitire.data.data.messageData.DataInceputAutocitire);
            let intervalFinal = new Date(this.autocitire.data.data.messageData.DataFinalAutocitire);
            if(currentDate.getTime() <= intervalFinal.getTime() && currentDate.getTime() >= intervalStart.getTime())
                this.editablePCAC = true;
            this.loading0 = false;
        } else {
            this.$emit('edited', true);
            this.status = null;
            this.message = '';
            this.loading0 = true;
        }
      }
  },
  methods: {
    hide:function() {
        this.dialog1 = false;
        this.$emit('hidePlateste', false);
    },

    async saveReadBtn() {
        let modul = null;
        if (this.item.EE == '1') modul = 'EE';
        if (this.item.GN == '1') modul = 'GN';
        if (modul) {
            let http = new Http();
            let result = await http.get(`/webapi/autocitire/validareCantitate?modul=${modul}&idContor=${this.selectedContor.IdContor}&indexFinal=${this.selectedFinal}`);
            if (result.data.data.valid == '0') {
                if (await this.$refs.confirm.open('Atentie', result.data.data.mesaj, {confirmBtn: 'Da'})) {
                    this.saveRead();
                }
            } else {
                this.saveRead();
            }
        } else {
            this.status = 0;
            this.message = 'Tipul de contor nu este valid!';
        }
    },

    async saveRead() {
        let idTip = '';
        // ENERGY
        if(this.selectedResource == 0) {
            if(
                this.selectedContor.IdTipContor == '1' &&
                Helper.empty(this.selectedContor.iDpcpv) &&
                this.autocitire.data.data.messageData.AutocitireActiva == '1' &&
                this.autocitire.data.data.messageData.InPerioadaAutocitire == '1'
            ) {
                idTip = 1;
            } else if(
                this.selectedContor.IdTipContor == '1' &&
                !Helper.empty(this.selectedContor.iDpcpv) &&
                this.selectedContor.Autocitit == '1' &&
                this.autocitire.data.data.messageData.AutocitireActiva == '1' &&
                this.autocitire.data.data.messageData.InPerioadaAutocitire == '1' &&
                this.autocitire.data.data.cont == '1'
            ) {
                idTip = 2;
            } else if(
                this.selectedContor.IdTipContor == '1' &&
                !Helper.empty(this.selectedContor.iDpcpv) &&
                this.selectedContor.Autocitit == '1' &&
                this.autocitire.data.data.messageData.AutocitireActiva == '1' &&
                this.autocitire.data.data.messageData.InPerioadaAutocitire == '1' &&
                this.autocitire.data.data.cont == '0'
            ) {
                idTip = 1;
            }
        }
        // GAS
        else if(this.selectedResource == 1) {
            if(
                this.selectedContor.IdTipContor == '1' &&
                Helper.empty(this.selectedContor.iDpcpvgn) &&
                this.autocitire.data.data.messageData.AutocitireActiva == '1' &&
                this.autocitire.data.data.messageData.InPerioadaAutocitire == '1'
            ) {
                idTip = 1;
            } else if(
                this.selectedContor.IdTipContor == '1' &&
                !Helper.empty(this.selectedContor.iDpcpvgn) &&
                this.selectedContor.Autocitit == '1' &&
                this.autocitire.data.data.messageData.AutocitireActiva == '1' &&
                this.autocitire.data.data.messageData.InPerioadaAutocitire == '1' &&
                this.autocitire.data.data.cont == '1'
            ) {
                idTip = 2;
            }
        }
        if(idTip == '') {
            this.status = 0;
            this.message = `Nu se pot adauga autocitiri la contorul ${this.selectedContor.SerieContor}`;
        } else {

            let http = new Http();
            let formData = new FormData();
            // use formaData for legacy Yii1 backend to post data
            formData.append('IndexSfarsitPerioada', parseFloat(this.selectedFinal));
            let postRead = null;
            if(this.selectedContor.iDpcc) {
                postRead = await http.post(`/webapi/autocitire/postreadee?idTip=${idTip}&iDpcc=${this.selectedContor.iDpcc}&iDpcc_iDpcpv=${this.selectedContor.iDpcc_iDpcpv}&idTipuriLuniAni=${this.autocitire.data.data.messageData.IdTipuriLuniAniAutocitire}`,
                    formData
                );
            } else if(this.selectedContor.iDpccgn){
                postRead = await http.post(`/webapi/autocitire/postreadgn?idTip=${idTip}&iDpccgn=${this.selectedContor.iDpccgn}&iDpccgn_iDpcpvgn=${this.selectedContor.iDpccgn_iDpcpvgn}&idTipuriLuniAni=${this.autocitire.data.data.messageData.IdTipuriLuniAniAutocitire}`,
                    formData
                );
            }

            this.status = postRead.data.status;
            this.message = postRead.data.message;
            if (typeof this.message === 'string') {
                this.message = this.message.replace(/.*\[SQL Server\]/g, '');
            }

            if(this.status == 1) {
                this.modificaModal1 = false;
                this.modificaModal0 = false;
            }

        }
    },

    async sendReads(contor) {
        let http = new Http();
        this.status = null;
        this.message = '';
        this.selectedContor = contor;
        if(this.selectedResource == 0) {
            let postRead = await http.get(`/webapi/autocitire/postreadee?idTip=${this.selectedContor.idTip}&iDpcc=${this.selectedContor.iDpcc}&iDpcc_iDpcpv=${this.selectedContor.iDpcc_iDpcpv}&idTipuriLuniAni=${this.autocitire.data.data.messageData.IdTipuriLuniAniAutocitire}`);
            this.loading1 = false;
            this.selectedContor.ConstantaAparat = postRead.data.data.results.ConstantaAparat;
            this.selectedContor.PCPVDataInceput = postRead.data.data.results.InceputPerioada;
            this.selectedContor.PCPVDataFinal = postRead.data.data.results.SfarsitPerioada;
            this.selectedContor.PCPVIndexInitialEA = postRead.data.data.results.IndexInceputPerioadaEA;
            /* this.selectedContor.PCPVIndexFinalEA = postRead.data.data.results.IndexSfarsitPerioadaEA;
            this.selectedFinal = parseFloat(this.selectedContor.PCPVIndexFinalEA).toFixed(2); */
            this.selectedFinal = parseFloat(postRead.data.data.results.IndexSfarsitPerioadaEA).toFixed(2);
        } else {
            let postRead = await http.get(`/webapi/autocitire/postreadgn?idTip=${this.selectedContor.idTip}&iDpccgn=${this.selectedContor.iDpccgn}&iDpccgn_iDpcpvgn=${this.selectedContor.iDpccgn_iDpcpvgn}&idTipuriLuniAni=${this.autocitire.data.data.messageData.IdTipuriLuniAniAutocitire}`);
            this.loading1 = false;
            this.selectedContor.PCPVGN_DataInceput = postRead.data.data.results.InceputPerioada;
            this.selectedContor.PCPVGN_DataFinal = postRead.data.data.results.SfarsitPerioada;
            this.selectedContor.PCPVGN_IndexInitial = postRead.data.data.results.IndexInceputPerioada;
            /* this.selectedContor.PCPVGN_IndexFinal = postRead.data.data.results.IndexSfarsitPerioada;
            this.selectedFinal = parseFloat(this.selectedContor.PCPVGN_IndexFinal).toFixed(2); */
            this.selectedFinal = parseFloat(postRead.data.data.results.IndexSfarsitPerioada).toFixed(2);
        }
    },
  }
}
</script>

<style scoped>
.close-text {
  color: #F02D4B;
  font-weight: bold;
  text-transform: none;
}

.pink-red-btn {
    font: normal normal normal 15px/20px DM Sans;
    letter-spacing: 0.3px;
    color: #F02D4B;
}

.text-red-btn {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-15)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0-3);
    color: var(--nova-red);
    font: normal normal normal 15px/20px DM Sans;
    color: #F02D4B;
}

.details-text {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-13)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--dark-grey);
    text-align: left;
    font: normal normal normal 13px/14px DM Sans;
    letter-spacing: 0px;
    color: #5D5D5C;
}

.green-white-btn {
    background: var(--green-primary) 0% 0% no-repeat padding-box !important ;
    background: #2DB7A4 0% 0% no-repeat padding-box !important;
    border-radius: 5px;
}

.main-tf {
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nova-red);
    letter-spacing: 0px;
    color: #F02D4B;
}

.if {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal normal 12px/14px DM Sans;
    letter-spacing: 0px;
}

.if > span {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal normal 12px/14px DM Sans;
    letter-spacing: 0px;
    color: #CE203B;
}

.arrow {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-font-awesome-5-free);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--dark-grey);
    text-align: left;
    font: normal normal normal 14px/20px Font Awesome 5 Free;
    letter-spacing: 0px;
    color: #5D5D5C;
}
.pink-btn {
    background: #FF5F7824 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    opacity: 1;
    color: #F02D4B;
    letter-spacing: normal;
    font-weight: bold;
    text-transform: none;
}
</style>
