<template>
      <v-container fluid style="background-color:#FFFCF9">
        <v-row>
          <v-col>
            <p class="c22-20-s-44-dm-sans">Prezentare</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col style="background-color:white; border-radius:15px;">
            <apexchart height="350" :options="facturiLunareOptions" :series="facturiLunare"></apexchart>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col>
            <p class="c22-20-s-44-dm-sans">Energie electrica</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col style="background-color:white; border-radius:15px;">
            <apexchart height="350" :options="consumMediuZilnicEE" :series="consumMZEE"></apexchart>
          </v-col>
          <v-col cols="1"></v-col>
          <v-col style="background-color:white; border-radius:15px;">
            <apexchart height="350" :options="costMediuZilnicEE" :series="costMZEE"></apexchart>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-row class="mt-8">
          <v-col>
            <p class="c22-20-s-44-dm-sans">Gaze naturale</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col style="background-color:white; border-radius:15px;">
            <apexchart height="350" :options="consumMediuZilnicGN" :series="consumMZGN"></apexchart>
          </v-col>
          <v-col cols="1"></v-col>
          <v-col style="background-color:white; border-radius:15px;">
            <apexchart height="350" :options="costMediuZilnicGN" :series="costMZGN"></apexchart>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col>
            <p class="c22-20-s-44-dm-sans">Curba de consum</p>
          </v-col>
        </v-row>
        <v-row style="background-color:white; border-radius:15px;">
          <v-col>
            <p class="c16-20-dm-sans-medium">Detalii</p>
          </v-col>
        </v-row>
        <v-row style="background-color:white; border-radius:15px;">
          <v-col cols="2">
            <!-- <v-select dense filled :items="fromInterval" v-model="selectedFromInterval" label="De la luna"></v-select> -->
            <v-menu v-model="selectedFromMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="selectedFromInterval" label="De la luna" readonly v-bind="attrs" v-on="on" dense filled class="no-border-select"></v-text-field>
              </template>
              <v-date-picker :show-current="true" :locale="locale" type="month" color="red lighten-1" v-model="selectedFromIntervalDP" @input="selectedFromMenu = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
            <!-- <v-select dense filled :items="toInterval" v-model="selectedToInterval" label="Pana la luna"></v-select> -->
            <v-menu v-model="selectedToMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="selectedToInterval" label="Pana la luna" readonly v-bind="attrs" v-on="on" dense filled class="no-border-select"></v-text-field>
              </template>
              <v-date-picker :show-current="true" :locale="locale" type="month" color="red lighten-1" v-model="selectedToIntervalDP" @input="selectedToMenu = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
            <v-select dense filled :items="itemsAfisare" v-model="selectedAfisare"
            item-value="value"
            item-text="text"
            label="Optiune afisare"
            class="no-border-select"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row v-if="selectedAfisare == 3" style="background-color:white; border-radius:15px;" no-gutters>
          <v-col>
            <v-radio-group v-model="selectedDay" row>
              <v-radio
                v-for="n in days"
                :key="n.value"
                :label="`${n.text}`"
                :value="n.value"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row style="background-color:white; border-radius:15px;">
          <v-col>
            <apexchart height="350" :options="curbaOptions" :series="curbaConsum"></apexchart>
          </v-col>
        </v-row>
      </v-container>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import Http from "../../common/js/Http.js";
import globals from "../../common/js/globals.js";
import validate from "../../common/js/validate.js";
import Helper from "../../common/js/Helper";

export default {
    name: 'RaportDetaliat',
    mixins: [validate, globals],
    components: {
        apexchart: VueApexCharts,
    },
    props:['pcId'],
    data() {
      return {
        months: [
          'Ianuarie',
          'Februarie',
          'Martie',
          'Aprilie',
          'Mai',
          'Iunie',
          'Iulie',
          'August',
          'Septembrie',
          'Octombrie',
          'Noiembrie',
          'Decembrie'
        ],
        dates:[],
        consumuri:null,
        // fromInterval:[],
        // toInterval:[],
        selectedFromMenu: false,
        selectedFromInterval:'',
        selectedFromIntervalDP:'',
        selectedToMenu: false,
        selectedToInterval:'',
        selectedToIntervalDP:'',
        itemsAfisare:[
            {text: 'WST', value: '4'},
            {text: 'Intreaga luna', value: '1'},
            {text: 'Zilele weekend', value: '2'},
            {text: 'Zilele saptamanii', value: '3'},
          ],
        selectedAfisare:'',
        facturiLunare:[],
        consumMZEE:[],
        costMZEE:[],
        consumMZGN:[],
        costMZGN:[],
        curbaConsum:[],
        selectedDay:'',
        days:[
            {text: 'Luni', value: '1'},
            {text: 'Marti', value: '2'},
            {text: 'Miercuri', value: '3'},
            {text: 'Joi', value: '4'},
            {text: 'Vineri', value: '5'},
            {text: 'Sambata', value: '6'},
            {text: 'Duminica', value: '7'},
          ],
        consumuriEE:[],
        consumuriGN:[],
        helper: Helper,
        globals,
        facturiLunareOptions: {
            colors: ['#F02D4B', '#2DB7A4'],
            legend: {
              show: true
            },
            chart: {
                width: '100%',
                height: 100,
                type: 'line',
                id: 'vc',

                toolbar: {
                    show: false
                },
                dropShadow: {
                    enabled: true,
                    top: 0,
                    left: 0,
                    blur: 0,
                    opacity: 1,
                    color: 'black'
                },
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '70%',
                //distributed: true
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            markers: {
                size: 6
            },
            title: {
                text: 'Sume facturate lunar 2020 comparat cu 2019',
                align: 'left',
            },
            xaxis: {
                categories: ['Ian','Feb','Mar','Apr','Mai','Iun','Iul','Aug','Sep','Oct','Nov','Dec']
            },
            yaxis: {
                tickAmount: 4
            },
        },

        consumMediuZilnicEE: {
            colors: ['#4B5AD033', '#4B5AD0'],
            legend: {
              show: true
            },
            chart: {
                width: '100%',
                height: 100,
                type: 'bar',
                id: 'consumMZEE',

                toolbar: {
                    show: false
                },
                dropShadow: {
                    enabled: true,
                    top: 0,
                    left: 0,
                    blur: 0,
                    opacity: 1,
                    color: 'black'
                },
            },
            dataLabels: {
              enabled: false
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '70%',
                //distributed: true
              }
            },
            stroke: {
              curve: 'smooth'
            },
            markers: {
                size: 6
            },
            title: {
                text: 'Consum mediu zilnic pe luna aferent anului 2020 comparat cu 2019',
                align: 'left',
            },
            xaxis: {
                categories: ['Ian','Feb','Mar','Apr','Mai','Iun','Iul','Aug','Sep','Oct','Nov','Dec']
            },
            yaxis: {
                tickAmount: 4
            }
        },

        costMediuZilnicEE: {
            colors: ['#2DB7A433 ', '#2DB7A4'],
            legend: {
              show: true
            },
            chart: {
                width: '100%',
                height: 100,
                type: 'bar',
                id: 'consumMZEE',

                toolbar: {
                    show: false
                },
                dropShadow: {
                    enabled: true,
                    top: 0,
                    left: 0,
                    blur: 0,
                    opacity: 1,
                    color: 'black'
                },
            },
            dataLabels: {
              enabled: false
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '70%',
                //distributed: true
              }
            },
            stroke: {
              curve: 'smooth'
            },
            markers: {
                size: 6
            },
            title: {
                text: 'Cost mediu zilnic pe luna aferent anului 2020 comparat cu 2019',
                align: 'left',
            },
            xaxis: {
                categories: ['Ian','Feb','Mar','Apr','Mai','Iun','Iul','Aug','Sep','Oct','Nov','Dec']
            },
            yaxis: {
                tickAmount: 4
            }
        },

        consumMediuZilnicGN: {
            colors: ['#FDECD2', '#F4A21F'],
            legend: {
              show: true
            },
            chart: {
                width: '100%',
                height: 100,
                type: 'bar',
                id: 'consumMZEE',

                toolbar: {
                    show: false
                },
                dropShadow: {
                    enabled: true,
                    top: 0,
                    left: 0,
                    blur: 0,
                    opacity: 1,
                    color: 'black'
                },
            },
            dataLabels: {
              enabled: false
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '70%',
                //distributed: true
              }
            },
            stroke: {
              curve: 'smooth'
            },
            markers: {
                size: 6
            },
            title: {
                text: 'Consum mediu zilnic pe luna aferent anului 2020 comparat cu 2019',
                align: 'left',
            },
            xaxis: {
                categories: ['Ian','Feb','Mar','Apr','Mai','Iun','Iul','Aug','Sep','Oct','Nov','Dec']
            },
            yaxis: {
                tickAmount: 4
            }
        },

        costMediuZilnicGN: {
            colors: ['#2DB7A433 ', '#2DB7A4'],
            legend: {
              show: true
            },
            chart: {
                width: '100%',
                height: 100,
                type: 'bar',
                id: 'consumMZGN',

                toolbar: {
                    show: false
                },
                dropShadow: {
                    enabled: true,
                    top: 0,
                    left: 0,
                    blur: 0,
                    opacity: 1,
                    color: 'black'
                },
            },
            dataLabels: {
              enabled: false
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '70%',
                //distributed: true
              }
            },
            stroke: {
              curve: 'smooth'
            },
            markers: {
                size: 6
            },
            title: {
                text: 'Cost mediu zilnic pe luna aferent anului 2020 comparat cu 2019',
                align: 'left',
            },
            xaxis: {
                categories: ['Ian','Feb','Mar','Apr','Mai','Iun','Iul','Aug','Sep','Oct','Nov','Dec']
            },
            yaxis: {
                tickAmount: 4
            }
        },

      curbaOptions: {
        tooltip: {
            enabled: false,
        },

        colors: ["#FF5F78"],
        chart: {
          width: "100%",
          height: 100,
          type: "area",
          id: "vc",
          toolbar: {
            show: false,
          },

        },

        fill: {
//         colors: 'white',
            type: 'gradient',
            gradient: {
                opacityFrom: 1.0,
                opacityTo: 0.0,
                stops: [0, 90, 100]
            }
        },
        dataLabels: {
            enabled:false,
        },
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 6,
        },
        title: {
          text: "",
          align: "left",
        },
        xaxis: {
          categories: [
          ],
        },
        yaxis: {
          tickAmount: 3,
        },
      },

      }
    },
    methods: {

        async getConsumOrar() {
          let http = new Http();
          this.consumuri = await http.get(`/webapi/rapoarte/curbaconsum?
          partener=${this.user.partener.IdParteneri}&
          from=${this.selectedFromInterval}&
          to=${this.selectedToInterval}&
          day=${this.selectedDay}&
          tip=${this.selectedAfisare}`);

          let data = this.consumuri.data.split(', ').slice(0,-1);
          // let data = this.consumuri.data.split(', ').slice(0,24);
          let apexData = [
            {
              // name: Object.keys(data),
              name:'Consum',
              data: data.map(elem => parseFloat(elem))
            }
          ];
          this.curbaConsum = apexData;
       },

      async getFacturaMedieLunara(pc) {
        let http = new Http();
        let facturi = await http.get(`/webapi/rapoarte/facturamedia?pc=${pc}&ani=2`);
        this.facturi = await facturi.data;

        let currentYearData = {
          name: this.currentYear,
          data: []
        };
        let lastYearData = {
          name: this.currentYear-1,
          data: []
        };
        let currentYearMonth = '';
        let lastYearMonth = '';

        let currentYearMonths = [];
        let lastYearMonths = [];

        this.facturi.forEach(element => {
          if(element.An == this.currentYear) {
            if(element.LunaNume == currentYearMonth) {
              currentYearData.data[currentYearData.data.length - 1] += parseFloat(parseFloat(element.ValoareFacturata));
              currentYearData.data[currentYearData.data.length - 1] = currentYearData.data[currentYearData.data.length - 1].toFixed(2);
            }
            else {
              currentYearData.data.push(parseFloat(parseFloat(element.ValoareFacturata).toFixed(2)));
            }
            currentYearMonths.push(element.LunaNume);
          }
          currentYearMonth = element.LunaNume;

          if(element.An == (this.currentYear-1)) {
            if(element.LunaNume == lastYearMonth) {
              lastYearData.data[lastYearData.data.length - 1] += parseFloat(parseFloat(element.ValoareFacturata));
              lastYearData.data[lastYearData.data.length - 1] = lastYearData.data[lastYearData.data.length - 1].toFixed(2);
            }
            else {
              lastYearData.data.push(parseFloat(parseFloat(element.ValoareFacturata).toFixed(2)));
            }
            lastYearMonths.push(element.LunaNume);
          }
          lastYearMonth = element.LunaNume;
        });
        let newCurrentYearData = {
          name: this.currentYear,
          data: []
        };
        let newLastYearData = {
          name: this.currentYear-1,
          data: []
        };
        let currentYearindex = 0;
        let lastYearindex = 0;
        this.months.forEach(elem => {
          // 0 = currentYear
          // 1 = lastYear
          if(!currentYearMonths.includes(elem)) {
            newCurrentYearData.data.push(0.00)
          } else {
            newCurrentYearData.data.push(currentYearData.data[currentYearindex]);
            currentYearindex++;
          }
          if(!lastYearMonths.includes(elem)) {
            newLastYearData.data.push(0.00)
          } else {
            newLastYearData.data.push(lastYearData.data[lastYearindex]);
            lastYearindex++;
          }
        });

        this.facturiLunare = [newCurrentYearData, newLastYearData];
      },
      async getLunarEE(pc) {
        let http = new Http();
        this.consumuriEE = await http.get(`/webapi/rapoarte/EELunar?pc=${pc}`);
        this.consumuriEE = await this.consumuriEE.data;
        let apexData = [
          {
            name: Object.keys(this.consumuriEE)[0],
            data: Object.values(this.consumuriEE[Object.keys(this.consumuriEE)[0]]).map(elem => elem)
          },
          {
            name: Object.keys(this.consumuriEE)[1],
            data: Object.values(this.consumuriEE[Object.keys(this.consumuriEE)[1]]).map(elem => elem)
          },
        ];
        this.consumMZEE = apexData;
      },

      async getLunarGN(pc) {
        let http = new Http();
        this.consumuriGN = await http.get(`/webapi/rapoarte/GNLunar?pc=${pc}`);
        this.consumuriGN = await this.consumuriGN.data;
        let apexData = [
          {
            name: Object.keys(this.consumuriGN)[0],
            data: Object.values(this.consumuriGN[Object.keys(this.consumuriGN)[0]]).map(elem => elem)
          },
          {
            name: Object.keys(this.consumuriEE)[1],
            data: Object.values(this.consumuriGN[Object.keys(this.consumuriGN)[1]]).map(elem => elem)
          },
        ];
        this.consumMZGN = apexData
      },
      async calcSumeFacturate() {
        this.costMZEE = [
          {
            name: this.currentYear-1,
            data: []
          },
          {
            name: this.currentYear,
            data: []
          }
        ];

        this.costMZGN = [
          {
            name: this.currentYear-1,
            data: []
          },
          {
            name: this.currentYear,
            data: []
          }
        ];

          if(this.facturi) {
            this.facturi.forEach(element => {
              if(element.Modul == 'EE') {
                if(element.An == this.currentYear-1)
                  this.costMZEE[0].data.push(parseFloat(element.ValoareFacturata).toFixed(2));
                else
                  this.costMZEE[1].data.push(parseFloat(element.ValoareFacturata).toFixed(2));
              } else {
                if(element.An == this.currentYear-1)
                  this.costMZGN[0].data.push(parseFloat(element.ValoareFacturata).toFixed(2));
                else
                  this.costMZGN[1].data.push(parseFloat(element.ValoareFacturata).toFixed(2));
              }
            });
          }
        }

    },

    created() {
      if (this.pcId) {
        this.getConsumOrar();
        this.getFacturaMedieLunara(this.pcId);
        this.getLunarEE(this.pcId);
        this.getLunarGN(this.pcId);
        this.calcSumeFacturate();
      }
      // this.fromInterval = this.getYearMonths();
      // this.toInterval = this.getYearMonths();
      const monthYear = (this.currentMonth < 10 ? '0' + this.currentMonth : this.currentMonth) + '.' + this.currentYear;
      this.selectedFromInterval = '01.' + this.currentYear;
      this.selectedToInterval = monthYear;

      this.facturiLunareOptions.title.text = 'Sume facturate lunar ' + String(this.currentYear) + ' comparat cu ' + String(this.currentYear - 1);
      this.consumMediuZilnicEE.title.text = 'Consum mediu zilnic pe luna aferent anului ' + String(this.currentYear) + ' comparat cu ' + String(this.currentYear - 1);
      this.costMediuZilnicEE.title.text = 'Cost mediu zilnic pe luna aferent anului ' + String(this.currentYear) + ' comparat cu ' + String(this.currentYear - 1);
      this.consumMediuZilnicGN.title.text = 'Consum mediu zilnic pe luna aferent anului ' + String(this.currentYear) + ' comparat cu ' + String(this.currentYear - 1);
      this.costMediuZilnicGN.title.text = 'Cost mediu zilnic pe luna aferent anului ' + String(this.currentYear) + ' comparat cu ' + String(this.currentYear - 1);
    },

    watch: {
      async selectedDay(){
        this.getConsumOrar();
      },
      async selectedAfisare(){
        this.getConsumOrar();
      },
      async selectedFromIntervalDP(to) {
        let [year, month] = to.split('-');
        this.selectedFromInterval = month + '.' + year;
      },
      async selectedToIntervalDP(to) {
        let [year, month] = to.split('-');
        this.selectedToInterval = month + '.' + year;
      },
      async pcId(to) {
        await this.getConsumOrar();
        await this.getFacturaMedieLunara(to);
        await this.getLunarEE(to);
        await this.getLunarGN(to);
        await this.calcSumeFacturate();
      }
    }
}
</script>

<style scoped>

</style>
