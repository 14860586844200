<template>
    <v-container fluid align-start class="rapoarte">
        <v-row>
          <v-col>
            <h1>Rapoarte</h1>
          </v-col>
          <!--v-col class="text-right">
            <v-btn small style="background: #FF5F7824">
                <v-icon color="#F02D4B">mdi-text-box-check</v-icon>
                <span class="details"><b>Export</b></span>
            </v-btn>
          </v-col-->
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-select class="no-border-select" :items="lcName" v-model="selectedPC" label="Loc de consum" filled></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-tabs background-color="transparent" color="red">
              <v-tab><span style="text-transform:none !important; letter-spacing:0px; height:21px;" class="c16-20-dm-sans-medium">Raport simplificat</span></v-tab>
              <v-tab-item>
                <RaportSimplificat :pcId="selectedPC"/>
              </v-tab-item>
              <v-tab><span style="text-transform:none !important; letter-spacing:0px; height:21px;" class="c16-20-dm-sans-medium">Raport detaliat</span></v-tab>
              <v-tab-item>
                <RaportDetaliat :pcId="selectedPC"/>
              </v-tab-item>
              <v-tab><span style="text-transform:none !important; letter-spacing:0px; height:21px;" class="c16-20-dm-sans-medium">Consumuri orare</span></v-tab>
              <v-tab-item>
                <ConsumuriOrare :pcId="selectedPC"/>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ConsumuriOrare from './../components/Rapoarte/ConsumuriOrare';
import RaportDetaliat from './../components/Rapoarte/RaportDetaliat';
import RaportSimplificat from './../components/Rapoarte/RaportSimplificat';
import Http from "../common/js/Http.js";

export default {
  name: 'Rapoarte',
  data() {
    return {
      lc: null,
      lcName: [],
      selectedPC : null,
      items: ['Foo', 'Bar', 'Fizz', 'Buzz'],

    }
  },
  watch: {
  },
  methods: {
      async getLC() {
        let http = new Http();
        this.lc = await http.get(`/webapi/rapoarte/locuriconsum`);
        this.lc = await this.lc.data;
        this.lcName = [];
        this.lc.forEach(pc => {
          this.lcName.push(
            {
              value:pc.IdPunctConsum,
              text:pc.NumePunctConsum
            })
        });
      }

  },
  async created() {
    await this.getLC();
    this.$root.$children[0].accountSelected = null;
    this.$root.$children[0].selectedMenuItem = 'reports';
  },
  components: {
      ConsumuriOrare,
      RaportDetaliat,
      RaportSimplificat
  }
}
</script>
<style scoped>

</style>
