<template>
    <v-container fluid class="prezentare align-center">
        <v-row>
            <v-col>
                <v-card class="v-card-custom">
                    <v-card-text style="color:black;">
                        <div style="margin-bottom:20px">
                            <pre class="c15-20-dm-sans-medium">Adresa</pre>
                            <pre class="c15-20-dm-sans" v-if="pc.idTara">str. {{pc.Strada}}, Nr. {{pc.Numar}}, Bl.{{pc.Bloc}}, Scara {{pc.Scara}}, Et.{{pc.Etaj}}</pre>
                            <pre class="c15-20-dm-sans" v-if="pc.idTara">Cod postal: {{pc.CodPostal}}</pre>
                            <pre class="c15-20-dm-sans" v-if="pc.idTara">{{pc.idTara.Tara}}, {{pc.idJudet.Judet}}, {{pc.Localitate}}</pre>
                        </div>
                        <LocuriAddress :pc="pc">
                            <template v-slot:button>
                                <span class="red-notice no-text-transform">Cere schimbarea adresei</span>
                            </template>
                        </LocuriAddress>
                        <div v-if="'CodCAEN' in pc && pc.CodCAEN" style="margin-top:15px" class="c14-19-dm-sans-medium">Cod CAEN: {{pc.CodCAEN}}</div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h3 class="c22-20-dm-sans">Servicii active</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3" v-if="relations && relations[0].AreContractEE == '1'">
                <v-card class="v-card-custom">
                    <v-card-text style="color:black;">
                        <v-row>
                            <v-col cols="2">
                                <v-img
                                    contain
                                    align-center
                                    width="35px"
                                    height="33px"
                                    alt="Energie"
                                    :src="require('../../assets/Energy.svg')"
                                />
                            </v-col>
                            <v-col>
                                <p class="c16-20-dm-sans-medium">Energie Electrica

                                </p>
                                <p class="c15-20-dm-sans">Cod POD: {{relations[0].CodPOD}}</p>
                                <p class="c15-20-dm-sans">Cod NLC: {{relations[0].CodNLC}}</p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="3" v-if="relations && relations[0].AreContractGN == '1'">
                <v-card class="v-card-custom">
                    <v-card-text style="color:black;">
                        <v-row>
                            <v-col cols="2">
                                <v-img
                                    contain
                                    align-center
                                    width="35px"
                                    height="33px"
                                    alt="Energie"
                                    :src="require('../../assets/Gas.svg')"
                                />
                            </v-col>
                            <v-col class="pb-12">
                                <p class="c16-20-dm-sans-medium">Gaze naturale
                                </p>
                                <p class="c15-20-dm-sans">Cod CLC: {{relations[0].CodDistribuitor}}</p>

                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row>
            <v-col>
                <h3 class="c22-20-dm-sans">Consumuri orare</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2">
            <v-select class="no-border-select" dense filled :items="months" v-model="selectedMonth" label="Luna"></v-select>
            </v-col>
            <v-col cols="2">
            <v-select class="no-border-select" dense filled v-model="consum" label="Consum"></v-select>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
            <v-col>
                <pre class="c16-20-dm-sans-medium">0 inregistrari</pre>
            </v-col>
          <v-col class="text-right">
            <v-btn small style="background: #FF5F7824" v-on:click="exportFile">
                <v-icon color="#F02D4B">mdi-text-box-check</v-icon>
                <span class="details"><b>Export</b></span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="consumuri" no-gutters justify="space-between" class="pr-8">
            <v-col cols="auto" v-for="h in header" :key="h"><p class="s-col">{{h}}</p></v-col>
        </v-row>
        <!-- <v-row :class="'row-'+((index%2) + 1)" v-for="(c, index) in consumuri" :key="c.Ibd" justify="space-between" class="mr-8" no-gutters> -->

        <v-row v-for="(c) in consumuri" :key="c.Ibd" justify="space-between" class="pr-10 mb-2 bubble" no-gutters>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.Ibd}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z1.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z2.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z3.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z4.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z5.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z6.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z7.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z8.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z9.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z10.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z11.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z12.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z13.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z14.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z15.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z16.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z17.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z18.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z19.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z20.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z22.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z23.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z24.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z25.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z26.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z27.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z28.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z29.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z30.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z31.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto">
                <!-- <v-divider style="" vertical></v-divider> -->
                <p v-if="c.Ibd" class="s-col">{{c.Total.replace('.','0.')}}</p>
            </v-col>
        </v-row>
        <v-alert type="error" text v-if="errorMessage != ''">
        <span class="c15-20-s-dm-sans">{{errorMessage}}</span>
        </v-alert>
    </v-container>
</template>

<script>
// @ is an alias to /src
import validate from "../../common/js/validate.js";
import Http from "../../common/js/Http.js";
import Helper from "../../common/js/Helper.js";
import LocuriAddress from "../modals/LocuriAddress";

export default {
    mixins: [validate],
    name: 'Prezentare',
    data() {
    return {
        days:['Du', 'Lu', 'Ma', 'Mi', 'Jo', 'Vi', 'Sa'],
        header:[],
        pc:{},
        relations:null,
        consumuri:null,
        consum:'',
        months:['01.2020','02.2020','03.2020','04.2020','05.2020','06.2020','07.2020','08.2020','09.2020','10.2020','11.2020','12.2020',],
        selectedMonth:'',
        errorMessage: ''
    }
    },
    watch: {
        async selectedMonth(to) {
            let [month, year] = to.split(".");
            await this.getConsumOrar(year, month);
            this.getMonthDays(year, month);
        }
    },

    methods: {
        async getPunctConsum() {
            let http = new Http();
            let pc = null;
            pc = await http.get(`/webapi/locuriconsum/locconsum?id=${this.$route.params.id}`);
            this.pc = await pc.data.data.pc;
            this.relations = await pc.data.data.relations;
            this.errorMessage = '';
        },
        async getConsumOrar(year, month) {
            let http = new Http();
            this.consumuri = await http.get(`/webapi/locuriconsum/consumuriorare?pc=${this.$route.params.id}&year=${year}&month=${month}`);
            this.consumuri = await this.consumuri.data.data.consumuri;
            this.errorMessage = '';
        },
        async getMonthDays(y, m) {
            this.header = ['Ora'];
            let d = new Date(`${y}-${m}`);
            let dinM = new Date(y,m,0);
            for (let i = d.getDate(); i <= dinM.getDate(); i++) {
                let cDate = new Date(`${y}-${m}-${i}`);
                this.header.push(`Z${i}(`+this.days[cDate.getDay()]+')');
            }
            this.header.push('Total');
        },

        exportFile(ev) {
            ev.preventDefault();
            if (this.selectedMonth) {
                let [month, year] = this.selectedMonth.split('.');
                Helper.downloadXLSX(`/webapi/exports/consumuriOrare?pc=${this.$route.params.id}&year=${year}&month=${month}`, `consumuri_orare_${month}_${year}`);
            } else {
                this.errorMessage = 'Selecteaza o luna pentru a exporta!';
            }
        }
    },

    created() {
        this.getPunctConsum();
        //this.getConsumOrar();
//        this.getMonthDays();

//        let year = new Date().getFullYear()
    },
    components: {
        LocuriAddress
    }
}
</script>
<style scoped>
.bubble {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 0px #b5957b1a;
  border-radius: 12px;
}
.prezentare {
    background-color: #FFFCF9;
}
.s-col {
    padding-top:15px;
    width: 0px;
    font: normal normal 700 .7vw DM Sans !important;
    letter-spacing: 0px;
}
.red-notice {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-15)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0-3);
    color: var(--nova-red);
    text-align: left;
    font: normal normal normal 15px/20px DM Sans;
    letter-spacing: 0.3px;
    color: #F02D4B;
}
</style>
