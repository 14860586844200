<template>
  <v-container fluid>
    <router-view>
      <transition name="route" mode="out-in"></transition>
    </router-view>
  </v-container>
</template>

<script>
export default {
  name: "AppContainer",

  data: () => ({}),
};
</script>
<style></style>
