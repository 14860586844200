<template>
  <div class="text-center">
    <v-dialog
      width="62%"
      v-model="modalPV"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <slot name="button"></slot>
        </div>
      </template>
      <v-card>
        <v-container fluid style="padding:0;">
          <v-card-title class="pa-md-2">
            <v-row no-gutters>
              <v-col>
                <span style="float:left; margin-top:6px">
                <v-img
                  v-if="item.TipContract == 'EE'"
                  contain
                  align-center
                  width="25px"
                  height="20px"
                  alt="Energie"
                  :src="require('./../../assets/Energy.svg')"
                />
                <v-img
                  v-if="item.TipContract == 'GN'"
                  contain
                  align-center
                  width="25px"
                  height="20px"
                  alt="Gaz"
                  :src="require('./../../assets/Gas.svg')"
                />
                </span>
                <span class="pl-3 c15-20-dm-sans-medium"><b>Proces verbal {{titleType}} - {{item.LunaNume}} {{item.An}}</b></span>
              </v-col>
              <v-col class="text-right">
                <v-btn icon @click.stop="hide">
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row v-if="loading === true">
              <v-col align="center">
                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col>
                <pre class="c16-20-s-dm-sans-medium" style="margin-bottom:12px">Raport proces verbal</pre>
                <v-simple-table class="table-small">
                  <tbody>
                    <!-- <tr v-if="details.estimari > 0">
                      <td><b>Estimari</b></td>
                      <td class="text-right">{{details.estimari}} {{item.CantitateFacturata.split(' ')[1]}}</td>
                    </tr> -->
                    <tr v-if="details.pierderi > 0">
                      <td><b>Pierderi</b></td>
                      <td class="text-right">{{details.pierderi}} kWh</td>
                    </tr>
                    <tr v-if="details.total > 0">
                      <td><b>Total energie facturata</b></td>
                      <td class="text-right"><b>{{details.total}} {{item._UM}}</b></td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
              <v-col align="right">
                <v-btn
                  v-if="item.idDoc && item.AdresaWebDocument"
                  contain
                  text
                  class="no-text-transform download-btn"
                  v-on:click="exportFile(item.idDoc, item.AdresaWebDocument)"
                >
                  <v-icon color="#F02D4B">mdi-file-pdf</v-icon>
                  <span class="c15-20-dm-sans"><b>Descarca</b></span>
                </v-btn>
                <div v-else class="text-left ml-12">
                  <span class="c15-20-dm-sans-medium close-text">Procesul verbal nu este inca disponibil spre descarcare. Va rugam sa reveniti!</span>
                </div>
              </v-col>
            </v-row>
            <v-divider v-if="nrc != ''"></v-divider>
            <v-row>
              <v-col>
                <pre class="c16-20-s-dm-sans-medium">{{nrc}}</pre>
              </v-col>
            </v-row>
            <div v-for="(data, id) in details.contoare" :key="id" class="pvd-box mr-1 ml-1">
              <div v-if="item.TipContract == 'EE'">
              <v-row>
                <v-col class="text-left">
                  <pre class="c16-20-s-dm-sans-medium" style="margin-bottom:3px">Contor #{{data.Serie}}</pre>
                  <pre class="c14-20-dm-sans-subtext">{{data.IndexInitial}} <v-icon class="text-center" small>mdi-arrow-right-bold</v-icon> {{data.IndexFinal}} kWh</pre>
                </v-col>
                <v-col class="text-right">
                  <pre v-if="data.Activ == 1" style="color:#2DB7A4">Activ</pre>
                  <pre v-if="data.DataInceput && data.DataSfarsit">{{Helper.dateFormatter(data.DataInceput)}} - {{Helper.dateFormatter(data.DataSfarsit)}}</pre>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="6">
                  <v-simple-table class="table-small">
                    <tbody>
                      <tr>
                        <td><b>Consum</b></td>
                        <td class="text-right">{{item._UM.toLowerCase() == 'kwh' ? calcWithDecimals(data.Consum) : calcWithDecimals(data.Consum*1000)}} kWh</td>
                      </tr>
                      <tr v-if="data.Pierderi > 0">
                        <td><b>Pierderi</b></td>
                        <td class="text-right">{{item._UM.toLowerCase() == 'kwh' ? calcWithDecimals(data.Pierderi) : calcWithDecimals(data.Pierderi*1000)}} kWh</td>
                      </tr>
                      <tr>
                        <td><b>Total</b></td>
                        <td class="text-right"><b>{{item._UM.toLowerCase() == 'kwh' ? calcWithDecimals(data.Total) : calcWithDecimals(data.Total*1000)}} kWh ({{item._UM.toLowerCase() == 'kwh' ? calcWithDecimals(data.Total/1000) : calcWithDecimals(data.Total)}} MWh)</b></td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
              </div>
              <div v-if="item.TipContract == 'GN'">
              <v-row>
                <v-col class="text-left">
                  <pre class="c16-20-s-dm-sans-medium" style="margin-bottom:3px">Contor #{{data.Serie}}</pre>
                  <pre class="c14-20-dm-sans-subtext">{{data.IndexInitial}} <v-icon class="text-center" small>mdi-arrow-right-bold</v-icon> {{data.IndexFinal}} mc</pre>
                </v-col>
                <v-col class="text-right">
                  <pre v-if="data.Activ == 1" style="color:#2DB7A4">Activ</pre>
                  <pre v-if="data.DataInceput && data.DataSfarsit">{{Helper.dateFormatter(data.DataInceput)}} - {{Helper.dateFormatter(data.DataSfarsit)}}</pre>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="6">
                  <v-simple-table class="table-small">
                    <tbody>
                      <tr>
                        <td><b>Consum</b></td>
                        <td class="text-right">{{item._UM.toLowerCase() == 'kwh' ? calcWithDecimals(data.Consum) : calcWithDecimals(data.Consum*1000)}} mc</td>
                      </tr>
                      <tr v-if="data.PCS > 0">
                        <td><b>Putere calorica superioara</b></td>
                        <td class="text-right">{{data.PCS}} kWh/mc</td>
                      </tr>
                      <tr v-if="data.Cantitate > 0">
                        <td><b>Cantitate energie facturata</b></td>
                        <td class="text-right">{{item._UM.toLowerCase() == 'kwh' ? calcWithDecimals(data.Cantitate/1000) : calcWithDecimals(data.Cantitate)}} MWh ({{item._UM.toLowerCase() == 'kwh' ? calcWithDecimals(data.Cantitate) : calcWithDecimals(data.Cantitate*1000)}} kWh)</td>
                      </tr>
                      <tr>
                        <td><b>Total</b></td>
                        <td class="text-right"><b>{{item._UM.toLowerCase() == 'kwh' ? calcWithDecimals(data.Total/1000) : calcWithDecimals(data.Total)}} MWh ({{item._UM.toLowerCase() == 'kwh' ? calcWithDecimals(data.Total) : calcWithDecimals(data.Total*1000)}} kWh)</b></td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
              </div>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn class="ml-3 close-text" text @click.stop="hide">
            Inchide
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Http from "./../../common/js/Http";
import Helper from "./../../common/js/Helper";

export default {
  name: 'PV',
  props: ['item'],
  data() {
    return {
      Helper,
      loading: true,
      titleType: '',
      modalPV: false,
      details: {},
      nrc: ''
    }
  },
  components: {
  },
  created() {
  },
  watch: {
    modalPV(status) {
      if (status) {
        switch (this.item.TipContract) {
          case 'EE':
            this.titleType = 'energie electrica';
            break;
          case 'GN':
            this.titleType = 'gaze naturale';
            break;
        }
        this.item._UM = this.item.CantitateFacturata.split(' ')[1];
        this.getPC();
      }
    }
  },
  methods: {
    hide() {
      this.modalPV = false;
    },
    exportFile(id, url) {
      Helper.downloadFile(`/webapi/exports/webDoc?id=${id}`, Helper.basename(url));
    },
    async getPC() {
      let http = new Http();
      let res = await http.get(`/webapi/locuriconsum/details?idPunctConsum=${this.item.IdPunctConsum}&idTipuriLuniAni=${this.item.IdTipuriLuniAni}&year=${this.item.An}&month=${this.item.Luna}&type=${this.item.TipContract}`);
      if (res.data) {
        this.details = res.data.data;
        // If key does not exists
        if (typeof this.details.contoare === 'undefined') {
          this.details.contoare = [];
        }
        const nrc = Object.keys(this.details.contoare).length;
        this.nrc = nrc + ' ' + (nrc == 1 ? 'contor' : 'contoare');
      }
      this.loading = false;
    },
    calcWithDecimals(nr) {
      return parseFloat(nr).toFixed(3);
    }
  }
}
</script>
<style scoped>
.close-text {
  color: #F02D4B;
  font-weight: bold;
  text-transform: none;
}
.table-small > .v-data-table__wrapper > table {
  width: auto !important;
}
.table-small > .v-data-table__wrapper > table > tbody > tr > td {
  height: auto;
  padding: 3px 0;
  border-bottom: 0 !important;
}
.table-small > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: none !important;
}

.pvd-box {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 0 15px;
}
.download-btn {
  color: #F02D4B;
  background-color: #FF5F7824
}
</style>
