<template>
    <v-container fluid align-start class="facturi">
        <v-row>
          <v-col>
            <h1 class="c32-20-dm-sans">Autocitire</h1>
          </v-col>
        </v-row>
        <br />
        <v-row no-gutters justify-start>
            <v-col cols="3" class="npg-box mr-7">
                <v-row>
                    <v-col cols="2" align-self="start">
                      <v-img
                          contain
                          class="ml-3"
                          width="35px"
                          height="33px"
                          alt="Energie"
                          :src="require('./../assets/Energy.svg')"
                      />
                    </v-col>
                    <v-col>
                        <pre class="c16-20-dm-sans-medium" style="margin-bottom:5px">Autocitire Energie</pre>
                        <span class="c15-20-dm-sans-subtext" style="margin-bottom:5px">{{newsEE}}</span>
                        <addReadModal v-if="newsEE != ''" :autocitiri="autocitiriEE" :atype="'Energie electrica'" v-on:daysDiffEE="daysDiffEE($event)">
                            <template v-slot:button>
                                <span style="text-transform:none !important; color: #F02D4B">Trimite autocitire</span>
                            </template>
                        </addReadModal>
                        <viewModal v-else-if="viewEE" :autocitiri="autocitiriEE" :atype="'Energie electrica'">
                            <template v-slot:button>
                                <span style="text-transform:none !important; color: #F02D4B">Vizualizeaza</span>
                            </template>
                        </viewModal>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="3" class="npg-box">
                <v-row>
                    <v-col cols="2" align-self="start">
                      <v-img
                          contain
                          class="ml-3"
                          align-center
                          width="35px"
                          height="33px"
                          alt="Gaz"
                          :src="require('./../assets/Gas.svg')"
                      />
                    </v-col>
                    <v-col>
                        <pre class="c16-20-dm-sans-medium" style="margin-bottom:5px">Autocitire Gaz</pre>
                        <span class="c15-20-dm-sans-subtext" style="margin-bottom:5px">{{newsGN}}</span>
                        <addReadModal v-if="newsGN != ''" :autocitiri="autocitiriGN" :atype="'Gaze naturale'" v-on:daysDiffGN="daysDiffGN($event)">
                            <template v-slot:button>
                                <span style="text-transform:none !important; color: #F02D4B">Trimite autocitire</span>
                            </template>
                        </addReadModal>
                        <viewModal v-else-if="viewGN" :autocitiri="autocitiriGN" :atype="'Gaze naturale'">
                            <template v-slot:button>
                                <span style="text-transform:none !important; color: #F02D4B">Vizualizeaza</span>
                            </template>
                        </viewModal>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <br />
        <v-row class="d-flex justify-space-between">
            <v-col><h3 class="c22-20-dm-sans-medium">Autocitiri anterioare</h3></v-col>
            <v-col class="text-right">
                <v-btn small style="background: #FF5F7824" v-on:click="exportFile">
                    <v-icon color="#F02D4B">mdi-text-box-check</v-icon>
                    <span class="details"><b>Export</b></span>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-tabs background-color="transparent" color="red">
              <v-tab v-on:click="setTab(0)"><span class="c16-20-dm-sans-medium" style="text-transform:none !important">Toate autocitirile</span></v-tab>
              <v-tab-item>
                <Toate @autocitiriFromToate="toateAutocitiri"/>
              </v-tab-item>
              <v-tab v-on:click="setTab(1)"><span class="c16-20-dm-sans-medium" style="text-transform:none !important">Energie electrica</span></v-tab>
              <v-tab-item>
                <EE />
              </v-tab-item>
              <v-tab v-on:click="setTab(2)"><span class="c16-20-dm-sans-medium" style="text-transform:none !important">Gaz</span></v-tab>
              <v-tab-item>
                <GN />
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
    </v-container>
</template>

<script>
// @ is an alias to /src
import Toate from './../components/Autocitire/Toate';
import EE from './../components/Autocitire/EE';
import GN from './../components/Autocitire/GN';
import validate from "../common/js/validate.js";
import addReadModal from "../components/modals/autocitire/Add";
import viewModal from "../components/modals/autocitire/View";
import Helper from "../common/js/Helper.js";

export default {
  name: 'Autocitire',
  mixins: [validate],
  data() {
    return {
      diffTypeEE: null,
      daysDiffArray: [],
      helper:Helper,
      autocitiri:[],
      autocitiriEE:[],
      newsEE:'',
      autocitiriGN:[],
      newsGN:'',
      tab: null,
      selectedTab: 0,
      items: [
          { tab: 'One', content: 'Tab 1 Content' },
          { tab: 'Two', content: 'Tab 2 Content' },
          { tab: 'Three', content: 'Tab 3 Content' },
      ],
      // autoEEModal: [],
      // autoGNModal: [],
      viewEE: false,
      viewGN: false
    }
  },
  watch: {
    daysDiffArray(to) {
      this.newsEE = '';
      this.newsGN = '';

      to.forEach(elem => {
        elem.forEach(elem2 => {
          elem2.forEach(elem3 => {
            if(this.newsEE == '' && elem3.newsEE != null) {
              this.newsEE = elem3.newsEE;
            }
            if(this.newsGN == '' && elem3.newsGN != null) {
              this.newsGN = elem3.newsGN;
            }
          })
//         console.log(elem2);
        })
      });
    }
  },
  methods: {
    daysDiffEE(event) {
      this.daysDiffArray.push(event);
    },
    daysDiffGN(event) {
      this.daysDiffArray.push(event);
    },
    async toateAutocitiri(autocitiriEE, autocitiriGN) {
      this.autocitiriEE = autocitiriEE;
      let currentDate = new Date(this.helper.formatDate());
      let autocitiriEECurrentSend = [];
      let autocitiriEEToSend = [];
      this.autocitiriEE.forEach(elem => {
        if (elem.TotalCantitate != 0 || elem.InPerioadaAutocitire == '1') {
          // this.autoEEModal.push(elem);
        let cStartDate = new Date(elem.DataInceputAutocitire);
        let cEndDate = new Date(elem.DataFinalAutocitire);
        if(currentDate.getTime() <= cEndDate.getTime() &&
          currentDate.getTime() >= cStartDate.getTime()
        ) {
          autocitiriEECurrentSend[this.helper.daysBetween2Dates(currentDate, cEndDate)] = elem;
        } else if(currentDate.getTime() < cStartDate.getTime()) {
          autocitiriEEToSend[this.helper.daysBetween2Dates(currentDate, cStartDate)] = elem;
        }
        }
      });

      this.autocitiriGN = autocitiriGN;
      let autocitiriGNCurrentSend = [];
      let autocitiriGNToSend = [];
      this.autocitiriGN.forEach(elem => {
        if (elem.TotalCantitate != 0 || elem.InPerioadaAutocitire == '1') {
          // this.autoGNModal.push(elem);
        let cStartDate = new Date(elem.DataInceputAutocitire);
        let cEndDate = new Date(elem.DataFinalAutocitire);
        if(currentDate.getTime() <= cEndDate.getTime() &&
          currentDate.getTime() >= cStartDate.getTime()
        ) {
          autocitiriGNCurrentSend[this.helper.daysBetween2Dates(currentDate, cEndDate)] = elem;
        } else if(currentDate.getTime() < cStartDate.getTime()) {
          autocitiriGNToSend[this.helper.daysBetween2Dates(currentDate, cStartDate)] = elem;
        }
        }
      });

      if(
        autocitiriEECurrentSend.length == 0 &&
        autocitiriEEToSend.length == 0
      ) {
        this.newsEE = '';
      } else if(
        autocitiriEECurrentSend.length == 0 &&
        autocitiriEEToSend.length != 0
      ) {
        const iter = autocitiriEEToSend.keys();
        for (const key of iter) {
          this.newsEE = `Puteti trimite autocitirea peste ${key} zile`;
          break;
        }
      } else if(
        autocitiriEECurrentSend.length != 0 &&
        autocitiriEEToSend.length == 0
      ) {
        const iter = autocitiriEECurrentSend.keys();
        for (const key of iter) {
          this.newsEE = `${key} zile ramase pentru a trimite autocitirea`;
          break;
        }
      }

      if(
        autocitiriGNCurrentSend.length == 0 &&
        autocitiriGNToSend.length == 0
      ) {
        this.newsGN = '';
      } else if(
        autocitiriGNCurrentSend.length == 0 &&
        autocitiriGNToSend.length != 0
      ) {
        const iter = autocitiriGNToSend.keys();
        for (const key of iter) {
          this.newsGN = `Puteti trimite autocitirea peste ${key} zile`;
          break;
        }
      } else if(
        autocitiriGNCurrentSend.length != 0 &&
        autocitiriGNToSend.length == 0
      ) {
        const iter = autocitiriGNCurrentSend.keys();
        for (const key of iter) {
          this.newsGN = `${key} zile ramase pentru a trimite autocitirea`;
          break;
        }
      }

      let viewEEItems = [];
      this.autocitiriEE.forEach(elem => {
        if (elem.IdPunctConsum && !viewEEItems.includes(elem.IdPunctConsum)) {
          viewEEItems.push(elem.IdPunctConsum);
        }
      });
      this.viewEE = viewEEItems.length > 0;
      let viewGNItems = [];
      this.autocitiriGN.forEach(elem => {
        if (elem.IdPunctConsum && !viewGNItems.includes(elem.IdPunctConsum)) {
          viewGNItems.push(elem.IdPunctConsum);
        }
      });
      this.viewGN = viewGNItems.length > 0;
    },
    setTab(mod) {
      this.selectedTab = mod;
    },
    exportFile(ev) {
      ev.preventDefault();
      let name;
      switch (this.selectedTab) {
        case 1:
          name = 'ee';
          break;
        case 2:
          name = 'gn';
          break;
        default:
          name = 'toate';
          break;
      }
      Helper.downloadXLSX(`/webapi/exports/autocitiri?tab=${this.selectedTab}`, `autocitiri_${name}`);
    }
  },
  created() {
    this.$root.$children[0].accountSelected = null;
    this.$root.$children[0].selectedMenuItem = 'autocitiri';
  },
  components: {
      Toate,
      EE,
      GN,
      addReadModal,
      viewModal
  }
}
</script>
<style scoped>
.red-notice {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-15)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0-3);
    color: var(--nova-red);
    text-align: left;
    font: normal normal normal 15px/20px DM Sans;
    letter-spacing: 0.3px;
    color: #F02D4B;
}
.details {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-15)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nova-red);
    font: normal normal medium 15px/20px DM Sans;
    letter-spacing: 0px;
    color: #F02D4B;
}
.pay-btn {
  border: 2px solid var(--green-primary);
  background: white !important;
  border: 2px solid #2DB7A4;
  border-radius: 5px;
  color: #2DB7A4;
}

.npg-box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #B5957B1A;
    border-radius: 12px;
}

</style>
