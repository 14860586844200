<template>
    <div>
        <v-form ref="form2" v-model="valid2">
            <v-row no-gutters v-if="pname">
                <v-select :items="countries" v-model="ptara" outlined class="form-select" label="Tara"></v-select>
            </v-row>
            <v-row no-gutters>
                <v-select :items="formattedCounties" v-model="pjudet" outlined class="form-select" label="Judet"></v-select>
            </v-row>
            <v-row no-gutters>
                <v-combobox :items="formattedCities" v-model="porasName" outlined class="form-select" hide-selected label="Localitate"></v-combobox>
            </v-row>
            <v-row no-gutters>
                <v-combobox :items="formattedStreets" v-model="pstrada" outlined class="form-select" hide-selected label="Strada"></v-combobox>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <v-text-field class="pa-2 form-select" outlined type="number" v-model="pnumar" label="Numar"></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field class="pa-2 form-select" outlined type="string" v-model="pbloc" label="Bloc"></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field class="pa-2 form-select" outlined type="number" v-model="pscara" label="Scara"></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <v-text-field class="pa-2 form-select" outlined type="number" v-model="ptronson" label="Tronson"></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field class="pa-2 form-select" outlined type="string" v-model="petaj" label="Etaj"></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field class="pa-2 form-select" outlined type="number" v-model="pap" label="Ap"></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <v-text-field class="pa-2 form-select" outlined type="number" v-model="psector" label="Sector"></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field class="pa-2 form-select" outlined type="number" v-model="pcodpostal" label="Cod postal"></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
            <br/>
            <v-divider/>
            <br/>
            <v-row>
                <v-col>
                <v-textarea name="input-7-1" outlined v-model="pobs" label="Observatii" auto-grow background-color="transparent"
                    value=""
                ></v-textarea>
                </v-col>
            </v-row>
            <v-divider/>
            <v-row v-if="message">
                <v-col>
                    <v-alert type="success">
                    {{messageStatus}}
                    </v-alert>
                </v-col>
            </v-row>
            <v-row v-if="error">
                <v-col>
                    <v-alert type="error">
                    <pre>{{errorMessage}}</pre>
                    </v-alert>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn
                        class="no-text-transform green-white-btn"
                        dark
                        @click="savePartnerAddress()"
                    >
                        Salveaza
                    </v-btn>
                    <v-btn
                        text
                        class="close-text"
                        @click="closeDialog()"
                    >
                        Inchide
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>
<script>
import Http from "./../../../common/js/Http";
import validate from "./../../../common/js/validate.js";
import globals from "./../../../common/js/globals.js";

export default {
    name: 'Adresa',
    mixins: [validate, globals],
    props: {

    },
    data() {
        return {
            messageStatus : true,
            message : '',
            error: false,
            errorMessage: '',
            valid2 : true,
            countries : [],
            counties : [],
            formattedCounties : [],
            cities : [],
            formattedCities : [],
            streets : [],
            formattedStreets : [],
            pname : '',

            ptara : '',
            ptaraName : '',
            pjudet : '',
            pjudetName : '',
            poras : '',
            porasName : '',
            pstrada : '',
            pstradaName : '',
            pnumar : '',
            pbloc : '',
            pscara : '',
            ptronson : '',
            petaj : '',
            pap : '',
            psector : '',
            pcodpostal : '',
            pobs : '',

        }
    },

    watch: {
        async ptara(to) {
            this.countries.forEach(elem => {
                if(to == elem.value)
                    this.ptaraName = elem.text
            });

            let http = new Http();
            this.counties = await http.get(`/webapi/account/getcountiesbycountry?country=${to}`);
            this.counties = this.counties.data;
        },

        counties() {
            if(this.counties != null) {
                this.formattedCounties = [];
                this.counties.forEach(elem => {
                    this.formattedCounties.push({
                        text : elem.DenumireFaraDiacritice,
                        value : elem.IdJudete
                    })
                });
            }
        },
        async pjudet(to) {
            if(this.counties != null) {
                this.counties.forEach(elem => {
                    if(to == elem.IdJudete)
                        this.pjudetName = elem.DenumireFaraDiacritice
                });
                let http = new Http();
                this.cities = await http.get(`/webapi/account/getcities?country=${this.ptara}&county=${to}`);
                this.cities = this.cities.data;
                if(this.cities != null) {
                    this.formattedCities = [];
                    this.cities.forEach(elem => {
                        this.formattedCities.push({
                            text : elem.label,
                            value : elem.value
                        })
                    });
                }
            }
        },

        async porasName(to) {
            this.formattedCities.forEach(elem => {
                if(elem.text == to.text) {
                    if( (typeof to === "object" || typeof to === 'function') && (to !== null) ) {
                        this.porasName = to.text;
                        this.poras = to.value;
                    }
                }
            });
            this.getStreets();
        },

        user() {
            this.getContracts();

            this.pname = this.user.Name;
            this.pcnp = this.user.CNP;
            this.pemail = this.user.Email;
            this.pmobil = this.user.Mobil;
            this.pcode = this.user.partener.BusinessPartnerCode;
        },
        account() {
//            this.pname = this.account.Nume;
        }
    },

    components: {
    },
    created() {
        this.getPartner();
//        this.getStreets();
        if(this.user != null) {
            this.pname = this.user.Name;
            this.pcnp = this.user.CNP;
            this.pemail = this.user.Email;
            this.pmobil = this.user.Mobil;
            this.pcode = this.user.partener.BusinessPartnerCode;
        }
    },
    methods: {
        closeDialog() {
            this.$emit('close', true);

        },
        async savePartnerAddress() {
            let http = new Http();
            let formData = new FormData();
            formData.append('set-address-id', this.account.partner_address.iDac); // address id [dbo].[Adresa]
            formData.append('entity-id', this.user.PartnerEntityId); // [dbo].[Parteneri] IdParteneri
            formData.append('entity-type-id', 1); // For partner
            formData.append('address-type-id', 2); // Save Sediu social address
            formData.append('associate', 0);
            formData.append('update', 0);
            formData.append('association-address-id', '');
            formData.append('newAddress', 0);

            formData.append('set-address-country-name', this.ptaraName);
            formData.append('set-address-country', this.ptara);

            formData.append('set-address-county-name', this.pjudetName);
            formData.append('set-address-county', this.pjudet);

            formData.append('set-address-city-name', this.porasName);
            formData.append('set-address-city', this.poras);

            formData.append('set-address-street', this.pstrada);
            formData.append('set-address-street-id', this.pstrada);

            formData.append('set-address-street-number', this.pnumar);
            formData.append('set-address-sector', this.psector);
            formData.append('set-address-building', this.pbloc);
            formData.append('set-address-entrance', this.pscara);
            formData.append('set-address-section', this.ptronson);
            formData.append('set-address-floor', this.petaj);
            formData.append('set-address-apartment', this.pap);
            formData.append('set-address-postal-code', this.pcodpostal);
            formData.append('set-address-comment', this.pobs);

            let getPostalCode = await http.get(`/webapi/account/getCheckPostalCode?city=${this.poras}&street=${this.pstrada}&pcode=${this.pcodpostal}`);
            if(getPostalCode.data['status']) {
                let postPAddress = await http.post(`/webapi/address/verify`, formData);

                let saveData = new FormData();
                Object.keys(postPAddress.data.address).forEach(k => {
                    saveData.append(k, postPAddress.data.address[k]);
                });
                saveData.append('actualizeaza', 'Actualizeaza');
                let savedPAddress = await http.post(`/webapi/account/postEditInfo`,
                    saveData
                );
                if(savedPAddress.data.status) {
                    this.message = true;
                    this.messageStatus = 'Adresa salvata.'
                    this.error = false;
                    this.errorMessage = ''
                } else {
                    this.message = false;
                    this.error = true;
                    this.errorMessage = savedPAddress.data.msg;
                }
            } else {
                this.message = false;
                this.error = true;
                this.errorMessage = 'Nu am gasit o informatii pentru acest cod postal.'
            }
        },

        async getStreets() {
            let http = new Http();
            let streets = await http.get(`/webapi/account/streets?city=${this.poras}`);
            this.streets  = await streets;
            this.formattedStreets = [];

            if(Array.isArray(this.streets.data)) {
                this.streets.data.forEach(elem => {
                    this.formattedStreets.push({
                        text : elem.label,
                        value : elem.value
                    })
                });
            } else {
                Object.values(this.streets.data).forEach(elem => {
                    this.formattedStreets.push({
                        text : elem.label,
                        value : elem.value
                    })
                });

            }
        },

        async getPartner() {
            let http = new Http();
            let account = await http.get(`/webapi/account`);
            this.account  = await account.data

            for (const [key, value] of Object.entries(this.account.countries)) {
                this.countries.push({
                    text : value,
                    value : key
                });
            }

            this.ptara = this.account.partner_address.IdTara;
            this.ptaraName = this.account.partner_address.Tara;

            this.pjudet = this.account.partner_address.IdJudet;
            this.pjudetName = this.account.partner_address.Judet;

            this.poras = this.account.partner_address.IdLocalitate;
            this.porasName = this.account.partner_address.Localitate;

            this.pstrada = this.account.partener.Strada;

            this.pnumar = this.account.partner_address.Numar;
            this.pbloc = this.account.partner_address.Bloc;
            this.pscara = this.account.partner_address.Scara;
            this.ptronson = this.account.partner_address.Tronson;
            this.petaj = this.account.partner_address.Etaj;
            this.pap = this.account.partner_address.Apartament;
            this.psector = this.account.partner_address.Sector;
            this.pcodpostal = this.account.partner_address.CodPostal;

        },

    }
}
</script>

<style scoped>
.green-white-btn {
    background: var(--green-primary) 0% 0% no-repeat padding-box !important ;
    background: #2DB7A4 0% 0% no-repeat padding-box !important;
    border-radius: 5px;
}

.close-text {
    color: #F02D4B;
    text-transform: none;
}
</style>


