<template>
  <v-container fluid align-center>
    <div class="ee"></div>
    <div class="gn"></div>
    <div class="login">
      <v-row style="background-color:#FFFCF9  !important;">
        <v-col align="center" justify="center">
          <div class="novabg"></div>
        </v-col>
      </v-row>
      <div class="login-form">
        <v-form ref="resetForm" v-model="recoverForm">
          <v-row style="border-radius:50px;">
            <v-col align="center" justify="center">
              <h2 class="c22-20-dm-sans"><b>Resetare parola</b></h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center" justify="center">
              <v-text-field outlined type="text" v-model="email" :rules="[rules.required, rules.email]" label="Email *"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center" justify="center">
              <v-btn @click="send" class="no-text-transform" width="100%" dark style="background-color:#F02D4B;">
                <span>Trimite link de resetare</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col align="center" justify="center">
            <router-link to="/login">
            <span class="c16-20-dm-sans-medium" style="color:#F02D4B">Inapoi la autentificare</span>
            </router-link>
          </v-col>
        </v-row>
        <v-row v-if="successMessage">
          <v-col align="center" justify="center" class="text-center">
            <v-alert type="success">{{successMessage}}</v-alert>
          </v-col>
        </v-row>
        <v-row v-if="errorMessage">
          <v-col align="center" justify="center" class="text-center">
            <v-alert type="error">{{errorMessage}}</v-alert>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import validate from "../common/js/validate.js";
import globals from "../common/js/globals.js";
import Http from "../common/js/Http.js";

export default {
  name: 'RecoverPassword',
  mixins: [validate, globals],
  data() {
    return {
      recoverForm: true,
      successMessage: '',
      errorMessage: '',
      email: ''
    }
  },
  methods: {
    async send() {
      this.successMessage = '';
      this.errorMessage = '';
      if(this.$refs.resetForm.validate()) {
        let formData = new FormData();
        formData.append('email', this.email);
        const url = new URL(document.location);
        formData.append('link', url.origin + '/resetpassword');
        let http = new Http();
        let postRead = await http.post(`/webapi/account/recoverPassword`, formData);
        if (postRead.data.error) {
          this.errorMessage = postRead.data.error.message;
        }
        if (postRead.data.success) {
          this.successMessage = postRead.data.success.message;
        }
        this.email = '';
        this.$refs.resetForm.resetValidation();
      }
    }
  }
}
</script>
<style scoped>
.terms h1 {
  padding: 0;
  margin-top: 10px;
  margin-bottom: 30px;
}
.novabg {
  background-color: #F02D4B;
  background-repeat: no-repeat;
  width: 156px;
  height: 70px;
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-repeat: no-repeat;
  mask-image: url('./../assets/logo.svg');
}
.ee {
  padding:0;
  margin: 0;
  margin-top:2%;
  background-color: #D1F8D8;
  background-repeat: no-repeat;
  width: 350px;
  float: left;
  height: 350px;
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-repeat: no-repeat;
  mask-image: url('./../assets/Energy.svg');
  transform: rotateY(180deg) rotate(0deg)
}
.gn {
  padding:0;
  margin: 0;
  margin-top:15%;
  background-color: #D1F8D8;
  background-repeat: no-repeat;
  width: 350px;
  height: 350px;
  float: right;
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-repeat: no-repeat;
  mask-image: url('./../assets/Gas.svg');
}
.login {
  margin: auto;
  padding: 10px;
  width: 25%;
}
.login-form {
  background-color: white;
  border-radius: 10px;
  padding: 50px;
  box-shadow: 0 0 60px #dddddd;
}
.v-text-field--outlined >>> fieldset {
  border-color: lightgray;
}
.v-text-field--outlined >>> .v-text-field__slot input {
  color: black;
}
</style>
