<template>
      <v-container fluid class="rapoarte elevation-0"  style="background-color:#FFFCF9;">
        <v-row>
          <v-col><h2>Energie electrica</h2></v-col>
        </v-row>
        <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <v-card class="v-card-custom">
                    <apexchart height="375" :options="eoptions" :series="eseries"></apexchart>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row class="mb-md-5">
                <v-col cols="6">
                  <v-card class="text-center pa-4 v-card-custom" min-width="140">
                    <v-card-text><p class="c15-20-dm-sans">Consum mediu lunar in {{currentYear}}</p></v-card-text>
                    <v-card-text><p class="c32-20-dm-sans" style="line-height: .8;"><b>{{consumMediuLunarEE}} MWh</b></p></v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card class="text-center pa-4 c-card-custom" min-width="140">
                    <v-card-text><p class="c15-20-dm-sans">Factura medie lunara {{currentYear}}</p></v-card-text>
                    <v-card-text><p class="c32-20-dm-sans" style="line-height: .8;"><b>{{facturaMedieEE}} RON</b></p></v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="">
                <v-col cols="6">
                  <v-card class="text-center pa-4 v-card-custom" min-width="140">
                    <v-card-text><p class="c15-20-dm-sans">Consum mediu zilnic in {{currentYear}}</p></v-card-text>
                    <v-card-text><p class="c32-20-dm-sans" style="line-height: .8;"><b>{{consumMediuZilnicEE}} MWh</b></p></v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card class="text-center pa-4 v-card-custom" min-width="140">
                    <v-card-text><p class="c15-20-dm-sans">Cost mediu zilnic in {{currentYear}}</p></v-card-text>
                    <v-card-text><p class="c32-20-dm-sans" style="line-height: .8;"><b>{{costMediuZilnicEE}} RON</b></p></v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
        </v-row>
        <v-row>
          <v-col><h2>Gaze</h2></v-col>
        </v-row>
        <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <v-card class="v-card-custom">
                    <apexchart height="375" :options="goptions" :series="gseries"></apexchart>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row class="mb-md-5">
                <v-col cols="6">
                  <v-card class="text-center pa-4 v-card-custom" min-width="140">
                    <v-card-text><p class="c15-20-dm-sans">Consum mediu lunar in {{currentYear}}</p></v-card-text>
                    <v-card-text><p class="c32-20-dm-sans" style="line-height: .8;"><b>{{ consumMediuLunarGN }} MWh</b></p></v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card class="text-center pa-4 v-card-custom" min-width="140">
                    <v-card-text><p class="c15-20-dm-sans">Factura medie lunara {{currentYear}}</p></v-card-text>
                    <v-card-text><p class="c32-20-dm-sans" style="line-height: .8;"><b>{{facturaMedieGN}} RON</b></p></v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="">
                <v-col cols="6">
                  <v-card class="text-center pa-4 v-card-custom" min-width="140">
                    <v-card-text><p class="c15-20-dm-sans">Consum mediu zilnic in {{currentYear}}</p></v-card-text>
                    <v-card-text><p class="c32-20-dm-sans" style="line-height: .8;"><b>{{consumMediuZilnicGN}} MWh</b></p></v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card class="text-center pa-4 v-card-custom" min-width="140">
                    <v-card-text><p class="c15-20-dm-sans">Cost mediu zilnic in {{currentYear}}</p></v-card-text>
                    <v-card-text><p class="c32-20-dm-sans" style="line-height: .8;"><b>{{costMediuZilnicGN}} RON</b></p></v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
        </v-row>
      </v-container>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import validate from "../../common/js/validate.js";
import globals from "../../common/js/globals.js";
import Http from "../../common/js/Http.js";
import Helper from "../../common/js/Helper";
export default {
    name: 'Dashboard',
    props:['pcId'],
    mixins: [validate, globals],
    data() {
        return {
          helper: Helper,
// Electicitate
          eoptions: {
              colors: ['#4B5AD0', '#4B5AD033'],
              chart: {
                  width: '100%',
                  height: 100,
                  type: 'bar',
                  id: 'vc',

                  toolbar: {
                      show: false
                  },
                  dropShadow: {
                      enabled: true,
                      top: 0,
                      left: 0,
                      blur: 0,
                      opacity: 1,
                      color: 'black'
                  },
              },
              dataLabels: {
                enabled: false
              },
              plotOptions: {
                dataLabels: {
                  enabled: false
                },
                bar: {
                  horizontal: false,
                  columnWidth: '70%'
                }
              },
              stroke: {
//                    curve: 'smooth'
              },
              markers: {
                  size: 6
              },
              title: {
                  text: 'Consum energie electrica 2020 comparat cu 2019',
                  align: 'left',
              },
              xaxis: {
                  categories: ['Ian','Feb','Mar','Apr','Mai','Iun','Iul','Aug','Sep','Oct','Nov','Dec']
              },
              yaxis: {
                  tickAmount: 4
              }
          },

          eseries: [],
// Gaze
          goptions: {
              colors: ['#F4A21F', '#F4A21F40'],
              chart: {
                  width: '100%',
                  height: 100,
                  type: 'bar',
                  id: 'vc',

                  toolbar: {
                      show: false
                  },
                  dropShadow: {
                      enabled: true,
                      top: 0,
                      left: 0,
                      blur: 0,
                      opacity: 1,
                      color: 'black'
                  },
              },
              dataLabels: {
                enabled: false
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: '70%'
                }
              },
              stroke: {
//                    curve: 'smooth'
              },
              markers: {
                  size: 6
              },
              title: {
                  text: 'Consum gaze naturale 2020 comparat cu 2019',
                  align: 'left',
              },
              xaxis: {
                  categories: ['Ian','Feb','Mar','Apr','Mai','Iun','Iul','Aug','Sep','Oct','Nov','Dec']
              },
              yaxis: {
                  tickAmount: 4
              }
          },

          gseries: [],
          consumuriEE:[],
          consumuriGN:[],

          consumMediuLunarEE:0,
          consumMediuLunarGN:0,

          consumMediuZilnicEE:0,
          consumMediuZilnicGN:0,

          facturi:[],
          facturaMedieEE:0,
          facturaMedieGN:0,
          costMediuZilnicEE:0,
          costMediuZilnicGN:0,
        }
    },
    watch: {
      async pcId(to) {
        await this.getLunarEE(to);
        await this.getLunarGN(to);
        await this.medieConsumEE(to);
        await this.medieConsumGN(to);
        await this.getFacturaMedieLunara(to);
      }
    },
    methods: {
      hidePlateste(status) {
          return status
      },

      updateEE(data) {
        if(data) {
          this.eseries = data;
        } else {
          this.eseries = [];
        }
      },

      updateGN(data) {
        if(data) {
          this.gseries = data;
        } else {
          this.gseries = [];
        }
      },

      async getFacturaMedieLunara(pc) {
        let http = new Http();
        this.facturi = await http.get(`/webapi/rapoarte/facturamedia?pc=${pc}&ani=1`);
        this.facturi = await this.facturi.data;
        let totalEE = 0;
        let lenEE = 0;
        let totalGN = 0;
        let lenGN = 0;
        this.facturi.forEach(element => {
          if (element.An == this.currentYear) {
            if (element.Modul === 'EE') {
              totalEE += parseFloat(element.ValoareFacturata);
              lenEE += 1;
            }
            if (element.Modul === 'GN') {
              totalGN += parseFloat(element.ValoareFacturata);
              lenGN += 1;
            }
          }
        });

        this.facturaMedieEE = (lenEE ? (totalEE / lenEE) : 0).toFixed(2);
        this.facturaMedieGN = (lenGN ? (totalGN / lenGN) : 0).toFixed(2);

        this.costMediuZilnicEE = (lenEE ? (totalEE / lenEE / 30) : 0).toFixed(2);
        this.costMediuZilnicGN = (lenGN ? (totalGN / lenGN / 30) : 0).toFixed(2);
      },

      async getLunarEE(pc) {
        let http = new Http();
        this.consumuriEE = await http.get(`/webapi/rapoarte/EELunar?pc=${pc}`);
        this.consumuriEE = await this.consumuriEE.data;
        let apexData = [
          {
            name: Object.keys(this.consumuriEE)[0],
            data: Object.values(this.consumuriEE[Object.keys(this.consumuriEE)[0]]).map(elem => elem)
          },
          {
            name: Object.keys(this.consumuriEE)[1],
            data: Object.values(this.consumuriEE[Object.keys(this.consumuriEE)[1]]).map(elem => elem)
          },
        ];
        this.updateEE(apexData);
        this.consumMediuLunarEE = parseFloat(this.helper.arrayAveragePositive(this.eseries[1].data)).toFixed(2);
      },

      async getLunarGN(pc) {
        let http = new Http();
        this.consumuriGN = await http.get(`/webapi/rapoarte/GNLunar?pc=${pc}`);
        this.consumuriGN = await this.consumuriGN.data;
        let apexData = [
          {
            name: Object.keys(this.consumuriGN)[0],
            data: Object.values(this.consumuriGN[Object.keys(this.consumuriGN)[0]]).map(elem => elem)
          },
          {
            name: Object.keys(this.consumuriGN)[1],
            data: Object.values(this.consumuriGN[Object.keys(this.consumuriGN)[1]]).map(elem => elem)
          },
        ];
        this.updateGN(apexData);
        this.consumMediuLunarGN = parseFloat(this.helper.arrayAveragePositive(this.gseries[1].data)).toFixed(2);
      },

      async medieConsumEE(pc) {
        let http = new Http();
        let consum = await http.get(`/webapi/rapoarte/consummediuzilnicEE?pc=${pc}`);
        consum = await consum.data;
        this.consumMediuZilnicEE = parseFloat(consum[1].ConsumMediuAnual_PunctConsumEE).toFixed(2);
      },

      async medieConsumGN(pc) {
        let http = new Http();
        let consum = await http.get(`/webapi/rapoarte/consummediuzilnicGN?pc=${pc}`);
        consum = await consum.data;

        this.consumMediuZilnicGN = parseFloat(consum[1].ConsumMediuAnual_PunctConsumGN).toFixed(2);
      }

},
    created() {
      this.updateEE();
      this.updateGN();

      this.eoptions.title.text = 'Consum energie electrica ' + String(this.currentYear) + ' comparat cu ' + String(this.currentYear - 1);
      this.goptions.title.text = 'Consum gaze naturale ' + String(this.currentYear) + ' comparat cu ' + String(this.currentYear - 1);
    },
    components: {
        apexchart: VueApexCharts,
    }
}
</script>

<style scoped>
</style>
