<template>
    <v-container fluid class="toate">
        <v-row>
          <v-col>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="autocitiri"
              :footer-props="{
                'items-per-page-text' : 'Autocitiri pe pagina',
                showFirstLastPage: true,
              }"
              item-key="name"
              class="elevation-1"
              loading-text="Se incarca..."
              no-results-text="Nu exista inregistrari de afisat"
              no-data-text="Nu exista inregistrari de afisat"
            >

            <template v-slot:[`footer.page-text`]="props">
              {{props.pageStart}}-{{props.pageStop}} din {{props.itemsLength}}
            </template>

            <template v-slot:[`item.IdPunctConsum`]= "{item}">
              <v-img
                  v-if="item.EE == '1'"
                  contain
                  align-center
                  width="35px"
                  height="33px"
                  alt="Energie"
                  :src="require('./../../assets/Energy.svg')"
              />

              <v-img
                  v-if="item.GN == '1'"
                  contain
                  align-center
                  width="35px"
                  height="33px"
                  alt="Gaz"
                  :src="require('./../../assets/Gas.svg')"
              />
            </template>

            <template v-slot:[`item.PentruLuna`]= "{item}">
                <span style="color:#F02D4B; letter-spacing:0px" class="c15-20-dm-sans-medium">{{item.PentruLuna}}</span>
            </template>

            <template v-slot:[`item.NumePunctConsum`]= "{item}">
                <span style="color:#171619; letter-spacing:0px" class="c15-20-dm-sans">{{item.NumePunctConsum}}</span>
            </template>

            <template v-slot:[`item.TotalCantitate`]= "{item}">
                <span style="color:#171619; letter-spacing:0px" class="c15-20-dm-sans">{{parseFloat(item.TotalVolum).toFixed(2)}}
                  <span style="color:#171619; letter-spacing:0px" class="c15-20-dm-sans">[mc]</span>
                </span>
            </template>

            <template v-slot:[`item.NrCitiri`]= "{item}">
                <span style="color:#171619; letter-spacing:0px" class="c15-20-dm-sans">{{item.NrCitiri}}</span>
            </template>

            <template v-slot:[`item.action`]= "{item}">
                <indexModal
                  :item=item
                  v-on:edited="edited($event)"
                  v-if="item.InPerioadaAutocitire == '1'
                  && item.AutocitireActiva == '1'
                  && item.DejaCititDeFurnizor == '0'
                  && (item.DataInceputAutocitire != null || item.DataFinalAutocitire != null)"
                >
                    <template v-slot:button>
                      <div>
                        <v-icon x-small color="white" style="border:0px; background-color:#F02D4B;">mdi-pencil-outline</v-icon>
                        <span style="text-transform:none !important; color: #F02D4B" class="ml-1">Modifica</span>
                      </div>
                      <!-- <div v-else>
                        <v-icon small style="color:black;" >mdi-lock</v-icon>
                      </div> -->
                    </template>
                </indexModal>
                <div v-else class="text-center">
                  <v-icon small style="color:black;">mdi-lock</v-icon>
                </div>

                <!-- <v-btn x-small text class="pay-btn" @click.stop="statusChange(item.id)">
                <span>Modifica</span>
                </v-btn> -->
            </template>
            </v-data-table>
          </v-col>
        </v-row>
    </v-container>
</template>

<script>


import indexModal from './../modals/autocitire/Index';
import validate from "../../common/js/validate.js";
import Http from "../../common/js/Http.js";
import Helper from "../../common/js/Helper.js";

export default {
    name: 'ToateAutocitirile',
    mixins: [validate],
    data: () => ({
      helper: Helper,
      autocitiri:[],
      autocitiriEE:[],
      autocitiriGN:[],
      items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      selected: [],
      headers: [
        {
          text: 'Tip',
          align: 'start',
          sortable: false,
          value: 'IdPunctConsum',
          width: '50px',
        },
        { text: 'Data', value: 'PentruLuna' },
        { text: 'Loc de consum', value: 'NumePunctConsum' },
        { text: 'Consum total', value: 'TotalCantitate' },
        { text: 'Autocitiri trimise', value: 'NrCitiri' },
        {
          text: '',
          value: 'action',
          sortable: false,
        },
      ],
    }),

  methods:{
    async edited(status) {
      if(status) {
        this.autocitiri = [];
        this.sautocitiriEE=[];
        this.autocitiriGN=[];
        await this.getAll();
        //this.sendAutocitiriToParent();
      }
    },
    async getAll() {
      let http = new Http();
      let autocitiri = await http.get(`/webapi/autocitire/all?module=2`);
      autocitiri = await autocitiri.data;
      autocitiri.forEach(elem => {
        if(elem.EE == '1') {
          this.autocitiriEE.push(elem)
        }
        if(elem.GN == '1') {
          this.autocitiriGN.push(elem)
        }
        this.autocitiri.push(elem);
      });
    },

    sendAutocitiriToParent() {
      this.$emit('autocitiriFromToate', this.autocitiriEE, this.autocitiriGN)
    }

  },

  async created() {
    await this.getAll();
    await this.sendAutocitiriToParent();
  },

components: {
     indexModal
  }
}
</script>

<style scoped>
.toate {
  padding: 0px;
  margin: 0px;
  background: #FFFCF9 0% 0% no-repeat padding-box !important;
}
.pay-btn {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-15)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0-3);
    color: var(--nova-red);
    font: normal normal normal 15px/20px DM Sans;
    letter-spacing: 0.3px;
    color: #F02D4B;
}
</style>
