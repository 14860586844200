<template>
    <v-container fluid class="prezentare align-center">
        <v-row>
            <v-col>
                <h3 class="c22-20-dm-sans">Consumuri orare</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2">
            <!-- <v-select class="no-border-select" dense filled :items="months" v-model="selectedMonth" label="Luna"></v-select> -->
            <v-menu v-model="monthsMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="selectedMonth" label="Luna" readonly v-bind="attrs" v-on="on" dense filled class="no-border-select"></v-text-field>
                </template>
                <v-date-picker :show-current="true" :locale="locale" type="month" color="red lighten-1" v-model="selectedMonthDP" @input="monthsMenu = false"></v-date-picker>
            </v-menu>
            </v-col>
            <v-col cols="2">
            <v-select class="no-border-select" dense filled v-model="consum" label="Consum"></v-select>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
            <v-col>
                <pre class="c16-20-dm-sans-medium">0 inregistrari</pre>
            </v-col>
          <v-col class="text-right">
            <v-btn small style="background: #FF5F7824" v-on:click="exportFile">
                <v-icon color="#F02D4B">mdi-text-box-check</v-icon>
                <span class="details"><b>Export</b></span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="consumuri" no-gutters justify="space-between" class="pr-8">
            <v-col cols="auto" v-for="h in header" :key="h"><p class="s-col">{{h}}</p></v-col>
        </v-row>
        <!-- <v-row :class="'row-'+((index%2) + 1)" v-for="(c, index) in consumuri" :key="c.Ibd" justify="space-between" class="mr-8" no-gutters> -->

        <v-row v-for="(c) in consumuri" :key="c.Ibd" justify="space-between" class="pr-10 mb-2 bubble" no-gutters>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.Ibd}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z1.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z2.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z3.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z4.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z5.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z6.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z7.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z8.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z9.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z10.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z11.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z12.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z13.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z14.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z15.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z16.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z17.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z18.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z19.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z20.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z22.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z23.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z24.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z25.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z26.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z27.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z28.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z29.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z30.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto"><p class="s-col">{{c.z31.replace('.0','0.0')}}</p></v-col>
            <v-col v-if="c.Ibd" cols="auto">
                <!-- <v-divider style="" vertical></v-divider> -->
                <p v-if="c.Ibd" class="s-col">{{c.Total.replace('.','0.')}}</p>
            </v-col>
        </v-row>
        <v-alert type="error" text v-if="errorMessage != ''">
        <span class="c15-20-s-dm-sans">{{errorMessage}}</span>
        </v-alert>
    </v-container>
</template>

<script>
// @ is an alias to /src
import validate from "../../common/js/validate.js";
import Http from "../../common/js/Http.js";
import Helper from "../../common/js/Helper.js";
import globals from "../../common/js/globals.js";

export default {
    mixins: [validate, globals],
    props:['pcId'],
    name: 'Prezentare',
    data() {
    return {
        days:['Du', 'Lu', 'Ma', 'Mi', 'Jo', 'Vi', 'Sa'],
        header:[],
        globals,
        pc:{},
        relations:null,
        consumuri:null,
        consum:'',
        monthsMenu: false,
        // months: 0,
        selectedMonth:'',
        selectedMonthDP:'',
        errorMessage: ''
    }
    },
    watch: {
        async pcId() {
            if(this.selectedMonth) {
                let [month, year] = this.selectedMonth.split('.');
                await this.getConsumOrar(year, month);
                await this.getMonthDays(year, month);
                this.getPunctConsum();
            }
        },
        async selectedMonthDP(to) {
            let [year, month] = to.split('-');
            this.selectedMonth = month + '.' + year;
            if(this.pcId) {
                await this.getConsumOrar(year, month);
                await this.getMonthDays(year, month);
                this.getPunctConsum();
            }
        }
    },

    methods: {
        async getPunctConsum() {
            let http = new Http();
            let pc = null;
            pc = await http.get(`/webapi/locuriconsum/locconsum?id=${this.pcId}`);
            this.pc = await pc.data.data.pc;
            this.relations = await pc.data.data.relations;
            this.errorMessage = '';
        },
        async getConsumOrar(year, month) {
            let http = new Http();
            this.consumuri = await http.get(`/webapi/locuriconsum/consumuriorare?pc=${this.pcId}&year=${year}&month=${month}`);
            this.consumuri = await this.consumuri.data.data.consumuri;
            this.errorMessage = '';
        },

        exportFile(ev) {
            ev.preventDefault();
            if (this.pcId && this.selectedMonth) {
                let [month, year] = this.selectedMonth.split('.');
                Helper.downloadXLSX(`/webapi/exports/consumuriOrare?pc=${this.pcId}&year=${year}&month=${month}`, `consumuri_orare_${month}_${year}`);
            } else {
                this.errorMessage = 'Selecteaza un loc de consum si o luna pentru a exporta!';
            }
        }
    },

    created() {
        // this.months = this.getYearMonths();
        //this.getConsumOrar();
//        this.getMonthDays();

//        let year = new Date().getFullYear()
    },
    components: {
    }
}
</script>
<style scoped>
.bubble {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 0px #b5957b1a;
  border-radius: 12px;
}
.prezentare {
    background-color: #FFFCF9;
}
.s-col {
    padding-top:15px;
    width: 0px;
    font: normal normal 700 .7vw DM Sans !important;
    letter-spacing: 0px;
}
</style>
