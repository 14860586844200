<template>
  <div>
    <v-dialog
      v-model="viewModal"
      max-width="700"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn x-small text class="pink-red-btn ma-0 pa-0" v-bind="attrs" v-on="on">
            <slot name="button"></slot>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="pa-md-2">
            <v-row no-gutters>
                <v-col>
                    <span class="pl-3 c15-20-dm-sans-medium"><b>Vizualizeaza {{atype}}</b></span>
                </v-col>
                <v-col class="text-right">
                    <v-btn icon @click.stop="close">
                        <v-icon small>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-start">
            <v-row>
            <v-col><p class="c15-20-dm-sans-medium" style="color:black">Locuri de consum</p></v-col>
            </v-row>
            <div style="border: solid 1px #F2ECE6; border-radius:10px;">
            <v-row v-for="autocitire in validReads" :key="autocitire.IdPunctConsum" class="mb-1">
                <v-col cols="1" align-self="center">
                    <v-img
                        contain
                        class="pl-10"
                        width="24px"
                        height="25px"
                        alt="Punct consum"
                        :src="require('../../../assets/house.svg')"
                    />
                </v-col>
                <v-col>
                    <span class="c15-20-dm-sans-medium" style="color:#000000">{{autocitire.NumePunctConsum}}</span>
                    <br>
                    <span class="c15-20-dm-sans-subtext" v-if="[1,6].includes(Number(autocitire.IdTipConsumator))">Consumator noncasnic</span>
                    <span class="c15-20-dm-sans-subtext" v-if="[2,3].includes(Number(autocitire.IdTipConsumator))">Consumator casnic</span>
                    <pre
                        style="color:black;"
                        v-if="autocitire.AutocitireActiva == '0' && (autocitire.DataInceputAutocitire == null || autocitire.DataFinalAutocitire == null)"
                    ><b>Contractul nu prevede autocitire.</b></pre>
                    <pre
                        style="color:black;"
                        v-if="autocitire.AutocitireActiva == '1'"
                    ><b>Perioada de autocitirie este {{Helper.dateFormatter(autocitire.DataInceputAutocitire)}} - {{Helper.dateFormatter(autocitire.DataFinalAutocitire)}}</b></pre>
                    <pre
                        style="color:black;"
                        v-if="autocitire.AutocitireActiva == '1' && autocitire.InPerioadaAutocitire == '0'"
                    ><b>Nu se pot introduce inca autocitiri pentru acest punct de consum.</b></pre>
                    <pre
                        style="color:black; overflow-wrap:anywhere;"
                        v-if="autocitire.NrCitiri.split(' ')[2] == '0'"
                    ><b>Operatorul de distributie transmite datele locului<br>de consum in luna urmatoare lunii de consum.<br>Va asiguram de faptul ca de luna viitoare veti avea<br>posibilitatea introducerii indexulul autocitit.<br>Va multumim pentru intelegere!</b></pre>
                </v-col>
                <div v-if="validReads[validReads.length-1].IdPunctConsum != autocitire.IdPunctConsum" style="height:2px; width:90%; margin: 0 auto; background-color:#F2ECE6;"></div>
            </v-row>
            </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            class="ml-3 close-text"
            text
            @click="close"
          >
            Inchide
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Helper from "./../../../common/js/Helper.js";
export default {
    name: 'veziAutocitire',
    props: {
      autocitiri : {
          type: Array,
          default: null
      },
      atype: String
    },
    data () {
        return {
            Helper,
            validReads : [],
            viewModal: false
        }
    },

    methods: {
        close() {
            this.viewModal = false;
        },
        setAutocitiri() {
            this.validReads = [];
            if (this.autocitiri.length) {
                let autocitiriTips = [];
                this.autocitiri.forEach(elem => {
                    if (
                        elem.IdPunctConsum
                        && !autocitiriTips.includes(elem.IdPunctConsum)
                        && elem.InPerioadaAutocitire != null
                        && elem.AutocitireActiva != null
                    ) {
                        autocitiriTips.push(elem.IdPunctConsum);
                        this.validReads.push(elem);
                    }
                });
                this.validReads.sort((a, b) => (a.NumePunctConsum > b.NumePunctConsum) ? 1 : -1);
            }
        }
    },

    watch: {
        viewModal(status) {
            if (status) {
                this.setAutocitiri();
            }
        }
    },

    components: {
    }
}
</script>
<style scoped>
.close-text {
  color: #F02D4B;
  font-weight: bold;
  text-transform: none;
}

.pink-red-btn {
    font: normal normal normal 15px/20px DM Sans;
    letter-spacing: 0.3px;
    color: #F02D4B;
}
</style>
