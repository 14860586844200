var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"procese align-center",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{staticClass:"no-border-select",attrs:{"dense":"","filled":"","items":_vm.tipfurnizare,"label":"Tip furnizare"},model:{value:(_vm.furnizare),callback:function ($$v) {_vm.furnizare=$$v},expression:"furnizare"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-select',{staticClass:"no-border-select",attrs:{"dense":"","filled":"","items":this.ani,"label":"An"},model:{value:(_vm.an),callback:function ($$v) {_vm.an=$$v},expression:"an"}})],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.pvs,"loading":_vm.loading,"footer-props":{
        'items-per-page-text' : 'Procese verbale pe pagina',
        showFirstLastPage: true,
        },"item-key":"name","loading-text":"Se incarca...","no-results-text":"Nu exista inregistrari de afisat","no-data-text":"Nu exista inregistrari de afisat"},scopedSlots:_vm._u([{key:"item.TipContract",fn:function(ref){
        var item = ref.item;
return [(item.TipContract == 'EE')?_c('v-img',{attrs:{"contain":"","align-center":"","width":"35px","height":"33px","alt":"Energie","src":require('./../../assets/Energy.svg')}}):_vm._e(),(item.TipContract == 'GN')?_c('v-img',{attrs:{"contain":"","align-center":"","width":"35px","height":"33px","alt":"Gaz","src":require('./../../assets/Gas.svg')}}):_vm._e()]}},{key:"item.An",fn:function(ref){
        var item = ref.item;
return [_c('pvModal',{attrs:{"item":item},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('div',{staticClass:"text-left"},[_c('pre',{staticClass:"c16-20-dm-sans-medium",staticStyle:{"color":"#F02D4B"}},[_vm._v(_vm._s(item.LunaNume)+" "+_vm._s(item.An))]),(item.TipContract == 'EE' && item.CantitateFacturata)?_c('pre',{staticClass:"c13-14-dm-sans-subtext"},[_vm._v(_vm._s(item.CantitateFacturata)+" energie facturata")]):_vm._e(),(item.TipContract == 'GN' && item.CantitateFacturata)?_c('pre',{staticClass:"c13-14-dm-sans-subtext"},[_vm._v(_vm._s(item.CantitateFacturata)+" gaz facturat")]):_vm._e()])]},proxy:true}],null,true)})]}},{key:"item.download",fn:function(ref){
        var item = ref.item;
return [(item.idDoc && item.AdresaWebDocument)?_c('v-btn',{staticClass:"no-text-transform",attrs:{"contain":"","text":""},on:{"click":function($event){return _vm.exportFile(item.idDoc, item.AdresaWebDocument)}}},[_c('v-icon',{attrs:{"color":"#F02D4B"}},[_vm._v("mdi-file-pdf")]),_c('span',{staticClass:"c15-20-dm-sans",staticStyle:{"color":"#F02D4B"}},[_c('b',[_vm._v("Descarca")])])],1):_c('pvModal',{attrs:{"item":item},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"no-text-transform text-right",attrs:{"contain":"","text":""}},[_c('v-icon',{attrs:{"color":"#F02D4B"}},[_vm._v("mdi-file-pdf")]),_c('span',{staticClass:"c15-20-dm-sans",staticStyle:{"color":"#F02D4B"}},[_c('b',[_vm._v("Descarca")])])],1)],1)]},proxy:true}],null,true)})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }