<template>
  <div class="text-center">
    <v-dialog
      width="62%"
      v-model="modalBill"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="text-left">
          <slot name="button"></slot>
        </div>
      </template>
      <v-card>
        <v-container fluid style="padding:0;">
          <v-card-title class="pa-md-2">
            <v-row no-gutters>
              <v-col>
                <span style="float:left; margin-top:6px">
                <v-img
                  v-if="bill.PrescurtareTipContract == 'EE'"
                  contain
                  align-center
                  width="25px"
                  height="20px"
                  alt="Energie"
                  :src="require('./../../assets/Energy.svg')"
                />
                <v-img
                  v-if="bill.PrescurtareTipContract == 'GN'"
                  contain
                  align-center
                  width="25px"
                  height="20px"
                  alt="Gaz"
                  :src="require('./../../assets/Gas.svg')"
                />
                </span>
                <span class="pl-3 c15-20-dm-sans-medium"><b>Detalii factura {{titleType}}</b></span>
              </v-col>
              <v-col class="text-right">
                <v-btn icon @click.stop="hide">
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col xs="12" sm="8">
              <pre class="c14-20-dm-sans-medium" style="margin-bottom:3px">Factura {{bill.SerieNumar}}</pre>
              <pre class="c16-20-s-dm-sans-medium" style="margin-bottom:3px">{{bill.EnitateFactura}}</pre>
              <pre class="c14-20-dm-sans-subtext" style="margin-bottom:5px">{{address}}</pre>
              <pre class="c14-20-dm-sans">Data emitere: {{bill.DataEmitere}}</pre>
              <pre class="c14-20-dm-sans">Scadent: {{bill.DataScadenta}}</pre>
            </v-col>
            <v-col xs="12" sm="4" align="right">
              <v-btn
                v-if="bill.idDoc && bill.AdresaWebDocument"
                contain
                text
                class="no-text-transform download-btn"
                v-on:click="exportFile(bill.idDoc, bill.AdresaWebDocument)"
              >
                <v-icon color="#F02D4B">mdi-file-pdf</v-icon>
                <span class="c15-20-dm-sans"><b>Descarca factura</b></span>
              </v-btn>
              <div v-else class="text-left ml-12">
                <span class="c15-20-dm-sans-medium close-text">Factura nu este inca disponibila spre descarcare. Va rugam sa reveniti!</span>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col>
              <pre class="c16-20-dm-sans"><strong>{{helper.formatPrice(bill.ValoareCuTVA)}} {{bill.DenumireMoneda}}</strong></pre>
              <pre class="c13-14-dm-sans-subtext">Valoare</pre>
            </v-col>
            <v-col>
              <pre class="c16-20-dm-sans"><strong>{{helper.formatPrice(bill.ValoareCuTVA - bill.Sold)}} {{bill.DenumireMoneda}}</strong></pre>
              <pre class="c13-14-dm-sans-subtext">Incasat</pre>
            </v-col>
            <v-col>
              <pre class="c16-20-dm-sans"><strong>{{helper.formatPrice(bill.Sold)}} {{bill.DenumireMoneda}}</strong></pre>
              <pre class="c13-14-dm-sans-subtext">Sold factura</pre>
            </v-col>
            <v-col>
              <pre class="c16-20-dm-sans"><strong>{{bill.TipStareFactura}}</strong></pre>
              <pre class="c13-14-dm-sans-subtext">Status</pre>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <platesteModal
            :user="user"
            :unformattedTotal="parseFloat(bill.ValoareCuTVA)"
            :total="new Intl.NumberFormat('ro-RO', { style: 'currency', currency: 'RON' }).format(parseFloat(bill.ValoareCuTVA))"
            :ids="[bill.iDfg]"
            :btnClass="'pay-btn'"
            v-on:hidePlateste="hidePlateste($event);hide()"
            v-if="bill.Sold > 0"
          >
            <template v-slot:button>
              Continua spre plata
            </template>
          </platesteModal>
          <v-btn class="ml-3 close-text" text @click.stop="hide">
          Inchide
          </v-btn>
          <v-spacer></v-spacer>
          <v-img
              alt="Vuetify Logo"
              contain
              :src="require('./../../assets/euplatesc_banner.png')"
              transition="scale-transition"
              width="79"
          />
        </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Http from "./../../common/js/Http";
import Helper from "./../../common/js/Helper";
import platesteModal from "./Plateste";

export default {
  name: 'Bill',
  props: ['bill', 'user'],
  data() {
    return {
      titleType: '',
      modalBill: false,
      helper: Helper,
      address: ''
    }
  },
  components: {
    platesteModal
  },
  created() {
  },
  watch: {
    modalBill(status) {
      if (status) {
        switch (this.bill.PrescurtareTipContract) {
          case 'EE':
            this.titleType = 'energie electrica';
            break;
          case 'GN':
            this.titleType = 'gaze naturale';
            break;
        }
        this.getAddress();
      }
    }
  },
  methods: {
    hide() {
      this.modalBill = false;
    },
    hidePlateste(status) {
      return status;
    },
    exportFile(id, url) {
      Helper.downloadFile(`/webapi/exports/webDoc?id=${id}`, Helper.basename(url));
    },
    async getAddress() {
      let http = new Http();
      let res = await http.get(`/webapi/address/getAddressesByEntityAndType?entity_id=${this.bill.IdEntitate}&entity_type_id=${this.bill.IdTipEntitate}`);
      if (Array.isArray(res.data) && res.data.length) {
        const addr = res.data[0];
        let address = '';
        if (addr.Judet) {
          address += addr.Judet + ', ';
        }
        if (addr.Localitate) {
          address += addr.Localitate + ', ';
        }
        if (addr.Strada) {
          address += addr.Strada;
        }
        if (addr.Numar) {
          address += ' Nr. ' + addr.Numar;
        }
        if (addr.Bloc) {
          address += ', Bloc ' + addr.Bloc;
        }
        if (addr.Scara) {
          address += ', Scara ' + addr.Scara;
        }
        if (addr.Apartament) {
          address += ', Ap. ' + addr.Apartament;
        }
        if (addr.CodPostal) {
          address += ' Cod Postal: ' + addr.CodPostal;
        }
        this.address = address;
      }
    }
  }
}
</script>

<style scoped>
.close-text {
  color: #F02D4B;
  font-weight: bold;
  text-transform: none;
}
.x-header {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-15)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--base-typeface-color);
  font: normal normal normal 18px/20px DM Sans;
  letter-spacing: 0px;
  color: #171619;
  opacity: 0.91;
  background-color: transparent !important;
  border: 0 !important;
  padding: 0;
  margin: 0;
  box-shadow: none;
}
.pay-btn {
  color: white;
  text-transform:none !important;
  background: var(--green-primary) 0% 0% no-repeat padding-box !important;
  background: #2DB7A4 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
}
.download-btn {
  color: #F02D4B;
  background-color: #FF5F7824
}
</style>
