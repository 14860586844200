<template>
    <v-container fluid align-start class="istoric">
        <v-row>
            <v-col>
                <h2>{{totals}} inregistrari</h2>
            </v-col>
            <v-col class="text-right">
                <v-btn small style="background: #FF5F7824" v-on:click="exportFile">
                    <v-icon color="#F02D4B">mdi-text-box-check</v-icon>
                    <span class="details"><b>Export</b></span>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                    v-model="selected"
                    :loading="loading"
                    :headers="headers"
                    :items="transactions"
                    :footer-props="{
                    'items-per-page-text' : 'Inregistrari pe pagina',
                    showFirstLastPage: true,
                    }"
                    item-key="name"
                    class="elevation-1"
                    loading-text="Se incarca..."
                    no-results-text="Nu exista inregistrari de afisat"
                    no-data-text="Nu exista inregistrari de afisat"
                >

                <template v-slot:[`footer.page-text`]="props">
                    {{props.pageStart}}-{{props.pageStop}} din {{props.itemsLength}}
                </template>

                <template v-slot:[`item.Id`]= "{item}">
                  <span style="color:#171619; letter-spacing:0px" class="c15-20-dm-sans">{{item.Id}}</span>
                </template>

                <template v-slot:[`item.NumarDocument`]= "{item}">
                  <span style="color:#171619; letter-spacing:0px" class="c15-20-dm-sans">{{item.NumarDocument}}</span>
                </template>

                <template v-slot:[`item.DataDocument`]= "{item}">
                  <span style="color:#171619; letter-spacing:0px" class="c15-20-dm-sans">{{item.DataDocument}}</span>
                </template>

                <template v-slot:[`item.ValoarePlata`]= "{item}">
                  <span style="color:#171619; letter-spacing:0px" class="c15-20-dm-sans">{{item.ValoarePlata}} RON</span>
                </template>

                <template v-slot:[`item.SerieNumar`]= "{item}">
                  <span style="color:#171619; letter-spacing:0px" class="c15-20-dm-sans">{{item.SerieNumar}}</span>
                </template>

                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Http from "../../common/js/Http.js";
import Helper from "../../common/js/Helper.js";

export default {
    name: 'Istoric',
    data: () => ({
      loading :true,
      totals : 0,
      transactions: [],
      selected: [],
      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: true,
          value: 'Id',
        },
        { text: 'Numar tranzactie', value: 'NumarDocument' },
        { text: 'Data', value: 'DataDocument' },
        { text: 'Total plata', value: 'ValoarePlata' },
        { text: 'Facturi achitate', value: 'SerieNumar' },
      ],
      vitems: [
        {
          id: 1,
          nt: '536A7BE9489D811C55B03BB3F61B8210AFE5D9B7',
          date: '12.06.2020',
          tp: '150.84 RON',
          fa: 'NPG 202061407 , NCG 202003366 , NPG 202058706',
        },
        {
          id: 1,
          nt: '536A7BE9489D811C55B03BB3F61B8210AFE5D9B7',
          date: '12.06.2020',
          tp: '150.84 RON',
          fa: 'NPG 202061407 , NCG 202003366 , NPG 202058706',
        },
        {
          id: 1,
          nt: '536A7BE9489D811C55B03BB3F61B8210AFE5D9B7',
          date: '12.06.2020',
          tp: '150.84 RON',
          fa: 'NPG 202061407 , NCG 202003366 , NPG 202058706',
        },
      ]
    }),

  methods:{
    async getTransactions() {
      let http = new Http();
      this.transactions =await http.get(`/webapi/facturi/docbIlls`);
      this.transactions = this.transactions.data.data.transactions;
      this.totals = this.transactions.length;
      this.loading = false;
    },

    exportFile(ev) {
      ev.preventDefault();
      Helper.downloadXLSX(`/webapi/exports/paymentsHistory`, `istoric_plati`);
    }
  },
  async created() {
    this.getTransactions();
  }
}
</script>

<style>
.istoric {
    background: #FFFCF9 0% 0% no-repeat padding-box !important;
}
</style>
