<template>
  <v-app>
    <v-app-bar app color="#F02D4B" dark v-model="$store.getters.getAppBarStatus">
      <Header v-if="$store.getters.getAppBarStatus" :selectedMenuItem="selectedMenuItem" :accountSelectedMenuItem="accountSelected" />
    </v-app-bar>

    <v-main class="main">
      <AppContainer />
    </v-main>

    <!-- <v-footer padless color="#F02D4B" v-if="$store.getters.getAppBarStatus">
      <v-col class="white--text">
        Copyright &copy; 2014 - {{ new Date().getFullYear() }} <a :href="author.link" target="_blank">{{author.name}}</a>. Toate drepturile rezervate.
      </v-col>
    </v-footer> -->
  </v-app>
</template>

<script>
import AppContainer from "./components/AppContainer";
import Header from "./components/Header";
// import {author} from "./../package.json";

export default {
    name: "App",
    components: {
      AppContainer,
      Header
    },
    data() {
      return {
        // author,
        status : true,
        selectedMenuItem: null,
        accountSelected: null
      }
    },
    async created() {
      document.title = 'Nova';
    }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=DM+Sans");
.main {
  padding-top: 55px !important;
}
* {
  font-family: DM sans !important;
}

/* html::-webkit-scrollbar {
  width: 0;
  height: 0;
} */
.theme--dark.v-text-field--filled > .v-input__control > .v-input__slot {
  background: transparent !important;
}

.theme--dark.v-label {
  color: white !important;
}
.custom-select >>> .v-input__control{
  display:none;
}

.no-border-select.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.no-border-select.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}

.c32-20-dm-sans {
  font: normal normal normal 32px/20px DM Sans;
  letter-spacing: -0.64px;
  color: #171619;
}

.c14-20-dm-sans-subtext {
  font: normal normal normal 14px/20px DM Sans !important;
  letter-spacing: 0.28px;
  color: #5D5D5C;
}

.c14-20-dm-sans {
  font: normal normal normal 14px/20px DM Sans !important;
  letter-spacing: 0.28px;
}

.c14-14-dm-sans{
  font: normal normal normal 14px/14px DM Sans;
  letter-spacing: 0px;
}

.c14-14-dm-sans-subtext {
  font: normal normal normal 14px/14px DM Sans;
  letter-spacing: 0px;
  color: #5D5D5C;
}

.c15-24-dm-sans {
  font: normal normal normal 15px/24px DM Sans !important;
  letter-spacing: 0.3px;
}

.c15-24-dm-sans-medium {
  font: normal normal 700 15px/24px DM Sans !important;
  letter-spacing: 0.3px;
}

.c14-19-dm-sans {
  font: normal normal normal 14px/19px DM Sans !important;
  letter-spacing: 0px;
}

.c14-19-dm-sans-medium {
  font: normal normal 700 14px/19px DM Sans !important;
  letter-spacing: 0px;
}

.c14-20-dm-sans-medium {
  font: normal normal 700 14px/20px DM Sans !important;
  letter-spacing: 0px;
}

.c15-20-dm-sans {
  font: normal normal normal 15px/20px DM Sans !important;
  letter-spacing: 0.3px;
}

.c15-20-dm-sans-medium {
  font: normal normal 600 15px/20px DM Sans !important;
  letter-spacing: 0.3px;
}

.c15-20-dm-sans-subtext {
  font: normal normal normal 15px/20px DM Sans !important;
  letter-spacing: 0px;
  color: #5D5D5C;
}

.c16-20-dm-sans-medium {
  font: normal normal 600 16px/20px DM Sans !important;
  letter-spacing: 0.32px;
}

.c16-20-dm-sans {
  font: normal normal normal 16px/20px DM Sans !important;
  letter-spacing: 0.32px;
}

.c16-20-s-dm-sans-medium {
  font: normal normal 600 16px/20px DM Sans !important;
  letter-spacing: 0px;
}


.c22-20-s-44-dm-sans {
  font: normal normal normal 22px/20px DM Sans !important;
  letter-spacing: 0px;
}

.c22-20-dm-sans {
  font: normal normal normal 22px/20px DM Sans !important;
  letter-spacing: 0px;
}

.c20-20-dm-sans {
  font: normal normal normal 20px/20px DM Sans !important;
  letter-spacing: 0px;
}

.c13-14-dm-sans-subtext {
  font: normal normal normal 13px/14px DM Sans !important;
  letter-spacing: 0.32px;
  color: #5D5D5C
}

.c12-14-dm-sans-subtext {
  font: normal normal normal 12px/14px DM Sans !important;
  letter-spacing: 0px;
  color: #5D5D5C
}

.color-red {
  color: #F02D4B;
}

a {
  text-decoration: none;
  color: white !important;
}

.no-text-transform {
  text-transform:none !important;
}

.v-application--wrap {
  /* background: #FAEBD789 0% 0% no-repeat padding-box !important; */
  background: #FFFCF9 0% 0% no-repeat padding-box !important;
}
/* .main {
    background: var(--unnamed-color-faebd789) 0% 0% no-repeat padding-box !important;
    background: #FAEBD789 0% 0% no-repeat padding-box !important;
} */

/* Loader */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid #F02D4B;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #F02D4B transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
<style lang="postcss">
.no-border-select, .form-select {
  font-weight: bold;
}
.mdi-checkbox-blank-outline, .mdi-checkbox-marked {
  color: rgba(0,0,0,1) !important;
}
.v-text-field--outlined fieldset {
  border-color: #F2ECE6 !important;
  border-width: 2px !important;
}
.v-sheet.v-card:not(.v-sheet--outlined).v-card-custom {
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 8px #B5957B1A !important;
  border-radius: 12px !important;
  opacity: 1 !important;
}
</style>
