<template>
  <div>
    <v-dialog
      persistent
      width="35%"
      v-model="modalBillSendOptions"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">
          <slot name="button"></slot>
        </v-btn>
      </template>
      <v-card>
        <v-container fluid style="padding:0;">
          <v-card-title class="pa-md-2">
            <v-row no-gutters>
              <v-col>
                <span class="pl-3 c15-20-dm-sans-medium"><b>Optiuni de transmitere a facturii {{item.NrContract}}</b></span>
              </v-col>
              <v-col class="text-right">
                <v-btn icon @click.stop="close">
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-alert type="success" text v-if="successMessage"><span class="c15-20-s-dm-sans">{{successMessage}}</span></v-alert>
            <v-alert type="error" text v-if="errorMessage"><span class="c15-20-s-dm-sans">{{errorMessage}}</span></v-alert>
            <v-radio-group v-model="optionsGroup">
              <v-radio
                v-for="(v, k) in options"
                :key="k"
                :label="`${v}`"
                :value="k"
                color="red"
              ></v-radio>
            </v-radio-group>
            <v-checkbox
              v-model="isOK"
              v-if="Object.keys(options).length > 0"
              :label="`Accept conditii de transmitere a facturii`"
            ></v-checkbox>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn class="ml-3 green-btn" v-on:click="save">
              Salveaza
            </v-btn>
            <v-btn class="ml-3 close-text" text v-on:click.stop="close">
              Inchide
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import globals from "./../../common/js/globals";
import Http from "./../../common/js/Http";

export default {
  name: 'BillSendOptions',
  props: ['item'],
  mixins: [globals],
  data() {
    return {
      modalBillSendOptions: false,
      isOK: false,
      optionsGroup: 1,
      options: {},
      successMessage : '',
      errorMessage : ''
    }
  },
  created() {
  },
  watch: {
    modalBillSendOptions(status) {
      if (status) {
        this.getOptions();
      }
    }
  },
  methods: {
    close() {
      this.isOK = false;
      this.modalBillSendOptions = false;
    },
    save() {
      this.successMessage = '';
      this.errorMessage = '';
      if (this.isOK) {
        let formData = new FormData();
        formData.append('id', this.item.IdContract);
        formData.append('type', this.optionsGroup);
        (new Http()).post(`/webapi/facturi/saveTipTransmitereFactura`, formData).then(response => {
          if (response.data.success) {
            this.successMessage = response.data.success.message;
            this.item.IdTipTransmitereFact = this.optionsGroup;
            this.item.Denumire = this.options[this.optionsGroup];
          }
          if (response.data.error) {
            this.errorMessage = response.data.error.message;
          }
        });
      } else {
        this.errorMessage = 'Nu ai acceptat conditiile de transmitere!';
      }
    },
    async getOptions() {
      let http = new Http();
      let res = await http.get(`/webapi/facturi/tipuriTransmitereFacturi`);
      if (res.data) {
        this.options = await res.data.items;
        this.optionsGroup = this.item.IdTipTransmitereFact;
      }
    }
  }
}
</script>
<style scoped>
.close-text {
  color: #F02D4B;
  font-weight: bold;
  text-transform: none;
}
.green-btn {
  color: white;
  text-transform:none !important;
  background: var(--green-primary) 0% 0% no-repeat padding-box !important;
  background: #2DB7A4 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
}
</style>
