<template>
  <div>
    <v-dialog
      persistent
      width="35%"
      v-model="modalReferral"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <slot name="button"></slot>
        </span>
      </template>
      <v-card>
        <v-container fluid style="padding:0;">
          <v-card-title class="pa-md-2">
            <v-row no-gutters>
              <v-col>
                <span class="pl-3 c15-20-dm-sans-medium"><b>Recomanda Nova</b></span>
              </v-col>
              <v-col class="text-right">
                <v-btn icon @click.stop="close">
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-form ref="form" v-model="valid">
          <v-card-text>
            <v-row no-gutters>
              <v-col align="center" justify="center">
                <v-img
                  align-center
                  contain
                  :src="require('./../../assets/achievement.svg')"
                  transition="scale-transition"
                  width="68"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col align="center">
                <span class="c32-20-dm-sans" style="line-height:36px">Recomandă Nova<br>și câștigi reduceri pe factură</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="center">
                <span class="c15-20-dm-sans">Dacă ești mulțumit de serviciile noastre și vrei să le recomanzi celor apropiați,<br>te recompensăm cu 50 RON pentru fiecare prieten recomandat<br>care semnează un contract cu Nova.</span>
              </v-col>
            </v-row>
            <v-row v-if="code && refLink">
              <v-col>
                <div class="code-box">
                  <span class="text-info">Codul tau de invitatie</span>
                  <h3>{{code}}</h3>
                  <div class="divider"></div>
                  <a :href="refLink" target="_blank" class="link">{{refLink}}</a>
                </div>
              </v-col>
            </v-row>
            <!-- FORM -->
            <v-row v-if="code">
              <v-col>
                <span class="c15-20-dm-sans" style="color:#171619"><strong>Trimite invitatia pe email</strong></span>
              </v-col>
            </v-row>
            <v-alert type="success" text v-if="successMessage"><span class="c15-20-s-dm-sans">{{successMessage}}</span></v-alert>
            <v-alert type="error" text v-if="errorMessage"><span class="c15-20-s-dm-sans">{{errorMessage}}</span></v-alert>
            <v-row no-gutters v-if="code">
              <v-text-field type="text" v-model="name" :rules="[rules.required]" label="Nume" outlined></v-text-field>
            </v-row>
            <v-row no-gutters v-if="code">
              <v-text-field type="text" v-model="email" :rules="[rules.required, rules.email]" label="Email" outlined></v-text-field>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn class="ml-3 green-btn" v-if="code" v-on:click="sendInvitation">
              Trimite invitatia
            </v-btn>
            <v-btn class="ml-3 close-text" text @click.stop="close">
              Inchide
            </v-btn>
          </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import globals from "./../../common/js/globals";
import Http from "./../../common/js/Http";
import Helper from "./../../common/js/Helper";

export default {
  name: 'ReferralModal',
  props: ['info'],
  mixins: [globals],
  data() {
    return {
      modalReferral: false,
      code: null,
      type: 0,
      refLink: '',
      successMessage: '',
      errorMessage: '',
      valid: true,
      name: '',
      email: '',
    }
  },
  created() {
  },
  watch: {
    modalReferral(status) {
      if (status) {
        if (!Helper.empty(this.info)) {
          this.code = this.info.code;
          this.type = this.info.type;
          this.refLink = this.info.refLink;
        }
      }
    }
  },
  methods: {
    close() {
      this.successMessage = '';
      this.errorMessage = '';
      this.modalReferral = false;
    },
    sendInvitation() {
      this.successMessage = '';
      this.errorMessage = '';
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append('name', this.name);
        formData.append('email', this.email);
        formData.append('code', this.code);
        formData.append('type', this.type);
        formData.append('link', this.refLink);
        (new Http()).post(`/webapi/referral/sendInvitation`, formData).then(response => {
          if (response.data.success) {
            this.successMessage = response.data.success.message;
          }
          if (response.data.error) {
            this.errorMessage = response.data.error.message;
          }
          this.name = '';
          this.email = '';
          this.$refs.form.resetValidation();
        });
      }
    }
  },
  components: {
  }
}
</script>
<style scoped>
.close-text {
  color: #F02D4B;
  font-weight: bold;
  text-transform: none;
}
.code-box {
  background: #F0EBE5;
  border-radius: 5px;
  padding: 12px 0;
  text-align: center;
}
.code-box .text-info {
  color: #5D5D5C;
}
.code-box h3 {
  padding: 5px;
  font-size: 2em;
  margin: 0;
}
.code-box .divider {
  height: 1px;
  background-color: #707070;
  opacity: 0.09;
  margin-top: 12px;
  margin-bottom: 12px;
}
.code-box a.link {
  color: #F02D4B !important;
  text-decoration: none;
}
.green-btn {
  color: white;
  text-transform:none !important;
  background: var(--green-primary) 0% 0% no-repeat padding-box !important;
  background: #2DB7A4 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
}
</style>
