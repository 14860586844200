import axios from "axios";

export default {
  data() {
    return {
      /**
       * this.user = currently logged user
       * this.user.SessionId = use this to check if user is master or not
       */

      user : this.$store.getters.getUser,
      appBarStatus : true,
    }
  },
  async beforeCreate() {
    if (this.$route.name !== 'ResetPassword') {
      // Redirect to mobile store if mobile device
      const ua = navigator.userAgent;
      if (ua.indexOf('Mobile') !== -1) {
        const patternApple = /(iphone|ipod|ipad)/i;
        const patternAndroid = /android/i;
        if (patternApple.test(ua)) {
          window.location = 'https://apps.apple.com/ro/app/nova-power-gas/id1536392440';
        }
        if (patternAndroid.test(ua)) {
          window.location = 'https://play.google.com/store/apps/details?id=com.nova.mobile&hl=ro&gl=US';
        }
      }
    }

    if(this.$route.query.token) {
      await localStorage.removeItem('masterSessions');
      await localStorage.setItem("accessToken", this.$route.query.token);
      this.$router.push({ name: this.$route.name });
    }

    // No route name
    if (this.$route.name === null) {
      this.$router.push({ name: 'Error 404' });
    }

    const allowedRoutes = ['Login', 'RecoverPassword', 'ResetPassword'];
    if (localStorage.getItem("accessToken") !== null) {
      // If user is already logged go directly to Dashboard
      if (allowedRoutes.indexOf(this.$route.name) !== -1) {
        this.$router.push({ name: 'Dashboard' });
      }

      let response = await axios
        .get(process.env.VUE_APP_BACKEND + "/webapi/default/validate", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        })
        .catch((error) => {
          if (error.response.status) this.$router.push({name: 'Login'});
        });

      /**
       * Optimization here
       * Condition needed here for when to update user mutation, getUser not relevant since swapping accounts
      */
//      if(!this.$store.getters.getUser) {
        this.$store.commit('setUser', response.data)
//      }
      await this.$store.commit('setAppBarStatus', true);
      this.appBarStatus = await this.$store.getters.getAppBarStatus;
      this.user = await this.$store.getters.getUser;
    } else {
      await this.$store.commit('setAppBarStatus', false);
      // You cand access without Token
      if (allowedRoutes.indexOf(this.$route.name) === -1) {
        this.$router.push({ name: 'Login' });
      }
      // window.location = process.env.VUE_APP_BACKEND + "/site/login";
    }
  },
};
