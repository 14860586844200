var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"istoric",attrs:{"fluid":"","align-start":""}},[_c('v-row',[_c('v-col',[_c('h2',[_vm._v(_vm._s(_vm.totals)+" inregistrari")])]),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticStyle:{"background":"#FF5F7824"},attrs:{"small":""},on:{"click":_vm.exportFile}},[_c('v-icon',{attrs:{"color":"#F02D4B"}},[_vm._v("mdi-text-box-check")]),_c('span',{staticClass:"details"},[_c('b',[_vm._v("Export")])])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.transactions,"footer-props":{
                'items-per-page-text' : 'Inregistrari pe pagina',
                showFirstLastPage: true,
                },"item-key":"name","loading-text":"Se incarca...","no-results-text":"Nu exista inregistrari de afisat","no-data-text":"Nu exista inregistrari de afisat"},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" din "+_vm._s(props.itemsLength)+" ")]}},{key:"item.Id",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"c15-20-dm-sans",staticStyle:{"color":"#171619","letter-spacing":"0px"}},[_vm._v(_vm._s(item.Id))])]}},{key:"item.NumarDocument",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"c15-20-dm-sans",staticStyle:{"color":"#171619","letter-spacing":"0px"}},[_vm._v(_vm._s(item.NumarDocument))])]}},{key:"item.DataDocument",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"c15-20-dm-sans",staticStyle:{"color":"#171619","letter-spacing":"0px"}},[_vm._v(_vm._s(item.DataDocument))])]}},{key:"item.ValoarePlata",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"c15-20-dm-sans",staticStyle:{"color":"#171619","letter-spacing":"0px"}},[_vm._v(_vm._s(item.ValoarePlata)+" RON")])]}},{key:"item.SerieNumar",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"c15-20-dm-sans",staticStyle:{"color":"#171619","letter-spacing":"0px"}},[_vm._v(_vm._s(item.SerieNumar))])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }