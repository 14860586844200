<template>
    <v-container fluid class="locuriconsum align-center">
        <v-row>
          <v-col>
            <h1 class="c32-20-dm-sans">Locuri de consum</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-select :items="items" v-model="selectedItem" label="Tip furnizare" class="no-border-select" filled></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col><h2 class="c22-20-dm-sans">{{itemCount}} inregistrari</h2></v-col>
          <v-col class="text-right">
            <v-btn small style="background: #FF5F7824" v-on:click="exportFile">
              <v-icon color="#F02D4B">mdi-text-box-check</v-icon>
              <span class="details"><b>Export</b></span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xs="12" sm="6" lg="3" v-for="col in lc" :key="col.IdPunctConsum">
            <router-link :to="{name: 'punctconsum', params: {id:col.IdPunctConsum, name:col.NumePunctConsum}}">
            <!-- <router-link :to="`/punctconsum/${col.IdPunctConsum}`"> -->
            <v-card class="pc v-card-custom">
                <v-row no-gutters>
                    <v-col>
                      <span class="c20-20-dm-sans" style="color:#F02D4B"><b>{{col.NumePunctConsum.charAt(0).toUpperCase() + col.NumePunctConsum.slice(1)}}</b></span>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col><p class="c13-14-dm-sans-subtext mt-1">{{col.DenumireTipConsumator}}</p></v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col style="min-height:40px">
                      <span class="c14-20-dm-sans">{{col.Adresa}}</span>
                    </v-col>
                </v-row>
                <v-row>
                  <v-col></v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="1" style="margin-right:15px" v-if="col.AreEE === '1'">
                    <v-img
                        contain
                        width="35px"
                        height="33px"
                        alt="Energie"
                        :src="require('./../assets/Energy.svg')"
                    />
                  </v-col>
                  <v-col cols="1" style="margin-right:15px" v-if="col.AreGN === '1'">
                    <v-img
                        contain
                        width="35px"
                        height="33px"
                        alt="Gaz"
                        :src="require('./../assets/Gas.svg')"
                    />
                  </v-col>
                </v-row>
            </v-card>
            </router-link>
          </v-col>
        </v-row>
        <v-row v-if="pageCount > 1">
          <v-col>
            <router-link
            v-for="page in pageCount" :key="page" :to="{name: 'locuri', params: {page:page}}">
            <v-btn icon >
              <span class="c15-20-dm-sans" style="color:#171619">{{page}}</span>
            </v-btn>
            </router-link>
          </v-col>
        </v-row>

    </v-container>
</template>

<script>
// @ is an alias to /src
import Http from "../common/js/Http.js";
import Helper from "../common/js/Helper.js";
import validate from "./../common/js/validate.js";

export default {
  name: 'LocuriCosnum',
  mixins: [validate],
  data() {
    return {
      itemsPerPage : 12,
      pageCount: 0,
      lc : [],
      items: ['Toate', 'Energie', 'Gaz' ],
      selectedItem: '',
      page : undefined,
      itemCount : 0,
    }
  },
  watch: {
    selectedItem(to) {
      this.getLocuriConsum(this.page, to)
    },

    $route(to) {
      this.page = to.params.page;
      this.getLocuriConsum(to.params.page);
    }
  },
  methods: {
    async getLocuriConsum(page = undefined, filter = undefined) {
        if(page === undefined)
          page = 1;
        let http = new Http();
        let lc = await http.get(`/webapi/default/locuriConsum?page=${page}&recsPerPage=${this.itemsPerPage}`);
        this.lc = await lc.data.data.locuriConsum;
        if(this.lc) {
          this.pageCount = Math.ceil(this.lc[0]['TotalRecsCount'] / this.itemsPerPage);
        }
        if(filter == 'Energie') {
          this.lc = this.lc.filter(function(value) {
            return value.AreContractEE === '1';
          });
        }
        if(filter == 'Gaz') {
          this.lc = this.lc.filter(function(value) {
            return value.AreContractGN === '1';
          });
        }
        this.itemCount = this.lc.length;
    },

    exportFile(ev) {
      ev.preventDefault();
      Helper.downloadXLSX(`/webapi/exports/locuriConsum`, `locuri_consum`);
    }
  },
  created() {
      this.getLocuriConsum();
      this.$root.$children[0].accountSelected = null;
      this.$root.$children[0].selectedMenuItem = 'lc';
  },
  components: {
  }
}
</script>
<style scoped>
.pc {
  min-height: 200px;
  height: 100%;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--base-typeface-color);
  text-align: left;
  font: normal normal normal 14px/20px DM Sans;
  letter-spacing: 0px;
  color: #171619;
  opacity: 0.88;
  padding: 20px !important;
}
.pc p {
    line-height: 150%;
}
.v-card {
  padding: 10px;
}
.card-title {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-20)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--nova-red);
  text-align: left;
  font: normal normal bold 20px/20px DM Sans;
  letter-spacing: 0px;
  color: #F02D4B;
}

.card-nova {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--base-typeface-color);
  text-align: right;
  font: normal normal bold 14px/20px DM Sans;
  letter-spacing: 0px;
  color: #171619;
}

.card-underline {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-13)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-dm-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--dark-grey);
  text-align: left;
  font: normal normal normal 13px/14px DM Sans;
  letter-spacing: 0px;
  color: #5D5D5C;
}

.card-info {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--base-typeface-color);
  text-align: left;
  font: normal normal normal 14px/20px DM Sans;
  letter-spacing: 0px;
  color: #171619;
}
</style>
