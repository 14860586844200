var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"referral",attrs:{"align-start":"","fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{staticClass:"no-border-select",attrs:{"items":_vm.filterItems,"label":"Status bonus","filled":""},model:{value:(_vm.filterItem),callback:function ($$v) {_vm.filterItem=$$v},expression:"filterItem"}})],1)],1),_c('v-divider'),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1 striped",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"footer-props":{'items-per-page-text': 'Inregistrari pe pagina', showFirstLastPage: true},"loading-text":"Se incarca...","no-results-text":"Nu exista inregistrari de afisat","no-data-text":"Nu exista inregistrari de afisat"},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" din "+_vm._s(props.itemsLength)+" ")]}},{key:"item.NumeRecomandat",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"c15-20-dm-sans",staticStyle:{"color":"#171619","letter-spacing":"0px"}},[_vm._v(_vm._s(item.NumeRecomandat))])]}},{key:"item.SerieNumarFact",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"c15-20-dm-sans",staticStyle:{"color":"#171619","letter-spacing":"0px"}},[_vm._v(_vm._s(item.SerieNumarFact))])]}},{key:"item.DataEmitereFact",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"c15-20-dm-sans",staticStyle:{"color":"#171619","letter-spacing":"0px"}},[_vm._v(_vm._s(item.DataEmitereFact))])]}},{key:"item.StatusBonus",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"c15-20-dm-sans",staticStyle:{"color":"#171619","letter-spacing":"0px"}},[_vm._v(_vm._s(item.StatusBonus))])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }