import Http from "./Http";

export default {
    empty: function (mixedVar) {
        let undef
        let key
        let i
        let len
        const emptyValues = [undef, null, false, 0, '', '0']
        for (i = 0, len = emptyValues.length; i < len; i++) {
          if (mixedVar === emptyValues[i]) {
            return true
          }
        }
        if (typeof mixedVar === 'object') {
          for (key in mixedVar) {
            if (Object.prototype.hasOwnProperty.call(mixedVar, key)) {
              return false
            }
          }
          return true
        }
        return false
    },

    downloadXLSX: function (url, name) {
        (new Http()).get(url, { responseType: 'blob' }).then(response => {
            if (response.data.size) {
                let blob = new Blob([response.data], { type: response.data.type });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = name + '.xlsx';
                link.click();
            } else {
                console.error('Nu sunt date pentru export!');
            }
        });
    },

    downloadFile: function (url, name) {
        (new Http()).get(url, { responseType: 'blob' }).then(response => {
            if (response.data.size) {
                let blob = new Blob([response.data], { type: response.data.type });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = name;
                link.click();
            } else {
                console.error('Nu sunt date pentru export!');
            }
        });
    },

    basename: function (str) {
        return String(str).split('/').reverse()[0];
    },

    formatPrice: function (value) {
        let val = (value/1).toFixed(2);
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    daysBetween2Dates: function(date1, date2) {
        let timeDiff = date2.getTime() - date1.getTime();

        // To calculate the no. of days between two dates
        return timeDiff / (1000 * 3600 * 24);
    },

    monthsBetween2Dates: function(dateFrom, dateTo) {
        return dateTo.getMonth() - dateFrom.getMonth() +
        (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
    },

    formatDate: function (date) {
        let d = (date) ? new Date(date): new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    },

    dateFormatter: function (date) {
        let d = new Date(date),
            day = d.getDate(),
            month = (d.getMonth() + 1),
            year = d.getFullYear();

        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;

        let result = [day, month, year].join('.');
        if (date.split(' ').length == 2) {
            let h = d.getHours(),
                m = d.getMinutes(),
                s = d.getSeconds();
            if (h < 10) h = '0' + h;
            if (m < 10) m = '0' + m;
            if (s < 10) s = '0' + s;
            result += ' ' + [h, m, s].join(':');
        }

        return result;
    },

    leiToRon: function(value) {
        if(value)
            return value.replace('Lei', 'RON');
        else return value;
    },

    wait: async function(ms) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    },

    getYearMonthDayFromDate(date, separator) {
        var dateObj = new Date(date);
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();

        let newdate = year + separator + month + separator + day;
        return newdate;
    },

    listYearsFromCurrent(length, menu=false) {
        let years = [];
        if(menu)
            years.push('Toate');
        let year = new Date().getFullYear();
        for (let index = year; index > (year - length); index--) {
            years.push(index);
        }
        return years;
    },

    arrayAverage: function(array) {
        let total = 0;
        for(let i = 0; i < array.length; i++) {
            total += Number(array[i]);
        }
        return new Intl.NumberFormat().format(total / array.length);
    },

    arrayAveragePositive: function(array) {
        let total = 0;
        let len = 0;
        for (let i = 0; i < array.length; i++) {
            const val = Number(array[i]);
            if (val > 0) {
                total += val;
                len += 1;
            }
        }
        return new Intl.NumberFormat().format(len ? (total / len) : 0);
    },

    getRndInteger: function(min, max) {
        return Math.floor(Math.random() * (max - min) ) + min;
    },

    userNameLetters(name) {
        let parts = name.split(' ');
        let letters = parts[0].substr(0, 1);
        if (parts.length == 2) {
            letters += parts[1].substr(0, 1);
        }

        return letters;
    }
}
