var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"toate",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.autocitiri,"footer-props":{
            'items-per-page-text' : 'Autocitiri pe pagina',
            showFirstLastPage: true,
          },"item-key":"name","loading-text":"Se incarca...","no-results-text":"Nu exista inregistrari de afisat","no-data-text":"Nu exista inregistrari de afisat"},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" din "+_vm._s(props.itemsLength)+" ")]}},{key:"item.IdPunctConsum",fn:function(ref){
          var item = ref.item;
return [(item.EE == '1')?_c('v-img',{attrs:{"contain":"","align-center":"","width":"35px","height":"33px","alt":"Energie","src":require('./../../assets/Energy.svg')}}):_vm._e(),(item.GN == '1')?_c('v-img',{attrs:{"contain":"","align-center":"","width":"35px","height":"33px","alt":"Gaz","src":require('./../../assets/Gas.svg')}}):_vm._e()]}},{key:"item.PentruLuna",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"c15-20-dm-sans-medium",staticStyle:{"color":"#F02D4B","letter-spacing":"0px"}},[_vm._v(_vm._s(item.PentruLuna))])]}},{key:"item.NumePunctConsum",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"c15-20-dm-sans",staticStyle:{"color":"#171619","letter-spacing":"0px"}},[_vm._v(_vm._s(item.NumePunctConsum))])]}},{key:"item.TotalCantitate",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"c15-20-dm-sans",staticStyle:{"color":"#171619","letter-spacing":"0px"}},[_vm._v(_vm._s(parseFloat(item.TotalVolum).toFixed(2))+" "),_c('span',{staticClass:"c15-20-dm-sans",staticStyle:{"color":"#171619","letter-spacing":"0px"}},[_vm._v("[kWh]")])])]}},{key:"item.NrCitiri",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"c15-20-dm-sans",staticStyle:{"color":"#171619","letter-spacing":"0px"}},[_vm._v(_vm._s(item.NrCitiri))])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [(item.InPerioadaAutocitire == '1'
              && item.AutocitireActiva == '1'
              && item.DejaCititDeFurnizor == '0'
              && (item.DataInceputAutocitire != null || item.DataFinalAutocitire != null))?_c('indexModal',{attrs:{"item":item},on:{"edited":function($event){return _vm.edited($event)}},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('div',[_c('v-icon',{staticStyle:{"border":"0px","background-color":"#F02D4B"},attrs:{"x-small":"","color":"white"}},[_vm._v("mdi-pencil-outline")]),_c('span',{staticClass:"ml-1",staticStyle:{"text-transform":"none !important","color":"#F02D4B"}},[_vm._v("Modifica")])],1)]},proxy:true}],null,true)}):_c('div',{staticClass:"text-center"},[_c('v-icon',{staticStyle:{"color":"black"},attrs:{"small":""}},[_vm._v("mdi-lock")])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }