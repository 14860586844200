<template>
  <v-container fluid align-center>
    <div class="ee"></div>
    <div class="gn"></div>
    <div class="login">
      <v-row style="background-color:#FFFCF9  !important;">
        <v-col align="center" justify="center">
          <div class="novabg"></div>
        </v-col>
      </v-row>
      <div class="login-form">
        <v-form ref="resetForm" v-model="resetForm">
          <v-row style="border-radius:50px;">
            <v-col align="center" justify="center">
              <h2 class="c22-20-dm-sans"><b>Resetare parola</b></h2>
            </v-col>
          </v-row>
          <v-row v-if="showForm">
            <v-col align="center" justify="center">
              <v-text-field outlined class="form-select" :type="passIcon1.type" v-model="password" :rules="[rules.required, rules.pass]" label="Parola" tabindex="1">
                <v-icon @click="togglePass(1)" slot="append" color="#F02D4B">{{passIcon1.icon}}</v-icon>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="showForm">
            <v-col align="center" justify="center">
              <v-text-field outlined class="form-select" :type="passIcon2.type" v-model="password2" :rules="[rules.required, rules.pass, rules.cpass]" label="Confirmare parola" tabindex="2">
                <v-icon @click="togglePass(2)" slot="append" color="#F02D4B">{{passIcon2.icon}}</v-icon>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="showForm">
            <v-col align="center" justify="center">
              <v-btn @click="send" class="no-text-transform" width="100%" dark style="background-color:#F02D4B;">
                <span>Salveaza parola</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col align="center" justify="center">
            <router-link to="/login">
            <span class="c16-20-dm-sans-medium" style="color:#F02D4B">Inapoi la autentificare</span>
            </router-link>
          </v-col>
        </v-row>
        <v-row v-if="successMessage">
          <v-col align="center" justify="center" class="text-center">
            <v-alert type="success">{{successMessage}}</v-alert>
          </v-col>
        </v-row>
        <v-row v-if="errorMessage">
          <v-col align="center" justify="center" class="text-center">
            <v-alert type="error">{{errorMessage}}</v-alert>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import validate from "../common/js/validate.js";
import globals from "../common/js/globals.js";
import Http from "../common/js/Http.js";

export default {
  name: 'ResetPassword',
  mixins: [validate, globals],
  data() {
    return {
      resetForm: true,
      showForm: false,
      successMessage: '',
      errorMessage: '',
      hash: '',
      password: '',
      password2: '',
      passIcons: [
        {
          check: true,
          type: 'password',
          icon: 'mdi-eye'
        },
        {
          check: false,
          type: 'text',
          icon: 'mdi-eye-off'
        }
      ],
      passIcon1: {},
      passIcon2: {}
    }
  },
  created() {
    this.passIcon1 = this.passIcon2 = this.passIcons[0];
    this.checkHash();
  },
  methods: {
    togglePass(idx) {
      switch (idx) {
        case 1:
          this.passIcon1 = this.passIcons[Number(this.passIcon1.check)];
          break;
        case 2:
          this.passIcon2 = this.passIcons[Number(this.passIcon2.check)];
          break;
      }
    },
    redirectToLogin() {
      setTimeout(() => {
        this.$router.push({ name: 'Login' });
      }, 5000);
    },
    async checkHash() {
      let params = new URLSearchParams(document.location.search.substring(1));
      this.hash = params.get('hash');
      let http = new Http();
      let res = await http.get(`/webapi/account/checkResetHash?hash=${this.hash}`);
      if (res.data.error) {
        this.errorMessage = res.data.error.message;
        this.redirectToLogin();
      }
      if (res.data.success) {
        this.showForm = true;
      }
    },
    async send() {
      this.successMessage = '';
      this.errorMessage = '';
      if(this.$refs.resetForm.validate()) {
        let formData = new FormData();
        formData.append('newpass', this.password);
        formData.append('retpass', this.password2);
        formData.append('hash', this.hash);
        let http = new Http();
        let postRead = await http.post(`/webapi/account/resetPassword`, formData);
        if (postRead.data.error) {
          this.errorMessage = postRead.data.error.message;
        }
        if (postRead.data.success) {
          this.successMessage = postRead.data.success.message;
          this.redirectToLogin();
        }
        this.password = '';
        this.password2 = '';
        this.$refs.resetForm.resetValidation();
      }
    }
  }
}
</script>
<style scoped>
.terms h1 {
  padding: 0;
  margin-top: 10px;
  margin-bottom: 30px;
}
.novabg {
  background-color: #F02D4B;
  background-repeat: no-repeat;
  width: 156px;
  height: 70px;
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-repeat: no-repeat;
  mask-image: url('./../assets/logo.svg');
}
.ee {
  padding:0;
  margin: 0;
  margin-top:2%;
  background-color: #D1F8D8;
  background-repeat: no-repeat;
  width: 350px;
  float: left;
  height: 350px;
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-repeat: no-repeat;
  mask-image: url('./../assets/Energy.svg');
  transform: rotateY(180deg) rotate(0deg)
}
.gn {
  padding:0;
  margin: 0;
  margin-top:15%;
  background-color: #D1F8D8;
  background-repeat: no-repeat;
  width: 350px;
  height: 350px;
  float: right;
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-repeat: no-repeat;
  mask-image: url('./../assets/Gas.svg');
}
.login {
  margin: auto;
  padding: 10px;
  width: 25%;
}
@media(max-width:767px) {
  .ee, .gn {
    display: none;
  }
  .login {
    width: 100%;
    margin: 100px 0;
  }
}
.login-form {
  background-color: white;
  border-radius: 10px;
  padding: 50px;
  box-shadow: 0 0 60px #dddddd;
}
.v-text-field--outlined >>> fieldset {
  border-color: lightgray;
}
.v-text-field--outlined >>> .v-text-field__slot input {
  color: black;
}
</style>
