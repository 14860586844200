import Vue from "vue";
import VueRouter from "vue-router";
//import Home from '../views/Home.vue'
import Facturi from "../views/Facturi";
import Referral from "../views/Referral";
import Autocitire from "../views/Autocitire";
import LocuriConsum from "../views/LocuriConsum";
import PunctConsum from "../views/PunctConsum";
import Rapoarte from "../views/Rapoarte";
import PlatesteForm from "../views/PlatesteForm";
import Suport from "../views/Suport";
import Login from "../views/Login";
import RecoverPassword from "../views/RecoverPassword";
import ResetPassword from "../views/ResetPassword";
import Dashboard from "../components/Dashboard";
import Account from "../components/Account";
import Contracts from "../components/Contracts";
import Error404 from "../components/Errors/404.vue";
import Error401 from "../components/Errors/401.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: '/recoverpassword',
    name: 'RecoverPassword',
    component: RecoverPassword
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/referral",
    name: "Referral",
    component: Referral
  },
  {
    path: "/facturi",
    name: "Facturi",
    component: Facturi,
  },
  {
    path: "/platesteform",
    name: "Platesteform",
    component: PlatesteForm,
  },
  {
    path: "/autocitire",
    name: "Autocitire",
    component: Autocitire,
  },
  {
    path: "/locuri/:page?",
    name: "locuri",
    component: LocuriConsum,
  },
  {
    path: "/punctconsum/:id/:name",
    name: "punctconsum",
    component: PunctConsum,
  },
  {
    path: "/rapoarte",
    name: "Rapoarte",
    component: Rapoarte,
  },
  {
    path: "/suport",
    name: "Suport",
    component: Suport,
  },
  {
    path: "/termeni",
    name: "Termeni",
    component: () => import('../views/Termeni.vue'),
  },
  {
    path: "/account",
    name: "Contul meu",
    component: Account,
  },
  {
    path: '/contracte',
    name: 'Contracte',
    component: Contracts
  },
  {
    path: "/404",
    name: "Error 404",
    component: Error404,
  },
  {
    path: "/401",
    name: "Error 401",
    component: Error401,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Fix NavigationDuplicated
const originalPush = router.push;
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);

  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err;
  });
};

export default router;
