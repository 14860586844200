<template>
  <v-container align-start fluid class="referral">
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :footer-props="{'items-per-page-text': 'Inregistrari pe pagina', showFirstLastPage: true}"
          class="elevation-1 striped"
          loading-text="Se incarca..."
          no-results-text="Nu exista inregistrari de afisat"
          no-data-text="Nu exista inregistrari de afisat"
        >
          <template v-slot:[`footer.page-text`]="props">
            {{props.pageStart}}-{{props.pageStop}} din {{props.itemsLength}}
          </template>
          <template v-slot:[`item.CodRecomandare`]= "{item}">
            <span style="color:#171619; letter-spacing:0px" class="code-span c15-20-dm-sans-medium">{{item.CodRecomandare}}</span>
          </template>
          <template v-slot:[`item.DataSemnareContract`]= "{item}">
            <span style="color:#171619; letter-spacing:0px" class="c15-20-dm-sans">{{item.DataSemnareContract}}</span>
          </template>
          <template v-slot:[`item.CodBPRecomandat`]= "{item}">
            <span style="color:#171619; letter-spacing:0px" class="c15-20-dm-sans">{{item.CodBPRecomandat}}</span>
          </template>
          <template v-slot:[`item.NumeRecomandator`]= "{item}">
            <span style="color:#171619; letter-spacing:0px" class="c15-20-dm-sans">{{item.NumeRecomandator}}</span>
          </template>
          <template v-slot:[`item.EmailRecomandator`]= "{item}">
            <span style="color:#171619; letter-spacing:0px" class="c15-20-dm-sans">{{item.EmailRecomandator}}</span>
          </template>
          <template v-slot:[`item.SerieNumarFact`]= "{item}">
            <span style="color:#171619; letter-spacing:0px" class="c15-20-dm-sans">{{item.SerieNumarFact}}</span>
          </template>
          <template v-slot:[`item.DataEmitereFact`]= "{item}">
            <span style="color:#171619; letter-spacing:0px" class="c15-20-dm-sans">{{item.DataEmitereFact}}</span>
          </template>
          <template v-slot:[`item.StatusBonus`]= "{item}">
            <span style="color:#171619; letter-spacing:0px" class="c15-20-dm-sans">{{item.StatusBonus}}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Http from './../../common/js/Http';

export default {
  name: 'ReferralReceived',
  data() {
    return {
      loading: true,
      items: []
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'Cod Recomandare',
          value: 'CodRecomandare'
        },
        {
          text: 'Data incheierii contractului',
          value: 'DataSemnareContract'
        },
        {
          text: 'Cod BP recomandat',
          value: 'CodBPRecomandat'
        },
        {
          text: 'Denumire recomandator',
          value: 'NumeRecomandator'
        },
        {
          text: 'Email recomandator',
          value: 'EmailRecomandator'
        },
        {
          text: 'Serie si numar factura',
          value: 'SerieNumarFact'
        },
        {
          text: 'Data emitere factura',
          value: 'DataEmitereFact'
        },
        {
          text: 'Status bonus',
          value: 'StatusBonus'
        }
      ];
    }
  },
  created() {
  },
  async mounted() {
    this.getItems();
  },
  watch: {
  },
  methods: {
    async getItems() {
      let http = new Http();
      let res = await http.get(`/webapi/referral/getReceived`);
      if (res.data.items) {
        this.items = res.data.items;
      }
      this.loading = false;
    }
  },
  components: {
  }
}
</script>
<style scoped>
.referral {
  background: #FFFCF9 0% 0% no-repeat padding-box !important;
}
.code-span {
  background-color: #BCAFA122;
  padding: 5px 10px;
  border-radius: 15px;
}
</style>
