<template>
  <v-container fluid align-center>
    <div class="ee"></div>
    <div class="gn"></div>
    <div class="login">

        <v-row style="background-color:#FFFCF9  !important;">
            <v-col align="center" justify="center">
                <div class="novabg"></div>
            </v-col>
        </v-row>
        <div class="login-form">
                <v-form ref="loginForm" v-model="loginForm">
                    <v-row style="border-radius:50px;">
                        <v-col align="center" justify="center">
                        <h2 class="c22-20-dm-sans"><b>Autentificare</b></h2>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col align="center" justify="center">
                            <v-text-field outlined type="text" v-model="email" :rules="[rules.required, rules.email]" label="Email *"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col align="center" justify="center">
                            <v-text-field outlined :type=passIcon.type v-model="pass" :rules="[rules.required]" label="Parola *">
                                <v-icon @click="togglePass" slot="append" color="#F02D4B">{{passIcon.icon}}</v-icon>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col align="start" justify="center" class="pl-2">
                            <router-link to="recoverpassword">
                            <span class="c14-14-dm-sans" style="color: #F02D4B;">Am uitat parola</span>
                            </router-link>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col align="center" justify="center">
                        <v-btn @click="login" class="no-text-transform" width="100%" dark style="background-color:#F02D4B;">
                            <span>Autentifica-te</span>
                        </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
                <v-row hidden>
                    <v-col align="center" justify="center">
                        <span class="c16-20-dm-sans-medium" style="color: #F02D4B;">Creeaza cont nou</span>
                    </v-col>
                </v-row>
                <v-row v-if="loginError">
                    <v-col align="center" justify="center" class="text-center">
                        <v-alert type="error">{{message}}</v-alert>
                    </v-col>
                </v-row>
        </div>
    </div>
  </v-container>
</template>

<script>
import Http from "../common/js/Http.js";
import validate from "../common/js/validate.js";
import globals from "../common/js/globals.js";

export default {
    name: 'Login',
    mixins: [validate, globals],
    data() {
        return {
            loginError : false,
            message : '',
            loginForm : true,
            email : '',
            pass : '',
            passIcon : {},
            passIcons : [
                {
                    check : true,
                    type : 'password',
                    icon : 'mdi-eye'
                },
                {
                    check : false,
                    type : 'text',
                    icon : 'mdi-eye-off'
                }
            ],
            pageTitle: '',
            pageContent: ''
        }
    },
    watch: {
    },
    methods: {
        async login() {
            if(this.$refs.loginForm.validate()) {
                let formData = new FormData();
                let data = {};
                data.username = this.email;
                data.password = this.pass;
                formData.append('LoginForm', JSON.stringify(data));
                let http = new Http();
                let postRead = await http.post(`/webapi/account/postLogin`,
                    formData
                );
                if(postRead.data.status) {
                    await localStorage.setItem("accessToken", postRead.data.data);
                    await this.$store.commit('setAppBarStatus', true);

                    this.$router.push({name: 'Dashboard'});
                    this.loginError = false;
                    this.message = '';
                } else {
                    this.loginError = true;
                    this.message = postRead.data.data;
                }
            }
        },
        togglePass() {
            if(this.passIcon.check)
                this.passIcon = this.passIcons[1];
            else
                this.passIcon = this.passIcons[0];
        },
        async getTerms() {
        let http = new Http();
        let terms = await http.get(`/webapi/default/terms`);
        this.pageTitle = terms.data.data.title;
        this.pageContent = terms.data.data.content;
    },

    async disableAppBar() {

    }
    },

    async created() {
        this.passIcon = this.passIcons[0];
    },
    async mounted() {

    },
    components: {
    }
}
</script>
<style scoped>
.terms h1 {
  padding: 0;
  margin-top: 10px;
  margin-bottom: 30px;
}

.novabg {
    background-color: #F02D4B;
    background-repeat: no-repeat;
    width: 156px;
    height: 70px;
    mask-size: contain;
    -webkit-mask-size: contain;
    mask-repeat: no-repeat;
    mask-image: url('./../assets/logo.svg');
}

.ee {
    padding:0;
    margin: 0;
    margin-top:2%;
    background-color: #D1F8D8;
    background-repeat: no-repeat;
    width: 350px;
    float: left;
    height: 350px;
    mask-size: contain;
    -webkit-mask-size: contain;
    mask-repeat: no-repeat;
    mask-image: url('./../assets/Energy.svg');
    transform: rotateY(180deg) rotate(0deg)
}

.gn {
    padding:0;
    margin: 0;
    margin-top:15%;
    background-color: #D1F8D8;
    background-repeat: no-repeat;
    width: 350px;
    height: 350px;
    float: right;
    mask-size: contain;
    -webkit-mask-size: contain;
    mask-repeat: no-repeat;
    mask-image: url('./../assets/Gas.svg');
}

.login {
    margin: auto;
    padding: 10px;
    width: 25%;
}

.login-form {
    background-color: white;
    border-radius: 10px;
    padding: 50px;
    box-shadow: 0 0 60px #dddddd;
}

.v-text-field--outlined >>> fieldset {
  border-color: lightgray;
}

.v-text-field--outlined >>> .v-text-field__slot input {
    color: black;
}

</style>
