import axios from "axios";

class Http {
  constructor() {
    if(localStorage.getItem("accessToken"))
    {
      return axios.create({
        baseURL: process.env.VUE_APP_BACKEND,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    } else {
      return axios.create({
        baseURL: process.env.VUE_APP_BACKEND,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    }
  }
}

export default Http;
