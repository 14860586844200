<template>
  <v-container align-start fluid class="referral">
    <v-row>
      <v-col cols="2">
        <v-select :items="filterItems" v-model="filterItem" label="Email recomandat" class="no-border-select" filled></v-select>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :footer-props="{'items-per-page-text': 'Inregistrari pe pagina', showFirstLastPage: true}"
          class="elevation-1 striped"
          loading-text="Se incarca..."
          no-results-text="Nu exista inregistrari de afisat"
          no-data-text="Nu exista inregistrari de afisat"
        >
          <template v-slot:[`footer.page-text`]="props">
            {{props.pageStart}}-{{props.pageStop}} din {{props.itemsLength}}
          </template>
          <template v-slot:[`item.CodRecomandare`]= "{item}">
            <span style="color:#171619; letter-spacing:0px" class="code-span c15-20-dm-sans-medium">{{item.CodRecomandare}}</span>
          </template>
          <template v-slot:[`item.NumeRecomandat`]= "{item}">
            <span style="color:#171619; letter-spacing:0px" class="c15-20-dm-sans">{{item.NumeRecomandat}}</span>
          </template>
          <template v-slot:[`item.EmailRecomandat`]= "{item}">
            <span style="color:#171619; letter-spacing:0px" class="c15-20-dm-sans">{{item.EmailRecomandat}}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Http from './../../common/js/Http';

export default {
  name: 'ReferralSent',
  data() {
    return {
      loading: true,
      filterItems: ['Toate'],
      filterItem: '',
      items: []
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'Cod Recomandare',
          value: 'CodRecomandare',
          sortable: false
        },
        {
          text: 'Nume Recomandat',
          value: 'NumeRecomandat'
        },
        {
          text: 'Email Recomandat',
          value: 'EmailRecomandat',
          filter: f => {
            if(this.filterItem == 'Toate') {
              return true;
            }
            return ('' + f + '').toLowerCase().includes(this.filterItem.toLowerCase());
          }
        }
      ];
    }
  },
  created() {
  },
  async mounted() {
    this.getItems();
  },
  watch: {
  },
  methods: {
    async getItems() {
      let http = new Http();
      let res = await http.get(`/webapi/referral/getSent`);
      if (res.data.items) {
        this.items = res.data.items;
        this.items.forEach(v => {
          this.filterItems.push(v.EmailRecomandat);
        });
      }
      this.loading = false;
    }
  },
  components: {
  }
}
</script>
<style scoped>
.referral {
  background: #FFFCF9 0% 0% no-repeat padding-box !important;
}
.code-span {
  background-color: #BCAFA122;
  padding: 5px 10px;
  border-radius: 15px;
}
</style>
