<template>
    <v-container fluid align-start class="optiuni">
        <v-row>
            <v-col>
                <h2 class="c22-20-dm-sans">{{oDataCount}} contracte</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="oData"
                    :footer-props="{
                    'items-per-page-text' : 'Inregistrari pe pagina',
                    showFirstLastPage: true,
                    }"
                    item-key="name"
                    class="elevation-1"
                    loading-text="Se incarca..."
                    no-results-text="Nu exista inregistrari de afisat"
                    no-data-text="Nu exista inregistrari de afisat"
                >

                <template v-slot:[`footer.page-text`]="props">
                    {{props.pageStart}}-{{props.pageStop}} din {{props.itemsLength}}
                </template>

                <template v-slot:[`item.Nume`]= "{item}">
                <v-img
                    v-if="item.ContractEE == '1'"
                    contain
                    align-center
                    width="35px"
                    height="33px"
                    alt="Energie"
                    :src="require('./../../assets/Energy.svg')"
                />

                <v-img
                    v-if="item.ContractGN == '1'"
                    contain
                    align-center
                    width="35px"
                    height="33px"
                    alt="Gaz"
                    :src="require('./../../assets/Gas.svg')"
                />

                </template>

                <template v-slot:[`item.NrContract`]= "{item}">
                    <span class="c16-20-dm-sans-medium">{{item.NrContract}}</span>
                </template>

                <template v-slot:[`item.Denumire`]= "{item}">
                    <BillSendOptions :item="item">
                        <template v-slot:button>
                            <span class="no-text-transform c3-tf">{{item.Denumire}}</span>
                            <v-icon color="#F02D4B">mdi-arrow-down</v-icon>
                        </template>
                    </BillSendOptions>
                </template>

                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import validate from "../../common/js/validate.js";
import Http from "../../common/js/Http.js";
import BillSendOptions from "../../components/modals/BillSendOptions";

export default {
    name: 'Istoric',
    mixins: [validate],
    data: () => ({
        oDataCount : 0,
        oData : [],
        selected: [],
        headers: [
        {
            text: 'Tip',
            align: 'start',
            sortable: false,
            value: 'Nume',
        },
        {
            text: 'Detalii contract',
            value: 'NrContract',
        },
        {
            text: 'Transmitere factura',
            value: 'Denumire',
            align: 'end'
        },
        ],
        vitems: [
        {
            type: 1,
            id : 287,
            address : 'Benesat, Str.-, nr.72, Jud. Salaj',
            nt: '536A7BE9489D811C55B03BB3F61B8210AFE5D9B7',
            date: '12.06.2020',
        },
        {
            type: 2,
            nt: '536A7BE9489D811C55B03BB3F61B8210AFE5D9B7',
            date: '12.06.2020',
            tp: '150.84 RON',
            fa: 'NPG 202061407 , NCG 202003366 , NPG 202058706',
        },
        {
            type: 1,
            nt: '536A7BE9489D811C55B03BB3F61B8210AFE5D9B7',
            date: '12.06.2020',
            tp: '150.84 RON',
            fa: 'NPG 202061407 , NCG 202003366 , NPG 202058706',
        },
        ]
    }),

    methods:{
        async getData() {
            let http = new Http();
            let oData = await http.get(`/webapi/facturi/optiunitransmiterefactura`);
            oData = await oData.data.data.contracte;
            Object.values(oData).forEach(val => {
                this.oData.push(val);
            });
            this.oDataCount = this.oData.length;
        }
    },

    async created() {
        this.getData();
    },
    components: {
        BillSendOptions
    }
}
</script>

<style>
.c2-details-header {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--base-typeface-color);
    text-align: left;
    font: normal normal medium 16px/20px DM Sans;
    letter-spacing: 0px;
    color: #171619;
}

.c2-details-text {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-13)/var(--unnamed-line-spacing-14) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--dark-grey);
    text-align: left;
    font: normal normal normal 13px/14px DM Sans;
    letter-spacing: 0px;
    color: #5D5D5C;
}

.c3-tf {
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nova-red);
    letter-spacing: 0px;
    color: #F02D4B;
}

.optiuni {
    background: #FFFCF9 0% 0% no-repeat padding-box !important;
}
</style>
