<template>
  <v-dialog
    persistent
    max-width="40%"
    v-model="modalUpload"
  >
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot name="button"></slot>
      </span>
    </template>
    <v-card>
      <v-container fluid style="padding:0;">
        <v-card-title class="pa-md-2">
          <v-row no-gutters>
            <v-col>
              <span class="pl-3 c15-20-dm-sans-medium"><b>Adauga document</b></span>
            </v-col>
            <v-col class="text-right">
              <v-btn icon @click="close">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref="uform" v-model="valid">
        <v-card-text>
          <v-row no-gutters>
            <v-text-field type="text" v-model="ufileName" :rules="[rules.required]" label="Denumire document" outlined></v-text-field>
          </v-row>
          <v-row no-gutters>
            <v-select v-model="ufileType" :items="ufileTypes" item-text="name" item-value="id" :rules="[rules.requiredselect]" label="Tipul documentului" outlined class="form-select"></v-select>
          </v-row>
          <v-row no-gutters>
            <v-textarea type="text" v-model="ufileComment" :rules="[rules.required]" label="Observatii" outlined></v-textarea>
          </v-row>
          <v-row no-gutters>
            <v-file-input v-model="ufileInfo" required label="Documentul" outlined></v-file-input>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn class="ml-3 green-btn" v-on:click="attachFile">
            Ataseaza
          </v-btn>
          <v-btn text v-on:click.stop="close" class="ml-3 close-text">
            Inapoi
          </v-btn>
        </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import globals from "./../../../common/js/globals";
import Http from "./../../../common/js/Http";

export default {
  name: 'AddDocument',
  mixins: [globals],
  data() {
    return {
      modalUpload: false,
      valid: true,

      // Upload
      ufileTypes: [],
      ufileName: '',
      ufileType: null,
      ufileComment: '',
      ufileInfo: null
    }
  },
  watch: {
    modalUpload(status) {
      if (status) {
        this.getFileTypes();
      }
    }
  },
  methods: {
    close() {
      this.modalUpload = false;
    },
    attachFile() {
      if (this.$refs.uform.validate() && this.ufileInfo !== null) {
        let formData = new FormData();
        formData.append('file', this.ufileInfo, this.ufileInfo.name);
        formData.append('upload_file', true);
        (new Http()).post(`/webapi/suggestion/addUploadFile`, formData).then(response => {
          let result = response.data;
          if (typeof result.error === 'undefined') {
            let ft = this.ufileTypes.find(v => v.id == this.ufileType);
            this.$emit('add-to-files', {
              Name: this.ufileName,
              FileType: this.ufileType,
              FileTypeName: ft.name,
              Comment: this.ufileComment,
              FileName: result.name,
              FilePath: result.path,
              FileMime: result.type
            });
          } else {
            console.error(result.error);
          }
          this.ufileName = '';
          this.ufileType = null;
          this.ufileComment = '';
          this.ufileInfo = null;
          this.$refs.uform.resetValidation();
        });
        this.close();
      }
    },
    async getFileTypes() {
      let http = new Http();
      let res = await http.get(`/webapi/suggestion/getFileTypes`);
      if (res.data) {
        for (let i in res.data.data) {
          this.ufileTypes.push({id: i, name: res.data.data[i]});
        }
      }
    }
  }
}
</script>
<style scoped>
.close-text {
  color: #F02D4B;
  font-weight: bold;
  text-transform: none;
}
.green-btn {
  color: white;
  text-transform:none !important;
  background: var(--green-primary) 0% 0% no-repeat padding-box !important;
  background: #2DB7A4 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
}
</style>
