import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    appBar : false
  },
  mutations: {
    async setUser(state, payload) {
      state.user = payload.user;
      // console.log(state.user);
      /**
       * if user is master
       * meaning if webuser has any child webuser associations
       * node true means user is master and has subusers
       */
      if (payload.node) {
        let masterData = {};
//        localStorage.removeItem('masterSessions');
        let masterDataArray = [];
        masterDataArray = await localStorage.getItem('masterSessions')?JSON.parse(localStorage.getItem('masterSessions')):[];
        masterData.id = state.user.Id;
        masterData.name = state.user.Name;
        masterData.token = localStorage.getItem("accessToken");
        if(masterDataArray) {
          for (let index = 0; index < masterDataArray.length; index++) {
            if (masterData.id == masterDataArray[index].id) {
              masterDataArray.splice(index, 1);
            }
          }
        }

        // ADD only node == true and remove if

        masterDataArray.push(masterData);
        localStorage.setItem('masterSessions', JSON.stringify(masterDataArray));
      }
    },
    setAppBarStatus(state, payload) {
      state.appBar = payload;
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    getUser : (state) => state.user,
    getAppBarStatus : (state) => state.appBar
  }
})
