<template>
    <v-container fluid class="procese align-center">
        <v-row>
            <v-col cols="2">
            <v-select class="no-border-select" dense filled :items="tipfurnizare" v-model="furnizare" label="Tip furnizare"></v-select>
            </v-col>
            <v-col cols="1">
            <v-select class="no-border-select" dense filled :items="this.ani" v-model="an" label="An"></v-select>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-data-table
            :headers="headers"
            :items="pvs"
            :loading="loading"
            :footer-props="{
            'items-per-page-text' : 'Procese verbale pe pagina',
            showFirstLastPage: true,
            }"
            item-key="name"
            class="elevation-1"
            loading-text="Se incarca..."
            no-results-text="Nu exista inregistrari de afisat"
            no-data-text="Nu exista inregistrari de afisat"
        >

        <template v-slot:[`item.TipContract`]= "{item}">
                <v-img
                    v-if="item.TipContract == 'EE'"
                    contain
                    align-center
                    width="35px"
                    height="33px"
                    alt="Energie"
                    :src="require('./../../assets/Energy.svg')"
                />
                <v-img
                    v-if="item.TipContract == 'GN'"
                    contain
                    align-center
                    width="35px"
                    height="33px"
                    alt="Gaz"
                    :src="require('./../../assets/Gas.svg')"
                />
        </template>

        <template v-slot:[`item.An`]= "{item}">
          <pvModal :item="item">
            <template v-slot:button>
            <div class="text-left">
            <pre class="c16-20-dm-sans-medium" style="color:#F02D4B;">{{item.LunaNume}} {{item.An}}</pre>
            <pre v-if="item.TipContract == 'EE' && item.CantitateFacturata" class="c13-14-dm-sans-subtext">{{item.CantitateFacturata}} energie facturata</pre>
            <pre v-if="item.TipContract == 'GN' && item.CantitateFacturata" class="c13-14-dm-sans-subtext">{{item.CantitateFacturata}} gaz facturat</pre>
            </div>
            </template>
          </pvModal>
        </template>

        <template v-slot:[`item.download`]= "{item}">
            <v-btn
              v-if="item.idDoc && item.AdresaWebDocument"
              contain
              text
              class="no-text-transform"
              v-on:click="exportFile(item.idDoc, item.AdresaWebDocument)">
                <v-icon color="#F02D4B">mdi-file-pdf</v-icon>
                <span class="c15-20-dm-sans" style="color:#F02D4B"><b>Descarca</b></span>
            </v-btn>
            <pvModal :item="item" v-else>
              <template v-slot:button>
              <div class="text-right">
              <v-btn
                contain
                text
                class="no-text-transform text-right"
              >
                <v-icon color="#F02D4B">mdi-file-pdf</v-icon>
                <span class="c15-20-dm-sans" style="color:#F02D4B"><b>Descarca</b></span>
              </v-btn>
              </div>
              </template>
            </pvModal>
        </template>

        </v-data-table>

    </v-container>
</template>

<script>
// @ is an alias to /src
import validate from "../../common/js/validate.js";
import Http from "../../common/js/Http.js";
import Helper from "../../common/js/Helper.js";
import pvModal from "../modals/PV";

export default {
  mixins: [validate],

  name: 'Procese',
  data() {
    return {
        helper : Helper,
        loading: true,
        tipfurnizare:['Toate','EE', 'GN'],
        furnizare:'',
        ani: ['Toate'],
        an:'',
        pvs:[],
        procese: [
            {
                TipContract: 1,
                IdTipuriLuniAni: 'Octombrie 2020',
                detalii2: '765 kwh energie facturata',
                download: 1
            },
            {
                TipContract: 0,
                detalii: 'Decembrie 2020',
                IdTipuriLuniAni: '765 kwh energie facturata',
                download: 1
            },
            {
                TipContract: 1,
                detalii: 'Ianuarie 2020',
                IdTipuriLuniAni: '765 kwh energie facturata',
                download: 1
            },
        ]

    }
  },

  methods: {
    async getPVs() {
      let http = new Http();
      this.pvs = await http.get(`/webapi/locuriconsum/PVEEGN?pc=${this.$route.params.id}`);
      this.pvs = this.pvs.data.data.pv;
      this.loading = false;
      // Only unique years from list
      this.pvs.forEach(pv => {
        const y = pv.An;
        if (this.ani.indexOf(y) === -1) {
          this.ani.push(y);
        }
      });
    },

    exportFile(id, url) {
      Helper.downloadFile(`/webapi/exports/webDoc?id=${id}`, Helper.basename(url));
    }
  },

  created() {
    this.getPVs();
  },
  components: {
    pvModal
  },

  computed: {
    headers() {
      return [
        {
          text: 'Tip',
          align: 'start',
          sortable: false,
          value: 'TipContract',
          width: '50px',
          filter: f => {
            if(this.furnizare == 'Toate') {
              return true;
            }
            return (' '+f+' ' ).toLowerCase().includes( this.furnizare.toLowerCase())
          }
        },
        {
          text: 'Detalii',
          value: 'An',
          align: 'start',
          filter: f => {
            if(this.an == 'Toate') {
            return true;
          }

          return (' '+f+' ' ).toLowerCase().includes( this.an )
          }
        },
        {
          text: '',
          value: 'download',
          align: 'end'
        },
      ]
    }
  },
}
</script>
<style scoped>
.procese {
    background-color: #FFFCF9;
}

</style>
