<template>
    <!-- <v-container fill-height fluid align-start class="dashboard"> -->
    <v-container fluid align-start class="account">
        <v-row>
            <v-col>
                <h1 class="c32-20-dm-sans">Contul meu</h1>
            </v-col>
            <v-col class="text-right">
                <v-btn @click="logout();" style="background: #FF5F7824 0% 0% no-repeat;" class="no-text-transform"><span class="details no-text-transform">Deconectare</span></v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="8">
                <v-card class="v-card-custom" min-height="306" elevation="5">
                    <v-row>
                        <v-col cols="1" align="center">
                            <div class="account-pic"></div>
                        </v-col>
                        <v-col>
                            <v-row class="text-left">
                                <v-col>
                                    <h2 class="c22-20-s-44-dm-sans" style="margin-bottom:3px"><b>{{pname}}</b></h2>
                                    <pre class="c14-20-dm-sans-subtext">COD Client {{pcode}}</pre>
                                </v-col>
                            </v-row>
                            <!-- <v-row no-gutters class="text-left">
                                <v-col cols="1">
                                    img
                                </v-col>
                                <v-col>
                                    <span class="c14-20-dm-sans">
                                        <b>Ai ales cel mai bun serviciu de energie si gaz in 23 Octombrie 2020, invitat de Andreea Popescu.</b>
                                    </span>
                                </v-col>
                            </v-row> -->
                            <v-row>
                                <v-col>
                                    <pre class="c15-20-dm-sans">{{paddressfull}}</pre>
                                    <pre class="c15-20-dm-sans">Cod postal: {{pcodpostal}}</pre>
                                    <pre class="c15-20-dm-sans">{{poras}}, {{pjudetname}}, {{ptaraname}}</pre>
                                </v-col>
                            </v-row>
                            <v-row v-if="ptoj != '2'" no-gutters class="text-left">
                                <v-col cols="2"><span class="c15-20-dm-sans"><b>CNP/CUI</b></span></v-col>
                                <v-col><span class="c15-20-dm-sans">{{pcui}}</span></v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="2"><span class="c15-20-dm-sans"><b>Email</b></span></v-col>
                                <v-col><span>{{pemail}}</span></v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="2"><span class="c15-20-dm-sans"><b>Telefon</b></span></v-col>
                                <v-col><span>{{pmobil}}</span></v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2">
                                    <v-dialog v-model="modDialog" width="650">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn style="padding:0px;" text v-bind="attrs" v-on="on">
                                            <span class="red-notice no-text-transform">Modifica date</span>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-title>
                                                <v-row no-gutters>
                                                    <v-col>
                                                        <span style="color:#171619;" class="c15-20-dm-sans">Detalii cont</span>
                                                    </v-col>
                                                    <v-col class="text-right">
                                                        <v-btn icon @click="modDialog = false">
                                                            <v-icon small>mdi-close</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-title>
                                            <v-divider></v-divider>

                                            <v-card-text>
                                            <v-row no-gutters>
                                                <v-col>
                                                    <v-tabs background-color="transparent" color="red">
                                                    <v-tab><span style="text-transform:none !important; letter-spacing:0px; height:21px; color:#5D5D5C;" class="c16-20-dm-sans">Date personale</span></v-tab>
                                                    <v-tab-item>
                                                        <br>
                                                        <v-form ref="form" v-model="valid">
                                                            <v-row no-gutters v-if="pname">
                                                                <v-text-field type="text" v-model="pname" :rules="[rules.required]" label="Nume" outlined readonly></v-text-field>
                                                            </v-row>
                                                            <v-row no-gutters>
                                                                <v-text-field type="number" v-model="pcui" :rules="[rules.required]" label="CNP/CUI Partener" outlined readonly></v-text-field>
                                                            </v-row>
                                                            <v-row no-gutters>
                                                                <v-text-field type="number" v-model="pcnp" label="CNP/CUI Utilizator" outlined></v-text-field>
                                                            </v-row>
                                                            <v-row no-gutters>
                                                                <v-text-field type="text" v-model="pemail" :rules="[rules.required, rules.email]" label="Email" outlined></v-text-field>
                                                            </v-row>
                                                            <v-row no-gutters>
                                                                <v-text-field type="number" v-model="pmobil" label="Mobil" outlined></v-text-field>
                                                            </v-row>
                                                            <v-divider/>
                                                            <v-row>
                                                                <v-col>
                                                                    <v-btn
                                                                        class="no-text-transform green-white-btn"
                                                                        dark
                                                                        @click="saveReadDP()"
                                                                    >
                                                                        Salveaza
                                                                    </v-btn>
                                                                    <v-btn
                                                                        text
                                                                        class="close-text"
                                                                        @click="modDialog = false"
                                                                    >
                                                                        Inchide
                                                                    </v-btn>
                                                                </v-col>
                                                            </v-row>
                                                        </v-form>
                                                    </v-tab-item>
                                                    <v-tab v-if="user && user.SessionId"><span style="text-transform:none !important; letter-spacing:0px; color:#5D5D5C;" class="c16-20-dm-sans">Adresa</span></v-tab>
                                                    <v-tab-item>
                                                        <br>
                                                        <Adresa v-on:close="modDialog = false"></Adresa>
                                                    </v-tab-item>
                                                    <v-tab  v-if="user && user.SessionId"><span style="text-transform:none !important; letter-spacing:0px; color:#5D5D5C;" class="c16-20-dm-sans">Informatii suplimentare</span></v-tab>
                                                    <v-tab-item>
                                                        <br>
                                                        <v-form v-if="account" ref="form3" v-model="valid3">
                                                        <v-row no-gutters v-for="entData in account.entData" :key="entData.Descriere">
                                                            <v-col>
                                                                <v-text-field outlined v-model="vIs[entData.IdTipuriInfoParteneri]" :label="entData.Descriere"></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                            <v-divider/>
                                                            <v-row>
                                                                <v-col>
                                                                    <v-btn
                                                                        class="no-text-transform green-white-btn"
                                                                        dark
                                                                        @click="saveReadIS()"
                                                                    >
                                                                        Salveaza
                                                                    </v-btn>
                                                                    <v-btn
                                                                        text
                                                                        class="close-text"
                                                                        @click="modDialog = false"
                                                                    >
                                                                        Inchide
                                                                    </v-btn>
                                                                </v-col>
                                                            </v-row>
                                                        </v-form>
                                                    </v-tab-item>
                                                    </v-tabs>
                                                </v-col>
                                            </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                                <v-col>
                                    <v-dialog v-model="passDialog" width="500" style="">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn style="padding:0px;" text v-bind="attrs" v-on="on">
                                            <span class="red-notice no-text-transform">Schimba parola</span>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-title>
                                                <v-row no-gutters>
                                                    <v-col>
                                                        <span style="color:#171619;" class="c15-20-dm-sans">Schimba parola</span>
                                                    </v-col>
                                                    <v-col class="text-right">
                                                        <v-btn icon @click="closePass">
                                                            <v-icon small>mdi-close</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col>
                                                        <v-alert type="success" text v-if="successMessage"><span class="c15-20-s-dm-sans">{{successMessage}}</span></v-alert>
                                                        <v-alert type="error" text v-if="errorMessage"><span class="c15-20-s-dm-sans">{{errorMessage}}</span></v-alert>
                                                    </v-col>
                                                </v-row>
                                                <v-form ref="formP" v-model="validP">
                                                    <v-row no-gutters>
                                                        <v-text-field outlined :type="passIcon1.type" v-model="password" :rules="[rules.required, rules.pass]" label="Parola noua" tabindex="1">
                                                            <v-icon @click="togglePass(1)" slot="append" color="#F02D4B">{{passIcon1.icon}}</v-icon>
                                                        </v-text-field>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-text-field outlined :type="passIcon2.type" v-model="password2" :rules="[rules.required, rules.pass, rules.cpass]" label="Confirmare parola noua" tabindex="2">
                                                            <v-icon @click="togglePass(2)" slot="append" color="#F02D4B">{{passIcon2.icon}}</v-icon>
                                                        </v-text-field>
                                                    </v-row>
                                                    <v-divider/>
                                                    <v-row>
                                                        <v-col>
                                                            <v-btn
                                                                class="no-text-transform green-white-btn"
                                                                dark
                                                                @click="savePass()"
                                                            >
                                                                Salveaza
                                                            </v-btn>
                                                            <v-btn
                                                                text
                                                                class="close-text ml-3"
                                                                @click="closePass"
                                                            >
                                                                Inchide
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-form>
                                            </v-card-text>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="4" class="text-center">
                <v-card class="v-card-custom" min-height="306" elevation="5">
                    <br><br>
                    <v-row>
                        <v-col align="center" justify="center">
                            <v-img
                                align-center
                                contain
                                :src="require('./../assets/achievement.svg')"
                                transition="scale-transition"
                                width="68"
                            />
                        </v-col>
                    </v-row>
                    <!-- <v-row no-gutters>
                        <v-col>
                            <span class="c15-20-dm-sans">Puncte de recomandare</span>
                        </v-col>
                    </v-row>
                    <v-row >
                        <v-col><h1 class="c32-20-dm-sans"><b>780 MWh</b></h1></v-col>
                    </v-row> -->
                    <v-row>
                        <v-col>
                            <span class="c14-20-dm-sans" v-if="referralData">Recomanda NOVA prietenilor tai si te recompensam cu 50 lei.</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="referralData">
                            <ReferralModal :info="referralData">
                                <template v-slot:button>
                                    <v-btn style="background-color:#2DB7A4"><span class="rec-friends-btn no-text-transform">Recomanda Nova</span></v-btn>
                                </template>
                            </ReferralModal>
                        </v-col>
                        <v-col>
                            <router-link to="/referral">
                            <v-btn style="background: #FF5F7824 0% 0% no-repeat;">
                                <span class="details no-text-transform"><b>Panou recomandari</b></span>
                            </v-btn>
                            </router-link>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h3 class="c22-20-dm-sans">Contracte</h3>
            </v-col>
        </v-row>
        <v-row v-if="contracts.length">
            <v-col xs="12" sm="6" lg="4" v-for="(contract, key) in contracts" :key="key">
                <v-card elevation="5" class="cb v-card-custom">
                    <v-row no-gutters>
                        <v-col style="flex: 0 1 45px">
                            <v-img
                                v-if="contract.IdTipContract == 6"
                                contain
                                align-center
                                min-width="35px"
                                min-height="35px"
                                width="35px"
                                height="33px"
                                alt="Energie"
                                :src="require('./../assets/Energy.svg')"
                            />
                            <v-img
                                v-if="contract.IdTipContract == 40"
                                contain
                                align-center
                                min-width="35px"
                                min-height="35px"
                                width="35px"
                                height="33px"
                                alt="Gaz"
                                :src="require('./../assets/Gas.svg')"
                            />
                        </v-col>
                        <v-col>
                            <h2 class="c20-20-dm-sans" style="color:black;"><b>Contract {{contract.NrContract}}</b></h2>
                            <span class="c13-14-dm-sans" style="color:#5D5D5C">{{contract.PunctConsumAdresaFull}}</span>
                        </v-col>
                        <v-col  style="flex: 0 1 100px" v-if="contract.IsActive == false">
                            <span style="color:#171619; letter-spacing:0px" class="close-span c15-20-dm-sans-medium">Incetat</span>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row>
                        <v-col cols="4">
                            <pre class="c16-20-dm-sans" style="color:black;"><b>{{Helper.dateFormatter(contract.DataSemnare)}}</b></pre>
                            <pre class="c13-14-dm-sans" style="color:#5D5D5C">Semnat</pre>
                        </v-col>
                        <v-col cols="4">
                            <pre class="c16-20-dm-sans" style="color:black;"><b>{{Helper.dateFormatter(contract.DataVigoare)}}</b></pre>
                            <pre class="c13-14-dm-sans" style="color:#5D5D5C">Intrat in vigoare</pre>
                        </v-col>
                        <v-col cols="4" v-if="contract.IsActive == false">
                            <pre class="c16-20-dm-sans" style="color:black;"><b>{{Helper.dateFormatter(contract.DataIncetare)}}</b></pre>
                            <pre class="c13-14-dm-sans" style="color:#5D5D5C">Incetat</pre>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <span class="c13-20-dm-sans" style="color:black">{{contract.ModTransmitereFact}}</span>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="contracts.length">
            <v-col>
                <router-link to="/contracte">
                <span class="red-notice c15-20-dm-sans">Toate contractele</span>
                </router-link>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h3 class="c22-20-dm-sans" style="margin-bottom:5px">Utilizatori</h3>
                <p class="c13-14-dm-sans-subtext">Ofera acces la cont persoanelor de incredere.</p>
                <v-alert type="error" text v-if="usersErrorMessage"><span class="c15-20-s-dm-sans">{{usersErrorMessage}}</span></v-alert>
            </v-col>
        </v-row>
        <v-row v-for="(user, key) in users" :key="key">
            <v-col>
                <v-card class="v-card-custom">
                    <v-row class="pl-md-4 pr-md-4">
                        <v-col style="flex: 0 1 64px" align-self="center">
                            <div class="user-btn"><v-icon>{{Helper.userNameLetters(user.Name)}}</v-icon></div>
                        </v-col>
                        <v-col align-self="center">
                            <h4 class="c16-20-dm-sans-medium" style="margin-bottom:5px">{{user.Name}}</h4>
                            <pre class="c13-14-dm-sans-subtext">{{user.Email}}</pre>
                        </v-col>
                        <v-col class="text-end" align-self="center">
                            <UserModal type="edit" :item="user">
                                <template v-slot:button>
                                    <v-btn text>
                                        <v-icon x-small color="#2DB7A4">mdi-pencil-outline</v-icon>
                                        <span style="letter-spacing:normal; color:#2DB7A4" class="no-text-transform ml-1">Modifica</span>
                                    </v-btn>
                                </template>
                            </UserModal>
                            <v-btn text v-on:click="deactivateUser(user.Id, user.Name)">
                                <v-icon x-small color="#F02D4B">mdi-close-box</v-icon>
                                <span style="letter-spacing:normal; color:#F02D4B" class="no-text-transform ml-1">Dezactiveaza</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <UserModal type="add" v-on:refresh-users="getUsers">
                    <template v-slot:button>
                        <span class="red-notice no-text-transform">+ Adauga utilizator</span>
                    </template>
                </UserModal>
            </v-col>
        </v-row>
        <ConfirmDialog ref="confirm"></ConfirmDialog>
    </v-container>
</template>
<script>
import globals from "../common/js/globals.js";
import Http from "./../common/js/Http";
import Helper from "./../common/js/Helper";
import validate from "../common/js/validate.js";
import Adresa from "./../components/modals/suport/Adresa";
import ReferralModal from "./../components/modals/Referral";
import UserModal from "./../components/modals/User";
import ConfirmDialog from "./../components/modals/ConfirmDialog";

export default {
    name: 'Account',
    mixins: [validate, globals],
    data() {
        return {
            Helper,
            emptyValue : '',
            account : null,
            modDialog : false,
            passDialog : false,
            countries : [],
            counties : [],
            formattedCounties : [],
            cities : [],
            formattedCities : [],
            streets : [],
            formattedStreets : [],
            contracts: [],
            users: [],
            usersErrorMessage: '',
            referralData: null,

            valid : true,
            valid2 : true,
            valid3 : true,
            validP : true,
            vIs : [],
            pcode : '',
            pname : '',
            pcui : '',
            pcnp : '',
            pemail : '',
            pmobil : '',
            ptoj: '0',

            password : '',
            password2 : '',
            successMessage : '',
            errorMessage : '',
            passIcons : [
                {
                    check : true,
                    type : 'password',
                    icon : 'mdi-eye'
                },
                {
                    check : false,
                    type : 'text',
                    icon : 'mdi-eye-off'
                }
            ],
            passIcon1 : {},
            passIcon2 : {},

            ptara : '',
            ptaraname : '',
            pjudet : '',
            pjudetname : '',
            poras : '',
            pstrada : '',
            pnumar : '',
            pbloc : '',
            pscara : '',
            ptronson : '',
            petaj : '',
            pap : '',
            psector : '',
            pcodpostal : '',
            paddressfull : ''
        }
    },

    methods: {
        async getReferral(user) {
            if (user) {
                let http = new Http();
                let res = await http.get(`/webapi/referral/getCode?partener=${user.PartnerEntityId}`);
                if (res.data && res.data.code) {
                    this.referralData = res.data;
                }
            }
        },
        async saveReadDP() {
            if(this.$refs.form.validate()) {
                let formData = new FormData();
                formData.append('Partener[Email]', this.pemail);
                formData.append('Partener[Name]', this.pname);
                formData.append('Partener[CUI]', this.pcui);
                formData.append('Partener[CNP]', this.pcnp);
                formData.append('Partener[Mobil]', this.pmobil);
                let http = new Http();
                let postRead = await http.post(`/webapi/account/postDatePersonale`,
                    formData
                );
                console.log(postRead);
            }
        },
        async saveReadIS() {
            if(this.$refs.form3.validate()) {
                let isKeys = [];
                this.account.entData.forEach(elem => {
                    isKeys[elem.IdTipuriInfoParteneri] = elem.IdTipuriInfoParteneri;
                });
                let formData = new FormData();
                formData.append('IdTipuriInfoParteneri', isKeys);
                formData.append('Info', this.vIs);
                let http = new Http();
                let postRead = await http.post(`/webapi/account/postInfoSuplimentare`,
                    formData
                );
                console.log(postRead);
            }
        },
        closePass() {
            this.successMessage = '';
            this.errorMessage = '';
            this.passDialog = false
        },
        async savePass() {
            this.successMessage = '';
            this.errorMessage = '';
            if (this.$refs.formP.validate()) {
                let formData = new FormData();
                formData.append('newpass', this.password);
                formData.append('retpass', this.password2);
                let http = new Http();
                let postRead = await http.post(`/webapi/account/changePassword`, formData);
                if (postRead.data.error) {
                    this.errorMessage = postRead.data.error.message;
                }
                if (postRead.data.success) {
                    this.successMessage = postRead.data.success.message;
                }
                this.password = '';
                this.password2 = '';
                this.$refs.formP.resetValidation();
            }
        },
        async getPartner() {
            let http = new Http();
            let account = await http.get(`/webapi/account`);
            this.account  = await account.data

            for (const [key, value] of Object.entries(this.account.countries)) {
                this.countries.push({
                    text : value,
                    value : key
                });
            }

            this.ptara = this.account.partner_address.IdTara;
            this.ptaraname = this.account.partner_address.Tara;
            this.pjudet = this.account.partner_address.IdJudet;
            this.pjudetname = this.account.partner_address.Judet;
            this.poras = this.account.partner_address.Localitate;
            this.pstrada = this.account.partener.Strada;
            this.pnumar = this.account.partner_address.Numar;
            this.pbloc = this.account.partner_address.Bloc;
            this.pscara = this.account.partner_address.Scara;
            this.ptronson = this.account.partner_address.Tronson;
            this.petaj = this.account.partner_address.Etaj;
            this.pap = this.account.partner_address.Apartament;
            this.psector = this.account.partner_address.Sector;
            this.pcodpostal = this.account.partner_address.CodPostal;

            if (this.pstrada) {
                this.paddressfull += 'Str. ' + this.pstrada;
            }
            if (this.pnumar) {
                this.paddressfull += ', Nr. ' + this.pnumar;
            }
            if (this.pbloc) {
                this.paddressfull += ', Bl. ' + this.pbloc;
            }
            if (this.pscara) {
                this.paddressfull += ', Sc. ' + this.pscara;
            }
            if (this.pap) {
                this.paddressfull += ', Ap. ' + this.pap;
            }
        },
        async getContracts() {
            let http = new Http();
            let res = await http.get(`/webapi/account/contracte?limit=3`);
            if (res.data) {
                this.contracts = res.data.data;
            }
        },
        async getUsers() {
            let http = new Http();
            let res = await http.get(`/webapi/account/getUsers`);
            if (res.data) {
                this.users = res.data.data;
            }
        },
        async deactivateUser(id, name) {
            if (await this.$refs.confirm.open('Atentie', 'Esti sigur ca vrei sa dezactivezi utilizatorul "' + name + '"?', {confirmBtn: 'Dezactivez'})) {
                let http = new Http();
                let res = await http.get(`/webapi/account/deleteUser?id=${id}`);
                if (res.data) {
                    if (res.data.success) {
                        this.getUsers();
                    }
                    if (res.data.error) {
                        this.usersErrorMessage = this.data.error.message;
                    }
                }
            }
        },
        togglePass(idx) {
            switch (idx) {
                case 1:
                    this.passIcon1 = this.passIcons[Number(this.passIcon1.check)];
                    break;
                case 2:
                    this.passIcon2 = this.passIcons[Number(this.passIcon2.check)];
                    break;
            }
        },
    },
    watch: {
        async ptara(to) {
            let http = new Http();
            this.counties = await http.get(`/webapi/account/getcountiesbycountry?country=${to}`);
            this.counties = this.counties.data;
        },

        counties() {
            if(this.counties != null) {
                this.formattedCounties = [];
                this.counties.forEach(elem => {
                    this.formattedCounties.push({
                        text : elem.DenumireFaraDiacritice,
                        value : elem.IdJudete
                    })
                });
            }
        },
        async pjudet(to) {
            if(this.counties != null) {
                let http = new Http();
                this.cities = await http.get(`/webapi/account/getcities?country=${this.ptara}&county=${to}`);
                this.cities = this.cities.data;
                if(this.cities != null) {
                    this.formattedCities = [];
                    this.cities.forEach(elem => {
                        this.formattedCities.push({
                            text : elem.label,
                            value : elem.value
                        })
                    });
                }
            }
        },

        async poras() {
        },

        user(info) {
            this.getContracts();
            this.getReferral(info);

            this.pname = this.user.Name;
            this.pcui = this.user.partener.Cui;
            this.ptoj = this.user.partener.IdTipOrganizareJuridica;
            this.pcnp = this.user.CNP;
            this.pemail = this.user.Email;
            this.pmobil = this.user.Mobil;
            this.pcode = this.user.partener.BusinessPartnerCode;
        },
        account() {
//            this.pname = this.account.Nume;
        }
    },
    created() {
        this.$root.$children[0].accountSelected = 'Detalii cont';
        this.$root.$children[0].selectedMenuItem = null;
        this.passIcon1 = this.passIcon2 = this.passIcons[0];
    },
    async mounted() {
        await this.getPartner();
        await this.getUsers();
        await this.getContracts();
        if (this.user != null) {
            this.pname = this.user.Name;
            this.pcui = this.user.partener.Cui;
            this.ptoj = this.user.partener.IdTipOrganizareJuridica;
            this.pcnp = this.user.CNP;
            this.pemail = this.user.Email;
            this.pmobil = this.user.Mobil;
            this.pcode = this.user.partener.BusinessPartnerCode;
        }
    },
    components: {
        Adresa,
        ReferralModal,
        UserModal,
        ConfirmDialog
    }
}
</script>
<style scoped>
.rec-friends {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--base-typeface-color);
    font: normal normal normal 14px/20px DM Sans;
    letter-spacing: 0px;
    color: #171619;
}
.rec-friends-btn {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-15)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: center;
    font: normal normal bold 15px/20px DM Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
}
.details {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-15)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--nova-red);
    font: normal normal medium 15px/20px DM Sans;
    letter-spacing: 0px;
    color: #F02D4B;
}

.account-pic {
    float: left;
    margin-left: 35px;
    margin-top: 10px;
    background-color: black;
    background-repeat: no-repeat;
    width: 45px;
    height: 45px;
    mask: url('./../assets/consumer.svg');
    mask-size: contain;
    mask-repeat: no-repeat;

}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

.green-white-btn {
    background: var(--green-primary) 0% 0% no-repeat padding-box !important ;
    background: #2DB7A4 0% 0% no-repeat padding-box !important;
    border-radius: 5px;
}

.close-span {
  background-color: #BCAFA122;
  padding: 5px 10px;
  border-radius: 15px;
}
.close-text {
    color: #F02D4B;
    text-transform: none;
}

.red-notice {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-15)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
    letter-spacing: var(--unnamed-character-spacing-0-3);
    color: var(--nova-red);
    text-align: left;
    font: normal normal normal 15px/20px DM Sans;
    letter-spacing: 0.3px;
    color: #F02D4B;
}

.v-text-field--outlined >>> fieldset {
    border-color: lightgray;
}
.v-text-field--outlined >>> .v-text-field__slot input {
    color: black;
}
.cb {
    height: 100%;
    padding: 20px;
}
.cb hr {
  margin: 15px 0;
}
.user-btn {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: pink;
    border-radius: 50%;
}
.user-btn .v-icon {
    color: #FF5F78;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
}
</style>
