<template>
  <v-container align-start fluid class="facturi">
    <v-row>
      <v-col style="padding-top:35px;" cols="3" md="7" xl="9">
        <p class="c32-20-dm-sans">Facturi si plati</p>
      </v-col>
      <v-col style="padding-top:30px; height:100px;" align="right">
        <table border="0" v-if="unformattedTotal != 0">
          <tr valign="top">
            <td>
              <div style="background-color:#2DB7A421; border-radius:16px; padding:18px">
                <v-img
                    width="21px"
                    alt="Vuetify Logo"
                    :src="require('./../assets/Vector.svg')"
                />
              </div>
            </td>
            <td style="padding:9px 12px">
              <pre class="c32-20-dm-sans" style="margin-bottom:3px"><b>{{total}}</b></pre>
              <span class="c14-14-dm-sans-subtext">{{ids.length}} facturi selectate</span>
            </td>
            <td>
              <platesteModal
                :align="'text-left'"
                :user="user"
                :unformattedTotal="unformattedTotal"
                :total="total"
                :ids="ids"
                :btnClass="'pay-btn'"
                v-on:hidePlateste="hidePlateste($event)"
              >
                <template v-slot:button>
                  <span style="text-transform:none !important"><b>Plateste</b></span>
                  <v-icon>mdi-menu-right</v-icon>
                </template>
              </platesteModal>
            </td>
          </tr>
        </table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tabs background-color="transparent" color="red">
          <v-tab><span style="text-transform:none !important; letter-spacing:0px; height:21px;" class="c16-20-dm-sans-medium">Facturile mele</span></v-tab>
          <v-tab-item>
            <FacturileMele @preppedBills="preppedBills"/>
          </v-tab-item>
          <v-tab><span style="text-transform:none !important; letter-spacing:0px;" class="c16-20-dm-sans-medium">Istoric plati online</span></v-tab>
          <v-tab-item>
            <IstoricPlatiOnline />
          </v-tab-item>
          <v-tab><span style="text-transform:none !important; letter-spacing:0px;" class="c16-20-dm-sans-medium">Optiuni transmitere factura</span></v-tab>
          <v-tab-item>
            <OptiuniTransmitere />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import FacturileMele from "./../components/Facturi/FacturileMele";
import IstoricPlatiOnline from "./../components/Facturi/IstoricPlatiOnline";
import OptiuniTransmitere from "./../components/Facturi/OptiuniTransmitere";
import validate from "./../common/js/validate.js";
import platesteModal from "./../components/modals/Plateste";

export default {
  name: "Facturi",
  mixins: [validate],
  data() {
    return {
      unformattedTotal: 0,
      total : 0,
      ids : [],
      tab: null,
      items: [
        { tab: "One", content: FacturileMele },
        { tab: "Two", content: "Tab 2 Content" },
        { tab: "Three", content: "Tab 3 Content" },
      ],
    };
  },
  created() {
    this.$root.$children[0].accountSelected = null;
    this.$root.$children[0].selectedMenuItem = 'bills';
  },
  methods: {
    preppedBills(unformattedTotal, total, ids) {
      this.unformattedTotal = parseFloat(unformattedTotal);
      this.total = total;
      this.ids = ids;
    }
  },
  components: {
    FacturileMele,
    IstoricPlatiOnline,
    OptiuniTransmitere,
    platesteModal,
  },
};
</script>
<style scoped>
.red-notice {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-15) /
    var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
  letter-spacing: var(--unnamed-character-spacing-0-3);
  color: var(--nova-red);
  text-align: left;
  font: normal normal normal 15px/20px DM Sans;
  letter-spacing: 0.3px;
  color: #f02d4b;
}
.details {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-medium) var(--unnamed-font-size-15) /
    var(--unnamed-line-spacing-20) var(--unnamed-font-family-dm-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--nova-red);
  font: normal normal medium 15px/20px DM Sans;
  letter-spacing: 0px;
  color: #f02d4b;
}
.pay-btn {
  border: 2px solid var(--green-primary);
  background: white !important;
  border: 2px solid #2db7a4;
  border-radius: 5px;
  color: #2db7a4;
}
</style>
