<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title class="pa-3">
        <span class="pl-3 c15-20-dm-sans-medium"><b>{{title}}</b></span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-show="!!message" class="pa-4 c15-20-dm-sans" style="color:#171619;" v-html="message"></v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn class="ml-3 green-btn" @click.native="agree">
        {{options.confirmBtn}}
        </v-btn>
        <v-btn v-if="!options.noconfirm" class="ml-3 close-text" text @click.native="cancel">
        Renunt
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'ConfirmDialog',
    data() {
      return {
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        options: {
          width: 500,
          zIndex: 200,
          noconfirm: false,
          confirmBtn: 'OK'
        }
      };
    },
    methods: {
      open(title, message, options) {
        this.dialog = true;
        this.title = title;
        this.message = message;
        this.options = Object.assign(this.options, options);
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
      agree() {
        this.resolve(true);
        this.dialog = false;
      },
      cancel() {
        this.resolve(false);
        this.dialog = false;
      }
    }
  };
</script>
<style scoped>
.close-text {
  color: #F02D4B;
  font-weight: bold;
  text-transform: none;
}
.green-btn {
  color: white;
  text-transform:none !important;
  background: var(--green-primary) 0% 0% no-repeat padding-box !important;
  background: #2DB7A4 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
}
</style>
