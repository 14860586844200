<template>
  <v-row class="header" no-gutters align="center" justify="center">
    <v-col align="start" justify="center">
      <!-- <router-link to="/"> -->
        <v-img
          class="ml-5"
          alt="Vuetify Logo"
          contain
          :src="require('./../assets/logo.svg')"
          transition="scale-transition"
          width="79"
        />
      <!-- </router-link> -->
    </v-col>
    <v-col><router-link to="/" class="menu-item" v-bind:class="{active: selectedMenuItem == 'home'}">Acasa</router-link></v-col>
    <v-col><router-link to="/facturi" class="menu-item" v-bind:class="{active: selectedMenuItem == 'bills'}">Facturi si plati</router-link></v-col>
    <v-col><router-link to="/autocitire" class="menu-item" v-bind:class="{active: selectedMenuItem == 'autocitiri'}">Autocitire</router-link></v-col>
    <v-col><router-link to="/locuri" class="menu-item" v-bind:class="{active: selectedMenuItem == 'lc'}">Locuri de consum</router-link></v-col>
    <v-col><router-link to="/rapoarte" class="menu-item" v-bind:class="{active: selectedMenuItem == 'reports'}">Rapoarte</router-link></v-col>
    <v-col><router-link to="/suport" class="menu-item" v-bind:class="{active: selectedMenuItem == 'support'}">Suport</router-link></v-col>
    <v-col v-if="!helper.empty(assocAccounts)">
      <div style="height:70px; overflow:hidden; margin-top:13px">
          <v-select
            class="custom-select no-border-select"
            :items="assocAccounts"
            label="Conturi asociate"
            filled
            @change="changeUser"
            v-model="userSelected"
          >
            <template v-slot:item="{item}">
              <span style="color:#F02D4B">{{item.text}}</span>
            </template>
          </v-select>
      </div>
    </v-col>
    <v-col cols="2">
      <v-row no-gutters align="center" justify="center">
        <v-col cols="4">
          <!-- <v-icon @click="reset">mdi-arrow-down</v-icon>
          <span>RO</span> -->
        </v-col>
        <v-col cols="8">
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-btn
                dark
                text
                color="white"
                elevation="0"
                class="text-none"
                v-bind="attrs"
                v-on="on"
              >
                <i class="select-account-pic" style="background-color:#FFFFFF"></i>
                <strong>{{ accountSelected || 'Contul meu' }}</strong>
                <v-icon class="ml-1">mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="() => { $router.push({ name:'Contul meu' }); }">
                <v-list-item-icon class="mr-1">
                  <i class="select-account-pic" style="background-color:#F02D4B"></i>
                </v-list-item-icon>
                <v-list-item-title class="c15-20-dm-sans" style="color:#F02D4B">Detalii cont</v-list-item-title>
              </v-list-item>
              <v-list-item @click="() => { $router.push({ name:'Referral' }); }">
                <v-list-item-icon class="mr-1"></v-list-item-icon>
                <v-list-item-title class="c15-20-dm-sans" style="color:#F02D4B">Recomandari</v-list-item-title>
              </v-list-item>
              <v-divider class="my-1" />
              <v-list-item @click="() => { logout(); }">
                <v-list-item-icon class="mr-1"></v-list-item-icon>
                <v-list-item-title class="c15-20-dm-sans" style="color:#F02D4B">Log out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import globals from "./../common/js/globals.js"
import validate from "./../common/js/validate.js";
import Helper from "./../common/js/Helper.js";
import Http from "./../common/js/Http";

export default {
  name: "Header",
  mixins: [validate, globals],
  props: {
    selectedMenuItem: String,
    accountSelectedMenuItem: String
  },
  data() {
    return {
      helper: Helper,
      mastersHistory: [],
      userSelected: null,
      assocAccounts: [],
      accountSelected: null
    }
  },

  created() {
    this.accountSelected = this.accountSelectedMenuItem;
  },

  watch: {
    accountSelectedMenuItem(value) {
      this.accountSelected = value;
    },
    user() {
      this.getAssocAccounts();
    }
  },

  methods: {
    async getAssocAccounts() {
        let http = new Http();
        this.mastersHistory = JSON.parse(localStorage.getItem('masterSessions'));
        if(this.mastersHistory && !this.helper.empty(this.mastersHistory)) {
          this.mastersHistory.forEach(elem => {
            if(elem.id != this.user.Id) {
              this.assocAccounts.push({value:elem.id, text:elem.name});
            }
          });
        }
        let assAccounts = await http.get(`/webapi/account/getAssociatedUsers`);
        assAccounts.data.data.forEach(elem => {
          if(elem.id != this.user.Id) {
            this.assocAccounts.push({value:elem.secondaryUser.Id, text:elem.secondaryUser.Name});
          }
        });
    },
    async changeUser() {
      let masterSelected = null;
      this.mastersHistory.forEach(elem => {
        if(elem.id == this.userSelected) {
          masterSelected = elem;
        }
      });
      if (masterSelected) {
        // remove selected mastersession from localStorage.getItem('masterSessions') unless it's the first one
        // console.log(masterSelected);
        localStorage.setItem('accessToken', masterSelected.token);
      } else {
        let formData = new FormData();
        formData.append('uid', this.userSelected);
        let http = new Http();
        let login = await http.post(`/webapi/account/postAssocLogin`, formData);
        // console.log(login);
        localStorage.setItem('accessToken', login.data.data);
      }

      window.location.reload();
    }
  }
}
</script>

<style scoped>
.header {
  font: normal normal bold 15px/20px DM Sans;
}
.header .col {
  text-align: center;
}
.header a.menu-item {
  display: inline-block;
  height: 62px;
  line-height: 62px;
  padding: 0 20px;
  text-align: center;
  font-family: DM Sans;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.3px;
  color: #FFFFFF;
  opacity: 1;
  white-space: nowrap;
}
.header a.menu-item.active {
  background-color: #FFFFFF;
  color: #F02D4B !important;
}
.select-account-pic {
  display: inline-block;
  background-repeat: no-repeat;
  width: 22px;
  height: 22px;
  mask: url('./../assets/consumer.svg');
  mask-size: contain;
  mask-repeat: no-repeat;
}
</style>
